<template>
  <div
    class="modal"
    id="favorite-list-modal"
    tabindex="-1"
    aria-labelledby="favorite-list-modal-label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header flex-column" style="background-color: #f2f5f5">
          <!-- 主視覺圖片 -->
          <div class="my-favorite d-flex align-items-center">
            <img src="@/assets/icons/shop/icon_favorit_black.svg" alt="" />
            <span>我的收藏</span>
            <img
              class="close-btn"
              data-bs-dismiss="modal"
              src="@/assets/icons/shop/icon-cross-close-btn-gray.svg"
              alt="close btn icon"
            />
          </div>
          <!-- 課程篩選 -->
          <div
            class="course-filter-container"
            v-if="apiData.isPending == false"
          >
            <a
              href="javascript:void(0);"
              id="favorite-list-course-all"
              class="course-all"
              @click.prevent="clickCourseAll()"
              >所有課程 ({{ courseAllQuantity }})</a
            >
            <a
              href="javascript:void(0);"
              id="favorite-list-will-be-discontinued"
              class="will-be-discontinued"
              @click.prevent="clickWillBeDiscontinued()"
              >即將下架 ({{ willBeDiscontinuedQuantity }})</a
            >
            <a
              href="javascript:void(0);"
              id="favorite-list-promotions"
              class="promotions"
              @click.prevent="clickPromotions()"
              >活動優惠 ({{ promotionsQuantity }})</a
            >
          </div>
        </div>
        <!-- Modal Body -->
        <div class="modal-body">
          <!-- 資料載入中的畫面 -->
          <div
            v-if="apiData.isPending == true"
            class="d-flex flex-row justify-content-center align-items-center"
            style="height: 45vh; background-color: white"
          >
            <div
              class="spinner-border"
              role="status"
              style="color: #25a2a6"
            ></div>
          </div>

          <!-- 課程顯示切換，透過views內的資料決定 -->
          <div
            class="course-card-container"
            v-if="views == 'course-all' && apiData.isPending == false"
          >
            <!-- 所有課程 -->
            <FavoriteListCourseCard
              v-for="(item, index) in courseAll"
              :key="courseAll[index]"
              :info="courseAll[index]"
              @removeItem="favoriteItemRemove"
              @updateExchangeList="updateExchangeList"
            />
            <FavoriteListCourseCardEmpty v-if="cardEmpty.courseAllEmpty" />
          </div>
          <div
            class="course-card-container"
            v-if="views == 'will-be-discontinued' && apiData.isPending == false"
          >
            <!-- 即將下架 -->
            <FavoriteListCourseCard
              v-for="(item, index) in willBeDiscontinued"
              :key="willBeDiscontinued[index]"
              :info="willBeDiscontinued[index]"
              @removeItem="favoriteItemRemove"
              @updateExchangeList="updateExchangeList"
            />
            <FavoriteListCourseCardEmpty
              v-if="cardEmpty.willBeDiscontinuedEmpty"
            />
          </div>

          <div
            class="course-card-container"
            v-if="views == 'promotions' && apiData.isPending == false"
          >
            <!-- 活動優惠 -->
            <FavoriteListCourseCard
              v-for="(item, index) in promotions"
              :key="promotions[index]"
              :info="promotions[index]"
              @removeItem="favoriteItemRemove"
              @updateExchangeList="updateExchangeList"
            />
            <FavoriteListCourseCardEmpty v-if="cardEmpty.promotionsEmpty" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, Prop, PropType, watch } from "vue";
import FavoriteListCourseCard from "@/components/OnlineShop/FavoriteListCourseCard.vue";
import FavoriteListCourseCardEmpty from "@/components/OnlineShop/FavoriteListCourseCardEmpty.vue";
import mixins from "@/mixins/index";
import axios from "axios";

// axios環境變數宣告
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}`;

export default defineComponent({
  name: "OnlineShop-favorite-list-Modal",
  mixins: [mixins],
  components: {
    FavoriteListCourseCard,
    FavoriteListCourseCardEmpty,
  },
  props: [],
  emits: ["updateExchangeList", "favoriteItemRemove"],
  data() {
    return {
      sampleStatus: false,
      views: "course-all",
      cardEmpty: {
        courseAllEmpty: false,
        willBeDiscontinuedEmpty: false,
        promotionsEmpty: false,
      },
      // ↓ 卡片資料
      courseAll: [],
      willBeDiscontinued: [],
      promotions: [],
      // ↑ 卡片資料

      // ↓ 卡片數量計算
      courseAllQuantity: -1,
      willBeDiscontinuedQuantity: -1,
      promotionsQuantity: -1,
      // ↑ 卡片數量計算

      apiData: {
        isPending: false,
        pendingStatus: 0,
      },
      mobileDevice: false,
    };
  },
  watch: {
    // 監控全部課程收藏數量變化，如果為0就顯示沒有收藏內容
    courseAllQuantity: function () {
      if (this.courseAllQuantity > 0) {
        this.cardEmpty.courseAllEmpty = false;
      } else if (this.courseAllQuantity == 0) {
        this.cardEmpty.courseAllEmpty = true;
      }
    },
    // 監控即將下架收藏數量變化，如果為0就顯示沒有收藏內容
    willBeDiscontinuedQuantity: function () {
      if (this.willBeDiscontinuedQuantity > 0) {
        this.cardEmpty.willBeDiscontinuedEmpty = false;
      } else if (this.willBeDiscontinuedQuantity == 0) {
        this.cardEmpty.willBeDiscontinuedEmpty = true;
      }
    },
    // 監控活動優惠收藏數量變化，如果為0就顯示沒有收藏內容
    promotionsQuantity: function () {
      if (this.promotionsQuantity > 0) {
        this.cardEmpty.promotionsEmpty = false;
      } else if (this.promotionsQuantity == 0) {
        this.cardEmpty.promotionsEmpty = true;
      }
    },
  },
  methods: {
    // axios
    // favoriteListGet() {
    //   const url = "/js/FavoriteListData.json";
    //   axios
    //     .get(`${url}`)
    //     // 這裡需要用箭頭函式，否則無法透過this訪問到data內的變數
    //     .then((res) => {
    //       this.courseAll = res.data.courseAll;
    //       this.willBeDiscontinued = res.data.willBeDiscontinued;
    //       this.promotions = res.data.promotions;
    //       this.emptyCard();
    //       this.courseQuantityCalculate()
    //       // console.log(`收藏清單的資料 : ${res.data}`);
    //     })
    //     // .then(response => (this.courseCardInfo = response))
    //     .catch(function (error) {
    //       // console.log('shop內axios失敗');
    //       console.log(error);
    //     });
    // },
    favoriteListGet() {
      // this.isPending = true;
      this.apiData.isPending = true;
      this.apiData.pendingStatus = this.apiData.pendingStatus + 1;

      this.courseAll = [];
      this.willBeDiscontinued = [];
      this.promotions = [];
      axios
        .get(`${serverUrl}learn_shop/like`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            for (let i = 0; i < res.data.data.length; i++) {
              let courseInfoBuffer = {
                index: 0,
                enable: false,
                courseFeature: "",
                courseLevel: "",
                courseDiscount: "",
                productName: "",
                productDescription: "",
                videoDate: "",
                willBeDiscontinued: "",
                price: "",
                soldQuantity: "",
                favoriteStatus: "",
                shoppingCartStatus: "",
                id: "",
                classification: [],
                type: 0,
                picture: "",
                setId: "",
              };
              // 將API的資料轉換成需求格式

              // 這個index是用來v-for產生時綁定用，這樣子在移除收藏時才可以選到指定的卡片，如果:key綁定v-for時的index，
              // 會發生怎麼刪除畫面上都是看到最後一筆消失，但資料Console.log出來看都是移除正確的筆數，造成異常
              courseInfoBuffer.index = i;

              // 是否下架
              if (courseInfoBuffer.enable != undefined) {
                courseInfoBuffer.enable = res.data.data[i].enable;
              } else {
                ourseInfoBuffer.enable = false;
              }

              // 折扣訊息顯示
              if (res.data.data[i].discountText != null) {
                courseInfoBuffer.courseDiscount = res.data.data[i].discountText;
              } else {
                courseInfoBuffer.courseDiscount = undefined;
              }

              // 課程等級
              courseInfoBuffer.courseLevel = res.data.data[i].level;

              // 課程標題
              if (res.data.data[i].title != undefined) {
                courseInfoBuffer.productName = res.data.data[i].title;
              }

              // 課程說明
              if (
                res.data.data[i].type == 1 &&
                res.data.data[i].description != null
              ) {
                courseInfoBuffer.productDescription =
                  res.data.data[i].description;
              } else {
                courseInfoBuffer.productDescription = "";
              }

              if (res.data.data[i].type != 1) {
                courseInfoBuffer.productDescription = `授課講師 : ${res.data.data[i].foreignName}`;
              }

              let dateDisplay = "上架";
              var videoDisplayDate = res.data.data[i].launchDate.substr(0, 10);
              // 當課程類型是週課影片時，要顯示上課日期而非上架
              if (res.data.data[i].type == 2) {
                dateDisplay = "上課";
                videoDisplayDate = res.data.data[i].classDate.substr(0, 10);
              }
              // 影片日期，即將下架日期計算
              // 需判斷日期資料是否為
              let days = 0;
              if (res.data.data[i].expirationDate != null) {
                courseInfoBuffer.videoDate = `${dateDisplay}日期 : ${videoDisplayDate}`;
                // 即將下架計算
                let actDate = new Date();
                let videoDate = new Date(
                  `${res.data.data[i].expirationDate.substr(0, 10)}`
                );
                let difference = Math.abs(videoDate - actDate);
                days = Math.round(difference / (1000 * 3600 * 24));
                // 計算日期小於10天才顯示即將下架
                if (days <= 10) {
                  courseInfoBuffer.willBeDiscontinued = days;
                } else {
                  // 如果條件不滿足必須將其設定成undefined ，因為在courseCard內都是透過undefined判斷是否顯示
                  courseInfoBuffer.willBeDiscontinued = undefined;
                }
              } else {
                courseInfoBuffer.videoDate = undefined;
                courseInfoBuffer.willBeDiscontinued = undefined;
              }

              // 課程原價及售價
              // coinDiscount是用來判斷有無特價的基準，如果這筆資料有東西代表是課程有折扣
              if (res.data.data[i].coinDiscount == undefined) {
                // courseInfoBuffer.courseOriginalPrice是商品原價，會在畫面上槓掉
                courseInfoBuffer.courseOriginalPrice = undefined;
                // courseInfoBuffer.price是商品售價
                courseInfoBuffer.price = res.data.data[i].coin;
              } else {
                courseInfoBuffer.courseOriginalPrice = res.data.data[i].coin;
                courseInfoBuffer.price = res.data.data[i].coinDiscount;
              }

              // 圖片來源
              
              if (res.data.data[i].picture != null) {
                courseInfoBuffer.picture = res.data.data[i].picture;
              } else if (res.data.data[i].category === "skill") {
                courseInfoBuffer.picture =
                  "/static/img/onlineShop/productBannerExtraSmallSkill.png";
              } else if (res.data.data[i].type == 1) {
                courseInfoBuffer.picture =
                  "/static/img/onlineShop/productBannerExtraSmall0.png";
              } else if (res.data.data[i].type == 2) {
                courseInfoBuffer.picture =
                  "/static/img/onlineShop/productBannerExtraSmall1.png";
              } else if (res.data.data[i].type == 3) {
                courseInfoBuffer.picture =
                  "/static/img/onlineShop/productBannerExtraSmall2.png";
              }

              //  課程類型
              courseInfoBuffer.type = res.data.data[i].type;

              // 已售出數量
              courseInfoBuffer.soldQuantity = res.data.data[i].sales;

              // 收藏狀態
              courseInfoBuffer.favoriteStatus = res.data.data[i].like;
              // 兌換清單狀態
              courseInfoBuffer.shoppingCartStatus =
                res.data.data[i].shoppingCart;

              // uid寫入
              courseInfoBuffer.id = res.data.data[i].id;
              courseInfoBuffer.setId = res.data.data[i].setId;

              // 所有課程加入
              courseInfoBuffer.classification.push("courseAll");
              this.courseAll.push(courseInfoBuffer);
              // console.log(courseInfoBuffer)
              // 即將下架的課程加入，透過即將下架的計算出的日期判斷是否為即將下架的課程
              if (
                (courseInfoBuffer.willBeDiscontinued > 0) &
                (courseInfoBuffer.enable == true)
              ) {
                courseInfoBuffer.classification.push("willBeDiscontinued");
                this.willBeDiscontinued.push(courseInfoBuffer);
              }
              // 有優惠的課程加入
              if (
                (courseInfoBuffer.courseOriginalPrice != undefined) &
                (courseInfoBuffer.enable == true)
              ) {
                courseInfoBuffer.classification.push("promotions");
                this.promotions.push(courseInfoBuffer);
              }
            }
            this.courseQuantityCalculate();
            // console.log(`收藏清單API取得`);
            // console.log(res.data.data)
            // setTimeout(() => {
            //   this.apiData.isPending = false;
            // }, 500)
            this.apiData.pendingStatus = this.apiData.pendingStatus - 1;
            this.apiData.isPending = false;
          }
          // console.log(res.data.data);
        })
        .catch((err) => {
          console.log(`收藏清單catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    favoriteItemRemove(obj) {
      // obj.id 商品的ID ， obj.classification 商品處在哪一個分類
      // 從課程資料陣列內刪除已經取消收藏的商品，這樣畫面才會跟著移除，否則要重新整理後才看的到變化
      // 商品只在全部課程

      let courseAllCheck = false;
      let willBeDiscontinuedCheck = false;
      let promotionsCheck = false;

      let courseAllIndex = 0;
      let willBeDiscontinuedIndex = 0;
      let promotionsIndex = 0;

      let courseAllBuffer = this.courseAll;
      // console.log(`刪除前的陣列是 : `)
      // console.log(this.courseAll);
      let willBeDiscontinuedBuffer = this.willBeDiscontinued;
      let promotionsBuffer = this.promotions;

      if (obj.classification.indexOf("courseAll") != -1) {
        courseAllCheck = true;
      }
      if (obj.classification.indexOf("willBeDiscontinued") != -1) {
        willBeDiscontinuedCheck = true;
      }
      if (obj.classification.indexOf("promotions") != -1) {
        promotionsCheck = true;
      }

      if (courseAllCheck) {
        for (let i = 0; i < courseAllBuffer.length; i++) {
          if (courseAllBuffer[i].id == obj.id) {
            courseAllIndex = i;
            // console.log(`刪除第${i}筆資料`);
            // console.log(courseAllIndex);
          }
        }
        courseAllBuffer.splice(courseAllIndex, 1);
        this.courseAll = courseAllBuffer;
      }

      if (willBeDiscontinuedCheck) {
        for (let i = 0; i < willBeDiscontinuedBuffer.length; i++) {
          if (willBeDiscontinuedBuffer[i].id == obj.id) {
            willBeDiscontinuedIndex = i;
            willBeDiscontinuedBuffer.splice(i, 1);
            this.willBeDiscontinued = willBeDiscontinuedBuffer;
          }
        }
      }

      if (promotionsCheck) {
        for (let i = 0; i < promotionsBuffer.length; i++) {
          if (promotionsBuffer[i].id == obj.id) {
            promotionsIndex = i;
            promotionsBuffer.splice(i, 1);
            this.promotions = promotionsBuffer;
          }
        }
      }
      this.courseQuantityCalculate();

      let url = "";
      if (obj.setId === null) {
        url = `${serverUrl}learn_shop/like/${obj.id}`;
      } else {
        url = `${serverUrl}learn_shop/like/${obj.setId}/${obj.id}`;
      }
      axios
        .patch(
          url,
          {
            add: false,
          },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.$emit("favoriteItemRemove", obj);
          }
        })
        .catch((error) => {
          console.error(`收藏清單移除收藏API觸發錯誤： ${error}`);
        });
    },

    // 所有課程按鈕按下後改變字體顏色以及顯示內容，透過views的字串資料改變顯示內容
    clickCourseAll() {
      const activateTarget = document.getElementById(
        "favorite-list-course-all"
      );
      const deactivateTarger0 = document.getElementById(
        "favorite-list-will-be-discontinued"
      );
      const deactivateTarger1 = document.getElementById(
        "favorite-list-promotions"
      );
      activateTarget.style = "text-decoration: none;color: #25a2a6;";
      deactivateTarger0.style = "text-decoration: none;color:#5E5D5D;";
      deactivateTarger1.style = "text-decoration: none;color:#5E5D5D;";
      this.views = "course-all";
    },
    // 即將下架按鈕按下後改變字體顏色以及顯示內容，透過views的字串資料改變顯示內容
    clickWillBeDiscontinued() {
      const activateTarget = document.getElementById(
        "favorite-list-will-be-discontinued"
      );
      const deactivateTarger0 = document.getElementById(
        "favorite-list-course-all"
      );
      const deactivateTarger1 = document.getElementById(
        "favorite-list-promotions"
      );
      activateTarget.style = "text-decoration: none;color: #25a2a6;";
      deactivateTarger0.style = "text-decoration: none;color:#5E5D5D;";
      deactivateTarger1.style = "text-decoration: none;color:#5E5D5D;";
      this.views = "will-be-discontinued";
    },
    // 活動優惠按鈕按下後改變字體顏色以及顯示內容，透過views的字串資料改變顯示內容
    clickPromotions() {
      const activateTarget = document.getElementById(
        "favorite-list-promotions"
      );
      const deactivateTarger0 = document.getElementById(
        "favorite-list-will-be-discontinued"
      );
      const deactivateTarger1 = document.getElementById(
        "favorite-list-course-all"
      );
      activateTarget.style = "text-decoration: none;color: #25a2a6;";
      deactivateTarger0.style = "text-decoration: none;color:#5E5D5D;";
      deactivateTarger1.style = "text-decoration: none;color:#5E5D5D;";
      this.views = "promotions";
    },
    emptyCard() {
      // 卡片數量空白判斷
      if (this.courseAll.length == 0) {
        this.cardEmpty.courseAllEmpty = true;
      } else {
        this.cardEmpty.courseAllEmpty = false;
      }

      if (this.willBeDiscontinued.length == 0) {
        this.cardEmpty.willBeDiscontinuedEmpty = true;
      } else {
        this.cardEmpty.willBeDiscontinuedEmpty = false;
      }

      if (this.promotions.length == 0) {
        this.cardEmpty.promotionsEmpty = true;
      } else {
        this.cardEmpty.promotionsEmpty = false;
      }
    },
    courseQuantityCalculate() {
      this.courseAllQuantity = this.courseAll.length;
      // console.log(this.courseAllQuantity);
      this.willBeDiscontinuedQuantity = this.willBeDiscontinued.length;
      this.promotionsQuantity = this.promotions.length;
    },
    courseAllUpdate(obj) {
      // console.log('觸發更新所有課程');
      // obj是從父階階收到的課程資料，將其推入到課程卡片的資料陣列進行卡片更新
      // this.courseAll.push(obj);
      // // 卡片數量重新計算
      // this.courseQuantityCalculate();
    },
    removeArrayItem(arr, item) {
      let index = -1;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == item) {
          index = i;
        }
      }
      // console.log(`移除的indexof`)
      // console.log(index);
      if (index > -1) {
        return arr.splice(index, 1);
      }
    },
    updateExchangeList(obj) {
      this.$emit("updateExchangeList", obj);
    },
  },
  mounted() {
    let screenWidth = document.body.clientWidth;
    if (screenWidth <= 415) {
      this.mobileDevice = true;
    }
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss"; // 全域 CSS

// MIXINS
@mixin set-font($color, $size, $weight) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

@mixin set-size($width, $height) {
  width: $width;
  height: $height;
}

// 大於 768px 時，套用以下 css
@media screen and (min-width: 768px) {
  .modal-dialog {
    max-width: 560px;
    max-height: 604px;

    .modal-content {
      border: none;
      border-radius: 10px;
      // padding: 9px 16px;
      border-radius: 10px;
      padding-bottom: 16px;
      background-color: #f2f5f5;

      .modal-header {
        border: none;
        padding: 0;

        // 我的收藏標題
        .my-favorite {
          width: 100%;
          font-size: 20px;
          padding: 26px 26.67px;
          color: #5e5d5d;
          font-weight: 500;

          span {
            margin-left: 11.24px;
          }
        }

        // 課程篩選按鈕
        .course-filter-container {
          width: 100%;
          font-size: 14px;
          text-align: center;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 16px;

          // 所有課程
          .course-all {
            text-decoration: none;
            color: #25a2a6;
            margin-left: 24px;
            font-weight: 500;
          }

          // 即將下架
          .will-be-discontinued {
            text-decoration: none;
            color: #5e5d5d;
            margin-left: 20px;
            font-weight: 500;
          }

          // 活動優惠
          .promotions {
            text-decoration: none;
            color: #5e5d5d;
            margin-left: 20px;
            font-weight: 500;
          }
        }
      }

      .modal-body {
        // margin-top: 16px;
        padding: 16px 24px;
        height: 476px;
        overflow-y: auto;
        // border: 1px solid red;

        .course-card-container {
          max-width: 512px;
          height: 130px;
        }
      }
    }
  }

  .close-btn {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 21px;
    right: 21px;
    cursor: pointer;
  }
}

// 遊覽區域寬度範圍在415px~767px，則下方CSS描述會立即被套用：
@media screen and (max-width: 767px) {
  .modal-dialog {
    max-width: 415px;

    .modal-content {
      border: none;
      border-radius: 10px;
      padding-bottom: 16px;
      background-color: white;

      .modal-header {
        border: none;
        padding: 0;
        background-color: #f2f5f5;

        // 我的收藏標題
        .my-favorite {
          width: 100%;
          font-size: 20px;
          padding: 26px 26.67px;
          height: 116px;
          color: #5e5d5d;
          font-weight: 500;
          span {
            margin-left: 11.24px;
          }
        }

        // 課程篩選按鈕
        .course-filter-container {
          width: 100%;
          font-size: 14px;
          text-align: center;
          display: flex;
          justify-content: flex-start;
          padding-top: 27px;
          background-color: white;
          // margin-top: 27px;
          // margin-bottom: 16px;

          // 所有課程
          .course-all {
            text-decoration: none;
            color: #25a2a6;
            margin-left: 16px;
            font-weight: 500;
          }

          // 即將下架
          .will-be-discontinued {
            text-decoration: none;
            color: #5e5d5d;
            margin-left: 16px;
            font-weight: 500;
          }

          // 活動優惠
          .promotions {
            text-decoration: none;
            color: #5e5d5d;
            margin-left: 16px;
            font-weight: 500;
          }
        }
      }

      .modal-body {
        margin-top: 14px;
        padding: 0px 16px;
        height: 476px;
        overflow-y: auto;

        .course-card-container {
          max-width: 383px;
          height: 164px;
          margin-top: 16px;
        }
      }
    }
  }

  .close-btn {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 21px;
    cursor: pointer;
  }
}

// 其他固定CSS

.button-block {
  button {
    border: none;
    padding: 10px 0px;
    border-radius: 5px;
  }

  .return-btn {
    @include set-font(#777777, 16px, 500);
    background-color: white;
    border: 1px solid #cccccc;
  }

  .confirm-btn {
    @include set-font(white, 16px, 500);
    background-color: #25a2a6;
  }

  .return-btn:hover {
    background-color: #ffeaea;
  }

  .confirm-btn:hover {
    background-color: #25a2a6;
  }
}

.custom-text-xs-info-400 {
  @include set-font(#777777, 12px, 400);
}

.custom-text-danger-500 {
  @include set-font(#fc2c2c, 16px, 500);
}

.custom-text-info {
  @include set-font(#5e5d5d, 14px, 400);
}

.custom-text-danger {
  @include set-font(#fc2c2c, 14px, 500);
}

.custom-sm-text-danger-400 {
  @include set-font(#fc2c2c, 12px, 400);
}
</style>
