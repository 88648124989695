
import { defineComponent, PropType } from "vue";
import PlanItem from "@/components/OnlineShop/PlanItem.vue";
import Banner from "@/components/OnlineShop/Banner.vue";
import WhatIsAIModal from "@/components/OnlineShop/WhatIsAIModal.vue";
import axios from "axios";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "OnlineShop-CCoin",
  components: {
    PlanItem,
    Banner,
    WhatIsAIModal,
  },
  data() {
    return {
      // type說明
      // 0: 福利 C 幣、 1: 無任何贈送、 2: 贈送 AI ， 3: 贈送 AI 跟週課， 99: 自訂方案
      plans: [
        {
          ccoin: 90,
          price: 0,
          custom: false,
          features: {
            free: true,
            ai: 0,
            weekly: 0,
          },
          type: 0,
        },
        {
          ccoin: 180,
          price: 200,
          custom: false,
          features: {
            free: false,
            ai: 0,
            weekly: 0,
          },
          type: 1,
        },
        {
          ccoin: 450,
          price: 500,
          ai: 0,
          custom: false,
          features: {
            free: false,
            ai: 0,
            weekly: 0,
          },
          type: 1,
        },
        {
          ccoin: 900,
          price: 1000,
          custom: false,
          features: {
            free: false,
            ai: 7,
            weekly: 0,
          },
          type: 2,
        },
        {
          ccoin: 2700,
          price: 3000,
          custom: false,
          features: {
            free: false,
            ai: 50,
            weekly: 0,
          },
          type: 2,
        },
        {
          ccoin: 5400,
          price: 6000,
          custom: false,
          features: {
            free: false,
            ai: 175,
            weekly: 2,
          },
          type: 3,
        },
        {
          ccoin: 8100,
          price: 9000,
          custom: false,
          features: {
            free: false,
            ai: 360,
            weekly: 4,
          },
          type: 3,
        },
        {
          ccoin: "萬元儲值",
          //   price: "1萬元以上金額",
          price: "萬元自定額",
          //   ai: "依比例贈送AI題庫天數",
          custom: true,
          features: {
            free: false,
            ai: "1年以上",
            weekly: 10,
          },
          type: 99,
        },
      ],
      oneOnOne: [
        {
          type: "英 / 日",
          unit: 60,
          ccoin: 750,
        },
        {
          type: "特殊語言",
          unit: 60,
          ccoin: 750,
        },
        {
          type: "雅思托福",
          unit: 60,
          ccoin: 1200,
        },
      ],
      isRotated: [false, false, false, true, false],
      itemPlan: [],
    };
  },
  created() {
    this.getCcoinPlans();
  },
  methods: {
    stopVideos() {
      var iframe = document.querySelector("iframe")! as HTMLIFrameElement;
      if (iframe !== null) {
        iframe.contentWindow!.postMessage(
          JSON.stringify({ event: "command", func: "stopVideo" }),
          "*"
        );
      }
    },
    openUrl(link: string) {
      window.open(link);
    },
    rotate(index: number) {
      this.isRotated[index] = !this.isRotated[index];
    },
    getCcoinPlans() {
      axios
        .get(`${serverUrl}coin`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let arrayBuffer = [] as any;
            res.data.data.map(function (item: any, index: number) {
              let buffer = {
                ccoin: item.ccoin as number | string,
                price: item.price as number | string,
                custom: item.custom as boolean,
                features: item.feature,
                promote: item.label,
                type: 0 as number,
                received: item.received,
              };
              // type set
              if (buffer.custom === true) {
                buffer.type = 99;
                buffer.ccoin = "自訂儲值";
                buffer.price = "萬元自訂額";
                buffer.features.ai = "1年以上";
                buffer.features.weekly = 10;
              } else if (buffer.features.free === true) {
                buffer.type = 0;
                // buffer.price = -1;
              } else if (buffer.price <= 1000) {
                buffer.type = 1;
              } else if (
                buffer.price >= 3000 &&
                buffer.features.weekly === null
              ) {
                buffer.type = 2;
              } else if (
                buffer.price >= 3000 &&
                buffer.features.weekly !== null
              ) {
                buffer.type = 3;
              }
              arrayBuffer.push(buffer);
            });
            this.plans = arrayBuffer;
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
        });
    },
  },
});
