<template>
  <!-- 尚須處理 Table border-radius  -->
  <!-- 確認付款 Modal modal fade -->
  <div
    class="modal"
    id="one-on-one-filter-page-modal"
    tabindex="-1"
    aria-labelledby="one-on-one-filter-page-modal-label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header d-flex flex-column title-search-container">
          <!-- 主視覺圖片 -->
          <div class="title">進階搜尋</div>
          <img
            class="close-btn"
            data-bs-dismiss="modal"
            src="@/assets/icons/shop/icon_closeFilter_black_btn.svg"
            alt="close btn icon"
          />
          <!-- 課程篩選 -->
          <div class="search-container d-flex justify-content-between">
            <a
              href="javascript:void(0);"
              id=""
              class="research d-flex align-items-center"
              @click="cleanSearch"
            >
              <img
                src="@/assets/icons/shop/icon_research.svg"
                alt=""
                class="research-icon"
              />
              <span>清除重置</span>
            </a>
            <button class="search" @click="startSearch">開始搜尋</button>
          </div>
        </div>
        <!-- Modal Body -->
        <div class="modal-body">
          <!-- 活動標籤，暫時隱藏 -->
          <!-- <div class="activity-tab-title-container">
            <span class="activity-tab-title">活動標籤</span>
            <div class="activity-tab-container  d-flex flex-wrap">
              <label><input type="checkbox" name="activity-tab" value="優惠中" /><span class="button">優惠中</span></label>
              <label><input type="checkbox" name="activity-tab" value="試用" /><span class="button">試用</span></label>
              <label><input type="checkbox" name="activity-tab" value="最新上架" /><span class="button">最新上架</span></label>
            </div>
          </div> -->

          <!-- 語言類型 -->
          <div class="language-tab-type-container">
            <span class="language-tab-title">課程類型</span>
            <div class="language-tab-container d-flex flex-wrap">
              <label
                ><input type="checkbox" name="language-tab-mobile" value="英日" />
                <div class="button">英日</div></label
              >
              <label
                ><input type="checkbox" name="language-tab-mobile" value="雅思" />
                <div class="button">托福雅思</div></label
              >
              <label
                ><input type="checkbox" name="language-tab-mobile" value="特殊語言" />
                <div class="button">特殊語言</div></label
              >
              <label
                ><input type="checkbox" name="language-tab-mobile" value="職場技能" />
                <div class="button">職場技能</div></label
              >
            </div>
            <p class="language-tab-special-note">※ 特殊語言：韓、德、西、法、泰等</p>
          </div>

          <!-- 時數分類 -->
          <div class="hours-classification-title-container">
            <span class="hours-classification-title">時數分類</span>
            <div class="hours-classification-container d-flex flex-wrap">
              <label
                ><input type="checkbox" name="hours-classification-tab" value="60" />
                <div class="button">60分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="120" />
                <div class="button">120分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="360" />
                <div class="button">360分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="480" />
                <div class="button">480分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="960" />
                <div class="button">960分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="1200" />
                <div class="button">1200分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="2400" />
                <div class="button">2400分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="3600" />
                <div class="button">3600分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="4800" />
                <div class="button">4800分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="5580" />
                <div class="button">5580分鐘</div></label
              >
              <label
                ><input type="checkbox" name="hours-classification-tab" value="9180" />
                <div class="button">9180分鐘</div></label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, Prop, PropType } from "vue";
import mixins from "@/mixins/index";

export default defineComponent({
  name: "OnlineShop-one-on-one-filter-page-Modal",
  mixins: [mixins],
  components: {},
  emits: ["oneOnOneMobileFilter"],
  data() {
    return {
      language: [],
      timeClass: [],
    };
  },
  methods: {
    cleanSearch() {
      let languageInput = document.querySelectorAll('input[name="language-tab-mobile"]');
      for (let i = 0; i < languageInput.length; i++) {
        languageInput[i].checked = false;
      }
      this.language = [];

      let timeClassInput = document.querySelectorAll(
        'input[name="hours-classification-tab"]'
      );

      for (let i = 0; i < timeClassInput.length; i++) {
        timeClassInput[i].checked = false;
      }
      this.timeClass = [];
    },
    startSearch() {
      this.$emit("oneOnOneMobileFilter", {
        language: this.language,
        timeClass: this.timeClass,
      });
    },
    languageEncode(data) {
      if (data == "英日") {
        return "14";
      }
      if (data == "特殊語言") {
        return "15";
      }
      if (data == "雅思") {
        return "16";
      }
      if (data == '職場技能') {
        return '19'
      }
    },
  },
  mounted() {
    // 語言類型監控及資料蒐集
    let languageInput = document.querySelectorAll('input[name="language-tab-mobile"]');

    for (let i = 0; i < languageInput.length; i++) {
      languageInput[i].addEventListener("change", (event) => {
        if (languageInput[i].checked) {
          this.language.push(this.languageEncode(languageInput[i].value));
        } else {
          let index = this.language.indexOf(this.languageEncode(languageInput[i].value));
          this.language.splice(index, 1);
        }
      });
    }

    // 時數分類監控及資料蒐集 hours-classification-tab
    let timeClassInput = document.querySelectorAll(
      'input[name="hours-classification-tab"]'
    );

    for (let i = 0; i < timeClassInput.length; i++) {
      timeClassInput[i].addEventListener("change", (event) => {
        if (timeClassInput[i].checked) {
          this.timeClass.push(Number(timeClassInput[i].value) / 30);
        } else {
          let index = this.timeClass.indexOf(Number(timeClassInput[i].value) / 30);
          this.timeClass.splice(index, 1);
        }
      });
    }
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss"; // 全域 CSS

// MIXINS
@mixin set-font($color, $size, $weight) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

@mixin set-size($width, $height) {
  width: $width;
  height: $height;
}

// 大於 320px 時，套用以下 css
@media screen and (min-width: 320px) {
  .modal-dialog {
    max-width: 560px;

    .modal-content {
      border: none;

      // 標題
      .modal-header {
        border: none;
        padding: 20.5px 16px 15px 16px;
        background-color: #f2f5f5;

        .title {
          width: 100%;
          font-size: 20px;
          color: #5e5d5d;
        }

        .search-container {
          margin-top: 37.5px;
          font-size: 14px;
          width: 100%;

          .research {
            text-decoration: none;

            .research-icon {
              width: 13.33px;
              height: 13.33px;
            }

            span {
              margin-left: 10px;
              color: #5e5d5d;
            }
          }

          .search {
            width: 105px;
            height: 32px;
            color: white;
            border-radius: 100px;
            background-color: #25a2a6;
            border: none;
          }
        }
      }

      .modal-body {
        height: 476px;
        overflow-y: auto;
        margin-top: 20px;
        padding: 0 16px;

        // 活動標籤
        .activity-tab-title-container {
          .activity-tab-title {
            font-size: 14px;
            color: #5e5d5d;
          }

          .activity-tab-container {
            margin-top: 16px;

            a {
              text-decoration: none;
              color: #909090;
              font-size: 14px;
              border: 1px solid #909090;
              border-radius: 10px;
              padding: 6px 16px;
              margin-right: 10px;
              margin-bottom: 14px;
            }

            .activity-tab0 {
            }

            .activity-tab1 {
            }

            .activity-tab2 {
            }
          }
        }

        // 語言類型
        .language-tab-type-container {
          margin-top: 16px;

          .language-tab-title {
            font-size: 14px;
            color: #5e5d5d;
          }

          .language-tab-container {
            margin-top: 16px;

            a {
              margin-bottom: 14px;
              text-decoration: none;
              color: #909090;
              font-size: 14px;
              border: 1px solid #909090;
              border-radius: 10px;
              padding: 6px 16px;
              margin-right: 10px;
            }

            .language-tab0 {
            }

            .language-tab1 {
            }

            .language-tab2 {
            }
          }

          .language-tab-special-note {
            color: #ff6767;
            font-size: 12px;
            font-weight: 400;
          }
        }

        // 時數分類
        .hours-classification-title-container {
          .hours-classification-title {
            font-size: 14px;
            color: #5e5d5d;
          }

          .hours-classification-container {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

// 其他固定CSS
.close-btn {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 26px;
  right: 21px;
  cursor: pointer;
}

// 多重選項的css
input[type="checkbox"] {
  display: none;
}

input:checked + .button {
  background: #25a2a6;
  color: #fff;
}

.button {
  font-size: 14px;
  border: 1px solid #cccccc;
  margin: 0 10px 14px 0;
  padding: 6px 16px;
  color: #cccccc;
  cursor: pointer;
  border-radius: 10px;
  height: 30px;
  display: flex;
  align-items: center;
}

.button:hover {
}
</style>
