
import { defineComponent } from "vue";
import axios from "axios";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "CcoinPickUpInstructions",
  props: ["id", "closeModalEvent"],
  emits: ["closeModal", "getCcoin"],
  data() {
    return {
      status: false,
      gradientEffectStatus: false,
    };
  },
  methods: {
    gradientEffect() {
      let target = document.querySelector(`#fade-content`)! as HTMLElement;
      let scrollBody = document.querySelector(`#scroll-body`)! as HTMLElement;
      // 先執行一次，避免只有一兩筆使用紀錄時被淡化效果蓋掉
      if (
        scrollBody.scrollTop + scrollBody.clientHeight + 5 >=
        scrollBody.scrollHeight
      ) {
        target.classList.remove("fade-content");
        target.classList.add("no-fade-content");
      } else {
        target.classList.add("fade-content");
        target.classList.remove("no-fade-content");
      }
      // 這裡必須延遲 10ms 是為了避免 DOM 剛生成時無法正確取得高度
      if (!this.gradientEffectStatus) {
        setTimeout(() => {
          scrollBody.addEventListener("scroll", () => {
            // 多 +5 是為了避免誤差造成判斷錯誤
            if (
              scrollBody.scrollTop + scrollBody.clientHeight + 5 >=
              scrollBody.scrollHeight
            ) {
              target.classList.remove("fade-content");
              target.classList.add("no-fade-content");
            } else {
              target.classList.add("fade-content");
              target.classList.remove("no-fade-content");
            }
          });
        }, 10);
        this.gradientEffectStatus = true;
      }
    },
    getCcoin() {
      this.status = true;
      this.$emit("getCcoin");
      axios
        .post(
          `${serverUrl}coin/receive`,
          {},
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // console.log('get ccoin')
          }
        });
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  computed: {
    title() {
      if (!this.status) {
        return "福利C幣領取";
      } else {
        return "領取成功！";
      }
    },
  },
});
