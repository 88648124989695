
import { defineComponent } from "vue";
import axios from "axios";

// Interface
import {} from "@/types/models";

// Mixins
import mixins from "@/mixins/index";

// Template
import Template14 from "@/views/templates/Template14.vue";

// Component
import CategoryCard from "@/components/OnlineShop/CategoryCard.vue";
import AltCategoryCard from "@/components/OnlineShop/AltCategoryCard.vue";
import CCoin from "@/components/OnlineShop/CCoin.vue";
import PurchaseCCoin from "@/components/OnlineShop/PurchaseCCoin.vue";
import History from "@/components/OnlineShop/History.vue";
import Shop from "@/components/OnlineShop/Shop.vue";
import ExchangeList from "@/components/OnlineShop/ExchangeList.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "OnlineShop",
  components: {
    Template14,
    CategoryCard,
    AltCategoryCard,
    CCoin,
    PurchaseCCoin,
    History,
    Shop,
    ExchangeList,
  },
  mixins: [mixins],
  data() {
    return {
      subpaths: [
        { name: "學習商店", link: "/online-shop?category=shop" }, // 原本是 { name: "學習商店", link: "/online-shop" }
        { name: "學習商店", link: "/online-shop?category=shop" },
      ],
      activeCategory: "shop", // shop:學習商店 ccoin:C幣儲值 history:兌換紀錄
      step: 0,
      myCCoin: 0,
      isPending: false,
    };
  },
  watch: {
    pageRoute() {
      this.activeCategory = `${this.$route.query.category}`;
    },
    pageStep() {
      this.step = [1, 2, 3].includes(parseInt(`${this.$route.query.step}`))
        ? parseInt(`${this.$route.query.step}`)
        : 0;
    },
    activeCategory() {
      switch (this.activeCategory) {
        case "shop":
          this.subpaths[1].name = "學習商店";
          this.subpaths[1].link = "/online-shop?category=shop";
          break;

        case "ccoin":
          this.subpaths[1].name = "C幣儲值";
          this.subpaths[1].link = "/online-shop?category=ccoin";
          break;

        case "history":
          this.subpaths[1].name = "兌換紀錄";
          this.subpaths[1].link = "/online-shop?category=history";
          break;

        default:
          break;
      }
    },
  },
  computed: {
    pageRoute() {
      return this.$route.query.category;
    },
    pageStep() {
      return this.$route.query.step;
    },
  },
  methods: {
    changeCategory(category: string) {
      if (category == "exchangeList") {
        this.activeCategory = "shop";
        // console.log(this.activeCategory)
      } else {
        this.activeCategory = category;
      }
    },
    // purchase() {
    //   // const formData = { products: ["TESTpoj39265"] }; // 一對一
    //   // const formData = {
    //   //   products: ["TESTvj47156"],
    //   // }; // 週課
    //   // const formData = { products: ["TESTjt82059"] }; // 函授

    //   // let formData = new FormData();
    //   // formData.append("products", JSON.stringify(["TEST001", "TEST002"]));

    //   const config = {
    //     headers: {
    //       Authorization: loginToken,
    //     },
    //   };
    //   axios
    //     .post(`${serverUrl}learn_shop`, formData, config)
    //     .then((res) => {
    //       if (res.status == 200) {
    //         console.log(res.data.data.mtNO);
    //         console.log(res.data.data.html);
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 450) {
    //         console.log("產品裡還有已下架商品");
    //       } else if (error.response.status === 460) {
    //         console.log("商品總C幣大於使用者擁有C幣");
    //       }
    //     });
    // },
  },
  mounted() {
    // URL Params: category
    if (this.$route.query.category) {
      const entryPoint = `${this.$route.query.category}`;
      this.activeCategory = entryPoint;
    }

    // URL Params: step
    if (
      this.$route.query.step &&
      [1, 2, 3].includes(parseInt(`${this.$route.query.step}`))
    ) {
      this.step = parseInt(`${this.$route.query.step}`);
    } else {
      this.step = 0;
    }

    // 取得C幣餘額
    this.isPending = true;
    axios
      .get(`${serverUrl}coin/balance`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data.data.coin);
          this.myCCoin = res.data.data.coin;

          this.isPending = false;
        }
      })
      .catch(() => {
        this.isPending = false;
      });
  },
});
