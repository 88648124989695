
import { defineComponent } from "vue";
import mixins from "@/mixins/index";
import axios from "axios";
import CcoinUsageHistoryItem from "@/components/OnlineShop/CcoinUsageHistoryItem.vue";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface actionAndPoints {
  action: string;
  point: string;
}
interface ccoinUsageHistoryItem {
  title: string;
  subTitle: string;
  createdDate: string;
  coin: string;
  expiredDate: string;
}

export default defineComponent({
  name: "CcoinUsageHistory",
  components: { CcoinUsageHistoryItem },
  mixins: [mixins],
  props: ["id", "myCCoin"],
  data() {
    return {
      actionAndPointsAndDescriptions: [] as Array<any>,
      actionAndPoints: [] as Array<actionAndPoints>,
      descriptions: [] as Array<string>,
      // --------------------
      switchStatus: "usage history",
      ccoinUsageHistory: [] as Array<ccoinUsageHistoryItem>,
      gradientEffectStatus: false,
      record: [] as any,
      expire: [] as any,
    };
  },
  methods: {
    gradientEffect() {
      let target = document.querySelector(`#fade-content`)! as HTMLElement;
      let scrollBody = document.querySelector(`#scroll-body`)! as HTMLElement;
      // 先執行一次，避免只有一兩筆使用紀錄時被淡化效果蓋掉
      if (
        scrollBody.scrollTop + scrollBody.clientHeight + 5 >=
        scrollBody.scrollHeight
      ) {
        target.classList.remove("fade-content");
        target.classList.add("no-fade-content");
      } else {
        target.classList.add("fade-content");
        target.classList.remove("no-fade-content");
      }
      // 這裡必須延遲 10ms 是為了避免 DOM 剛生成時無法正確取得高度
      if (!this.gradientEffectStatus) {
        setTimeout(() => {
          scrollBody.addEventListener("scroll", () => {
            // 多 +5 是為了避免誤差造成判斷錯誤
            if (
              scrollBody.scrollTop + scrollBody.clientHeight + 5 >=
              scrollBody.scrollHeight
            ) {
              target.classList.remove("fade-content");
              target.classList.add("no-fade-content");
            } else {
              target.classList.add("fade-content");
              target.classList.remove("no-fade-content");
            }
          });
        }, 10);
        this.gradientEffectStatus = true;
      }
    },
    usageHistory() {
      this.switchStatus = "usage history";
      this.ccoinUsageHistory = this.record;
    },
    expiring() {
      this.switchStatus = "expiring";
      this.ccoinUsageHistory = this.expire;
    },
  },
  computed: {
    noHistory() {
      if (
        this.switchStatus === "usage history" &&
        this.ccoinUsageHistory.length === 0
      ) {
        return "還沒有儲值紀錄";
      } else if (
        this.switchStatus === "expiring" &&
        this.ccoinUsageHistory.length === 0
      ) {
        return "沒有即將到期的C幣";
      } else {
        return "history list";
      }
    },
    // 根據 switchStatus 判斷目前畫面是顯示使用紀錄還是即將過期，會影響到 C 幣數值跟到期時間的顏色
    // 傳入到子組件內改變自己顏色
    textColor() {
      if (this.switchStatus === "usage history") {
        return "black";
      } else {
        return "red";
      }
    },
  },
  created() {
    this.axiosGetMethod("coin/record")
      .then((data) => {
        this.record = data.data.record;
        this.expire = data.data.expireSoon;
        this.usageHistory();
      })
      .catch((error) => {
        console.error(error);
      });
  },
});
