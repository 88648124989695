
import { defineComponent } from "vue";
import axios from "axios";
import Banner from "@/components/OnlineShop/Banner.vue";
import Pagination from "@/components/Pagination.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
const currentTimeStamp = Date.now();

interface data {
  id: string;
  coin: number;
  title: string;
  createdAt: string;
  picture: null | string;
  enable: boolean;
  type: number;
  file: string;
}
interface ItemStyle {
  backgroundImage?: string;
}
type TextMap = {
  [key: number]: string;
};
export default defineComponent({
  name: "OnlineShop-History",
  components: { Banner, Pagination },
  data() {
    return {
      typeText: {
        2: "前往課程",
        3: "前往課程",
      } as TextMap,
      isPending: false,
      isPosting: false,
      dropdown: false,
      selected: 0,
      data: [] as Array<data>,
      total: 0,
      currentPage: 1,
      totalPage: 1,
    };
  },
  methods: {
    openUrl(link: string, type: number, id: string) {
      // link 是來自 API 的資料內，名稱 file 的教材路徑
      // type 2 是週課影片， 3 是函授影片
      // id 是該課程的 id

      // 判斷為週課影片時，因為本身資料已經帶有教材路徑，直接開啟新分頁就可以
      if (type === 2) {
        window.open(link, "_blank");
        // 判斷為函授影片時，由於 file 會是 null ，因為後端沒有給資料，所以無法直接開啟
        // 必須在前端自己抽換 url ，透過 this.$router.resolve
      } else if (type === 3) {
        let routeUrl = this.$router.resolve({
          path: `/product-info?`,
          query: { pid: id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    changeFilter(type: number) {
      this.selected = type;
      this.currentPage = 1;
      this.fetchData();
    },
    changePage(page: number) {
      this.currentPage = page;
      window.scrollTo(0, 0);
      this.fetchData();
    },
    fetchData() {
      this.isPending = true;
      axios
        .get(
          `${serverUrl}learn_shop/record?type=${
            this.selected === 0 ? "" : this.selected
          }&page=${this.currentPage}&displayedNum=8`,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // let tmp = [] as Array<data>;
            // res.data.data.data.forEach((ele: data) => {
            //   let temp = ele;
            //   temp.enable = false;
            //   tmp.push(temp);
            // });
            // this.data = tmp;

            // 將購買影片時間修改成超過 90 天，用以查看週課及函授的按鈕變化
            // let temp = res.data.data.data.forEach(function (
            //   item: any,
            //   index: any,
            //   array: any
            // ) {
            //   array[index].createdAt = "2022-03-31 16:00:00"
            //    console.log(array[index])
            // });

            this.data = res.data.data.data;
            // 測試用假資料
            // this.data = [{ id: "string", coin: 3, title: "string", createdAt: "string", picture: "string", enable: true, type: 3, file: "string", }]
            this.total = res.data.data.total;
            this.totalPage = res.data.data.totalPage;
            this.currentPage = res.data.data.currentPage;
            this.isPending = false;
          }
        })
        .catch(() => {
          this.isPending = false;
        });
    },
    addToList(pid: string) {
      const config = {
        headers: {
          Authorization: loginToken,
        },
      };

      this.isPosting = true;
      axios
        .patch(`${serverUrl}learn_shop/cart/${pid}`, { add: true }, config)
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);

            // Show Alert
            const toast = document.getElementById(
              "custom-toast-item"
            )! as HTMLElement;
            toast.style.visibility = "visible";
            toast.style.opacity = "1";

            setTimeout(() => {
              toast.style.visibility = "hidden";
              toast.style.opacity = "0";
            }, 2000);

            this.isPosting = false;
          } else {
            console.log("error");
            this.isPosting = false;
          }
        });
    },
    hideToast() {
      const toast = document.getElementById(
        "custom-toast-item"
      )! as HTMLElement;
      toast.style.visibility = "hidden";
      toast.style.opacity = "0";
    },
    setBgImg(name: string) {
      return `url(/static/img/products/${name})`;
    },
    compareDate(date: string) {
      const dateDiff = (
        (currentTimeStamp - new Date(date).getTime()) /
        (1000 * 60 * 60 * 24)
      ).toFixed(0);

      if (parseInt(dateDiff) > 90) {
        return false;
      } else {
        return true;
      }
    },
  },
  computed: {
    itemStyles() {
      return (index: number): ItemStyle => {
        const item = this.data[index];
        const styles: ItemStyle = {};

        if (item.picture !== null) {
          styles.backgroundImage = `url(${item.picture})`;
        } else if (item.type === 1) {
          styles.backgroundImage = `${this.setBgImg("oneOnOne.png")}`;
        } else if (item.type === 2) {
          styles.backgroundImage = `${this.setBgImg("weekly.png")}`;
        } else if (item.type === 3) {
          styles.backgroundImage = `${this.setBgImg(
            "correspondenceEducation.png"
          )}`;
        }
        // console.log(styles);
        return styles;
      };
    },
  },
  mounted() {
    // 待付款List
    this.fetchData();
  },
});
