
import { defineComponent } from "vue";
import axios from "axios";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface Item {
  [key: number]: {
    ccoin: number;
    ai: number;
    weekly: number;
    weeklyNextStage: number;
    remainingAmountNextStage: number;
  };
}

export default defineComponent({
  name: "OnlineShopCustomCCoinModal",
  data() {
    return {
      price: "" as string | number,
      customModal: null as any,
      buffer: {} as any,
      ccoin: 0,
      ai: 0,
      weekly: 0,
      spread: {
        weekly: 0 as number,
        price: 0 as number,
      },
    };
  },
  computed: {
    error() {
      if (this.price === "" || this.price >= 10000) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    price() {
      // 如果數入金額範圍合理，以及儲存快取的 buffer 內沒有該筆金額的紀錄，則 call API 計算
      if (
        this.price >= 10000 &&
        this.price <= 20000 &&
        this.buffer[this.price as number] === undefined
      ) {
        this.priceCalculate(this.price as number);
        // 如果輸入的金額曾經有過紀錄，則從快取取得資料
      } else if (this.buffer[this.price as number] !== undefined) {
        let index = this.price as number;
        this.ccoin = this.buffer[index].ccoin;
        this.ai = this.buffer[index].ai;
        this.weekly = this.buffer[index].weekly;
        this.spread.weekly = this.buffer[index].weeklyNextStage;
        this.spread.price = this.buffer[index].remainingAmountNextStage;
        // 如果輸入金額小於 10000 ，不觸發 API 以外，還要把數值寫成 0 ，重置贈送的相關資訊
      } else if (this.price < 10000) {
        this.ccoin = 0;
        this.ai = 0;
        this.weekly = 0;
        this.spread.weekly = 0;
        this.spread.price = 0;
      }
    },
  },
  methods: {
    reset() {
      if (this.price !== "") {
        this.price = "";
      }
    },
    submit() {
      if (this.price !== "" && this.price >= 10000) {
        let buffer = {
          price: this.price,
          ai: this.buffer[this.price].ai,
          ccoin: this.buffer[this.price].ccoin,
          weekly: this.buffer[this.price].weekly,
        };
        this.$emit("setCustom", buffer);
        this.$emit("closeModal");
      }
    },
    priceCalculate(price: number) {
      axios
        .post(
          `${serverUrl}coin/customCalculate`,
          {
            amount: price,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.buffer[price] = res.data.data;
            this.ccoin = res.data.data.ccoin;
            this.ai = res.data.data.ai;
            this.weekly = res.data.data.weekly;
            this.spread.weekly = res.data.data.weeklyNextStage;
            this.spread.price = res.data.data.remainingAmountNextStage;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  emits: ["closeModal", "setCustom"],
});
