
import { defineComponent, Prop, PropType } from "vue";
import mixins from "@/mixins/index";

import axios from "axios";
import Bus from "@/utils/Bus";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface item {
  coin: number;
  coinDiscount: number;
  discountText: string;
  enable: boolean;
  expirationDate: null | string;
  id: string;
  like: boolean;
  title: string;
  type: number;
  checked: boolean;
}

export default defineComponent({
  name: "OnlineShop-Confirm-Exchange-Modal",
  mixins: [mixins],
  props: {
    items: {
      type: Array as PropType<Array<item>>,
      required: true,
    },
    myCCoin: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isPosting: false,
    };
  },
  methods: {
    confirm() {
      let productArray = [] as Array<string>;
      this.items.forEach((ele) => {
        productArray.push(ele.id);
      });

      const config = {
        headers: {
          Authorization: loginToken,
        },
      };

      this.isPosting = true;
      axios
        .post(`${serverUrl}learn_shop`, { products: productArray }, config)
        .then((res) => {
          if (res.status == 200) {
            this.isPosting = false;
            // 關閉Modal
            this.$emit("closeModal");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isPosting = false;
          let text = "";
          // 錯誤狀態判斷
          switch (err.response.status) {
            case 455:
              text =
                "不需要重複購買同一堂函授課程的全套及單堂課程！請移除其中一項商品後再次兌換。";
              break;
            case 470:
              text = "已經購買該堂函授課程內某一堂課程！請移除全套課程的商品。";
              break;
            case 475:
              text =
                "已經擁有該堂函授課程的全套課程！不需要再額外購買單堂的函授課程。";
              break;
            default:
              text = "兌換失敗，請重新整理頁面後再試。";
              break;
          }
          Bus.emit("openAlertModal", {
            type: "error",
            title: ["Error!"],
            content: text,
            btnText: "關閉",
          });
        });
    },
    getSum(items: Array<item>) {
      let sum = 0;
      if (items.length > 0) {
        items.forEach((element) => {
          if (element.checked && element.enable) {
            if (element.coinDiscount) {
              sum += element.coinDiscount;
            } else {
              sum += element.coin;
            }
          }
        });
      }

      return sum;
    },
  },
  emits: ["closeModal"],
});
