
import { defineComponent, PropType } from "vue";

interface btn {
  category: number | string;
  imgActive: string;
  imgInactive: string;
  alt: string;
  name: string;
  route: string;
}

export default defineComponent({
  name: "CategoryBtn",
  props: {
    data: {
      type: Object as PropType<btn>,
      required: true,
    },
    active: {
      type: String,
      required: true,
    },
  },
  methods: {
    setImage(img: string) {
      return require(`@/assets/icons/icon_${img}.svg`);
    },
  },
});
