
import { defineComponent, PropType } from "vue";

interface data {
  pid: string;
  coin: number;
  title: string;
  createdAt: string;
  picture: null | string;
  enable: boolean;
  type: number;
}

export default defineComponent({
  name: "Pagination",
  props: {
    isPending: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array as PropType<Array<data>>,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
  },
  methods: {
    changePage(page: number) {
      this.$emit("changePage", page);
    },
  },
  emits: ["changePage"],
});
