
import { defineComponent } from "vue";

export default defineComponent({
    name: "FavoriteListCourseCard",
    data() {
        return {
            courseInfo: {
                courseFeature: '1對1視訊課程',
                courseLevel: '2~3',
                courseDiscount: '25',
                courseBought: false,
                willBeDiscontinued: '7',
                productName: ' 英、日1對1 - 60分鐘 英、日1對1 - 60分鐘 英、日1對1 - 60分鐘',
                productDescription: '全程1對1教學，高效學習方案。',
                videoDate: '影片日期：2022/6/22',
                courseOriginalPrice: '99999',
                price: '900',
                soldQuantity: '1322',
            }
        }
    },
    methods: {
        openModal() {
            this.$emit('openModal');
        }
    },
    mounted() {
        // this.courseDetailsModal = new Modal(
        //   document.getElementById("course-details-modal")
        // );
    },
    emits: [
        'openModal'
    ]
});
