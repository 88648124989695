
import { defineComponent } from "vue";

export default defineComponent({
  name: "OnlineShop-Banner",
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
});
