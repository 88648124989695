<template>
  <div v-if="views != 'exchange-list'">
    <!-- 學習商店最上方的Banner -->

    <div class="banner-block" id="banner-block">
      <!-- <div class="search-all">
        <input type="text" id="search-all" placeholder="搜尋課程">
      </div> -->

      <div class="d-flex align-items-center search-all-container">
        <img
          src="@/assets/icons/shop/icon-magnifier-black.svg"
          class="search-all-icon"
          @click="courseSearchIconClick"
        />
        <input
          type="text"
          class="search-all-input"
          id="courseSearch"
          placeholder="搜尋課程"
        />
        <img
          src="@/assets/icons/shop/icon-cross-close-btn-gray.svg"
          alt=""
          class="search-clean-icon"
          @click="courseSearchCleanIconClick"
        />
      </div>
    </div>

    <!-- 最新上架訊息超連結 -->
    <div class="new-arrival">
      <span class="title"></span>
      <router-link to="online-shop?category=ccoin" class="ccoin-quantity"
        >我目前擁有：{{ myCCoin }} C幣</router-link
      >
    </div>

    <!-- 手機板C幣顯示 -->
    <div class="ccoin-quantity-container-mobile">
      <a href="javascript:void(0);" class="ccoin-quantity"
        >我目前擁有：{{ myCCoin }} C幣</a
      >
      <router-link to="online-shop?category=ccoin" class="stored-value"
        >儲值去</router-link
      >
    </div>

    <!-- 課程選擇畫面 -->
    <div class="course-container">
      <!-- 課程選擇，1對1視訊 -->
      <div class="course-selection">
        <!-- 選擇標籤、收藏及兌換清單電腦版 -->
        <div class="select-tab">
          <div class="course-type-tab-container">
            <div
              class="course-type-tab"
              :class="{
                active: views === VIEW.one_on_one,
              }"
              @click="courseSelectOneOnOne"
            >
              <div class="content">
                <!-- 這裡2個icon的原因是按下別的按鈕後背景顏色會改變，同時需要改變圖案配合 -->
                <img
                  src="@/assets/icons/shop/icon_one_on_one_white.svg"
                  v-show="views === VIEW.one_on_one"
                  style="height: 22px"
                />
                <img
                  src="@/assets/icons/shop/icon_one_on_one_black.svg"
                  v-show="views !== VIEW.one_on_one"
                  style="height: 22px"
                />
                <span>1對1視訊課程</span>
              </div>
            </div>

            <div
              class="course-type-tab"
              :class="{
                active: views === VIEW.weekly_class,
              }"
              @click="
                () => courseSelectWeeklyCorrespondenceClass('weekly-class')
              "
            >
              <div class="content">
                <img
                  src="@/assets/icons/shop/icon_weekly_class_white.svg"
                  v-show="views === VIEW.weekly_class"
                  style="width: 22px; height: 22px"
                />
                <img
                  src="@/assets/icons/shop/icon_weekly_video_black.svg"
                  v-show="views !== VIEW.weekly_class"
                  style="width: 22px; height: 22px"
                />
                <span>週課影片</span>
              </div>
            </div>

            <div
              class="course-type-tab"
              :class="{
                active: views === VIEW.correspondence_education,
              }"
              @click="
                () =>
                  courseSelectWeeklyCorrespondenceClass(
                    'correspondence-education'
                  )
              "
            >
              <div class="content">
                <img
                  src="@/assets/icons/shop/icon_correspondence-education_white.svg"
                  v-show="views === VIEW.correspondence_education"
                  style="width: 22px; height: 22px"
                />
                <img
                  src="@/assets/icons/shop/icon_correspondence-education_black.svg"
                  v-show="views !== VIEW.correspondence_education"
                  style="width: 22px; height: 22px"
                />
                <span>函授教學影片</span>
              </div>
            </div>
          </div>

          <!-- 收藏及兌換清單 -->
          <div class="collect-exchange-container" st>
            <a
              href="javascript:void(0);"
              class="collect"
              @click.prevent="openFavoriteListModal()"
            >
              <img src="@/assets/icons/shop/icon_favorit_primary.svg" alt="" />
              <span>我的收藏</span>
            </a>

            <div class="exchange-dropdown" v-show="exchangeList.length > 0">
              <router-link
                to="online-shop?category=exchangeList"
                class="exchange"
              >
                <img
                  src="@/assets/icons/shop/icon_shopcart_primary.svg"
                  alt=""
                />
                <span>兌換清單</span>
                <span>{{ exchangeListItemsQuantity }}</span>
              </router-link>
              <!-- 兌換清單的三角形 -->
              <div
                class="exchange-list-box-triangle"
                style="
                  border-color: transparent transparent #ffffff transparent;
                  border-style: solid solid solid solid;
                  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3));
                  filter: drop-shadow(0px -4px 2px rgba(0, 0, 0, 0.03));
                  border-width: 15px;
                  height: 0px;
                  width: 0px;
                  position: absolute;
                  top: 12px;
                  right: 30px;
                  z-index: 2;
                "
              ></div>
              <div class="exchange-dropdown-content">
                <ExchangeListItem
                  v-for="(item, index) in exchangeList"
                  :key="item.id"
                  :info="exchangeList[index]"
                />

                <!-- 按下後跳轉到兌換清單頁面 -->
                <div class="d-flex">
                  <router-link
                    to="online-shop?category=exchangeList"
                    class="view-exchange-list btn-black-filled d-flex justify-content-center align-items-center"
                    >查看兌換清單</router-link
                  >
                </div>
              </div>
            </div>

            <!-- 兌換清單內空白時顯示 -->
            <div
              class="exchange-dropdown"
              style="position: relative; color: #cccccc"
              v-show="exchangeList.length == 0"
            >
              <a
                href="javascript:void(0)"
                class="exchange"
                style="color: #cccccc"
              >
                <i class="bi bi-cart2"></i>
                <span style="color: #cccccc">兌換清單</span>
                <span style="background-color: #cccccc">{{
                  exchangeListItemsQuantity
                }}</span>
              </a>

              <div class="exchange-dropdown-content" style="height: 245px">
                <div
                  style="
                    color: #777777;
                    font-size: 14px;
                    text-align: center;
                    height: 75%;
                    padding-top: 35%;
                  "
                >
                  您的兌換清單是空的
                </div>
                <div class="d-flex">
                  <button
                    class="btn btn-secondary w-100 d-flex justify-content-center align-items-center"
                    style="
                      margin-left: 12px;
                      margin-right: 12px;
                      color: white;
                      border: none;
                      height: 48px;
                    "
                  >
                    查看兌換清單
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 選擇標籤手機板 -->
        <div class="course-type-tab-container-mobile">
          <div
            class="course-type-tab"
            :class="{
              active: views === VIEW.one_on_one,
            }"
            @click="courseSelectOneOnOne"
          >
            <div class="content">
              <!-- 這裡2個icon的原因是按下別的按鈕後背景顏色會改變，同時需要改變圖案配合 -->
              <img
                src="@/assets/icons/shop/icon_one_on_one_white.svg"
                v-show="views === VIEW.one_on_one"
                style="width: 20px; height: 20px"
              />
              <img
                src="@/assets/icons/shop/icon_one_on_one_black.svg"
                v-show="views !== VIEW.one_on_one"
                style="width: 20px; height: 20px"
              />
              <span>1對1視訊課程</span>
            </div>
          </div>

          <div
            class="course-type-tab"
            :class="{
              active: views === VIEW.weekly_class,
            }"
            @click="() => courseSelectWeeklyCorrespondenceClass('weekly-class')"
          >
            <div class="content">
              <img
                src="@/assets/icons/shop/icon_weekly_class_white.svg"
                v-show="views === VIEW.weekly_class"
                style="width: 20px; height: 20px"
              />
              <img
                src="@/assets/icons/shop/icon_weekly_video_black.svg"
                v-show="views !== VIEW.weekly_class"
                style="width: 20px; height: 20px"
              />
              <span>週課複習影片</span>
            </div>
          </div>

          <div
            class="course-type-tab"
            :class="{
              active: views === VIEW.correspondence_education,
            }"
            @click="
              () =>
                courseSelectWeeklyCorrespondenceClass(
                  'correspondence-education'
                )
            "
          >
            <div class="content">
              <img
                src="@/assets/icons/shop/icon_correspondence-education_white.svg"
                v-show="views === VIEW.correspondence_education"
                style="width: 20px; height: 20px"
              />
              <img
                src="@/assets/icons/shop/icon_correspondence-education_black.svg"
                v-show="views !== VIEW.correspondence_education"
                style="width: 20px; height: 20px"
              />
              <span>函授教學影片</span>
            </div>
          </div>
        </div>

        <!-- 收藏及兌換清單手機板 -->
        <div class="collect-exchange-container-mobile d-flex">
          <a
            href="javascript:void(0);"
            class="collect btn-black-hollow"
            @click.prevent="openFavoriteListModal()"
          >
            <img src="@/assets/icons/shop/icon_favorit_primary.svg" alt="" />
            <span>我的收藏</span>
          </a>

          <router-link
            to="online-shop?category=exchangeList"
            class="exchange btn-black-filled"
            v-if="exchangeList.length > 0"
          >
            <img src="@/assets/icons/shop/icon_shopcart_white.svg" alt="" />
            <span>兌換清單</span>
          </router-link>

          <a
            class="exchange"
            href="javascript:void(0);"
            v-if="exchangeList.length == 0"
            style="background-color: #cccccc; cursor: default"
          >
            <img src="@/assets/icons/shop/icon_shopcart_white.svg" alt="" />
            <span>兌換清單</span>
          </a>
        </div>

        <!-- 資料載入中的畫面 -->
        <div
          v-if="apiData.isPending == true"
          class="d-flex flex-row justify-content-center align-items-center"
          style="height: 60vh; background-color: white"
        >
          <div
            class="spinner-border"
            role="status"
            style="color: #25a2a6"
          ></div>
        </div>

        <!-- 1對1視訊課程 -->
        <div
          class="course-content"
          v-show="views == 'one-on-one' && apiData.isPending == false"
        >
          <!-- 1對1視訊課程 -->
          <div>
            <!-- 課程名稱 -->
            <div class="d-flex align-items-center course-category">
              <img
                src="@/assets/icons/shop/icon_one_on_one_title_black.svg"
                style="width: 24px; height: 24px"
              />
              <span>1對1視訊課程</span>
            </div>
            <hr class="course-category-hr" />

            <!-- 過濾選項 -->

            <div class="d-flex justify-content-between filter w-100">
              <div class="d-flex align-items-end one-on-one-quantity-all">
                全部 ({{ courseAllQuantity }})
              </div>

              <div class="filter-select d-flex">
                <input
                  type="checkbox"
                  name="oneOnOneFilter"
                  id="oneOnOneFilterAll"
                />
                <span class="all">全部</span>

                <span class="dividing-line"> </span>

                <input
                  type="checkbox"
                  name="oneOnOneFilter"
                  id="oneOnOneFilterEnJp"
                  style="background: yellow"
                />
                <span class="en-jp">英日</span>

                <input
                  type="checkbox"
                  name="oneOnOneFilter"
                  id="oneOnOneFilterSpecial"
                />
                <div
                  class="d-flex question-mark-content-container"
                  style="position: relative"
                >
                  <span class="special-language">特殊語言&nbsp;</span>
                  <img
                    class="question-mark"
                    src="@/assets/icons/shop/icon_question_mark.svg"
                    alt=""
                  />
                  <div class="question-mark-content">
                    特殊語言：韓、西、德、法、泰
                    <img src="@/assets/img/shop/dialog-box.svg" />
                  </div>
                </div>

                <input
                  type="checkbox"
                  name="oneOnOneFilter"
                  id="oneOnOneFilterIelts"
                />
                <span class="ielts">托福雅思</span>

                <input
                  type="checkbox"
                  name="oneOnOneFilter"
                  id="oneOnOneFilterSkill"
                />
                <span class="skill">職場技能</span>
              </div>

              <!-- 過濾選項，手機板 -->
              <div class="filter-ctrl" @click="openoneOnOneFilterPageModal()">
                <img src="@/assets/icons/shop/icon_ctrl.svg" alt="" />
                <div
                  style="
                    background-color: #fc2c2c;
                    width: 8px;
                    height: 8px;
                    border-radius: 100px;
                    position: absolute;
                    top: 8px;
                    right: 8px;
                  "
                  v-if="oneOnOneMobileFilterEnable"
                ></div>
              </div>
            </div>

            <!-- 課程卡片內容 -->
            <div
              class="d-flex flex-wrap justify-content-between course-card-container"
            >
              <!-- @openModal指的是監聽裡面$emit的值 -->
              <CourseCard
                v-for="(item, index) in courseCardInfo"
                :key="courseCardInfo[index]"
                :index="index"
                :info="courseCardInfo[index]"
                :item="'courseCardInfo'"
                class="course-card-mb-0 course-card-margin"
                @favoriteStatusChange="toast"
                @favoriteIconClick="favoriteItemsUpdate"
                @addToExchangeList="addToExchangeList"
              />
            </div>

            <!-- 分頁切換功能，Pagination -->
            <div class="pagination d-flex flex-row align-items-center"></div>

            <Pagination
              :isPending="pagination.oneOnOne.isPending"
              :data="pagination.oneOnOne.data"
              :currentPage="pagination.oneOnOne.currentPage"
              :totalPage="pagination.oneOnOne.totalPage"
              @changePage="changePage($event)"
            />

            <ClassFilterPageEmpty
              v-if="viewsInside == 'one-on-one-filter-empty'"
              :info="'one-on-one'"
              @backToAllCourse="backToAllCourse"
            />
          </div>

          <!-- 課程推薦 -->
          <div class="d-flex course-recommend">
            <img src="@/assets/icons/shop/icon_star.svg" alt="" />
            <span>猜你會喜歡</span>
          </div>
          <hr class="course-recommend-hr" />
          <!-- <div class="d-flex flex-wrap course-recommend-container">
              <CourseCard v-for="(item, index) in courseRecommendCardInfo" :key="index"
                class="course-recommend-card-mr-0" @openModal="openCourseDetailModal()" />
            </div> -->
          <div
            class="d-flex flex-wrap justify-content-between course-recommend-container"
          >
            <CourseCard
              v-for="(item, index) in courseRecommendCardInfo"
              :key="courseRecommendCardInfo[index]"
              :index="index"
              :item="'courseRecommendCardInfo'"
              :info="courseRecommendCardInfo[index]"
              class="course-card-mb-0 course-card-margin"
              @favoriteStatusChange="toast"
              @favoriteIconClick="favoriteItemsUpdate"
              @addToExchangeList="addToExchangeList"
            />
          </div>
        </div>
      </div>

      <!-- 週課影片 weekly-class 與 函授教學影片 correspondence-education -->
      <!-- 這兩個課程類別寫在一起，因為畫面基本一致，所以欄位共用 -->
      <div
        class="course-content"
        v-show="
          (views === 'weekly-class' || views === 'correspondence-education') &&
          apiData.isPending == false
        "
      >
        <!-- 課程名稱 -->
        <div class="d-flex align-items-center course-category">
          <img
            src="@/assets/icons/shop/icon_weekly_video_title_black.svg"
            style="width: 24px; height: 24px"
            v-show="views === 'weekly-class'"
          />
          <img
            src="@/assets/icons/shop/icon_correspondence_education_title_black.svg"
            style="width: 24px; height: 24px"
            v-show="views === 'correspondence-education'"
          />
          <span>{{
            views === "weekly-class" ? "週課影片" : "函授教學影片"
          }}</span>
        </div>
        <hr class="course-category-hr" />

        <!-- 週課過濾選項 -->
        <div class="filter w-100">
          <div class="d-flex justify-content-between">
            <div class="quantity-all d-flex align-items-end">
              全部 ({{ weeklyCorrespondenceQuantity }})
            </div>
            <div
              class="filter-select weekly-class-filter-container"
              style="position: relative; min-width: fit-content"
            >
              <!-- 課程類型 -->
              <!-- 有選擇課程類型，則更改 boarder 樣式 -->
              <div
                class="weekly-class-tag-container"
                :class="{
                  active: this.weeklyCorrespondenceSubjectFilterActivate,
                }"
              >
                <div
                  class="d-flex justify-content-center align-items-center weekly-class-tag-title"
                >
                  <a href="javascript:void(0);" class="d-flex">
                    <span>課程類型</span>
                    <img
                      src="@/assets/icons/shop/icon_arrow_down.svg"
                      alt=""
                      v-show="!this.weeklyCorrespondenceSubjectFilterActivate"
                    />
                    <img
                      src="@/assets/icons/shop/icon_arrow_down_primary.svg"
                      alt=""
                      v-show="this.weeklyCorrespondenceSubjectFilterActivate"
                    />
                  </a>
                </div>

                <div class="option-menu-container">
                  <div
                    v-for="(categoryInfo, index) in allowSubjects"
                    :key="index"
                  >
                    <div class="select-menu">
                      <div class="select-option-group">
                        {{ categoryInfo.chinese }}
                      </div>

                      <label
                        v-for="(subjectInfo, index) in categoryInfo.subjects"
                        :key="index"
                        @click="
                          () =>
                            weeklyCorrespondenceFilterSubject(
                              subjectInfo,
                              categoryInfo.category
                            )
                        "
                      >
                        <span class="select-option">
                          {{ subjectInfo.chinese }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 課程等級下拉選單 -->
              <!-- 沒有選課程類型，disable select -->
              <!-- 有選擇語言類型，則更改 boarder 樣式 -->
              <div
                class="weekly-class-tag-container"
                :class="{
                  active: this.weeklyCorrespondenceLevelFilterActivate,
                  // 沒有查詢課程類型，顯能使用課程等級查詢
                  disabled: !this.weeklyCorrespondenceSubjectFilterActivate,
                }"
              >
                <div class="weekly-class-tag-title">
                  <a href="javascript:void(0);" class="d-flex">
                    <span>課程等級</span>
                    <img
                      src="@/assets/icons/shop/icon_arrow_down.svg"
                      alt=""
                      v-show="!this.weeklyCorrespondenceLevelFilterActivate"
                    />
                    <img
                      src="@/assets/icons/shop/icon_arrow_down_primary.svg"
                      alt=""
                      v-show="this.weeklyCorrespondenceLevelFilterActivate"
                    />
                  </a>
                </div>

                <div class="option-menu-container">
                  <label
                    v-for="(
                      levelInfo, index
                    ) in weeklyCorrespondenceClassLevels"
                    :key="index"
                  >
                    <input
                      type="radio"
                      name="weekly-class-level"
                      :value="levelInfo.value"
                      @click="
                        () =>
                          weeklyCorrespondenceFilterSubjectLevel(
                            levelInfo.value
                          )
                      "
                    />
                    <span class="button">{{ levelInfo.label }}</span>
                  </label>
                </div>
              </div>

              <!-- 授課講師下拉選單 -->
              <div
                class="weekly-class-tag-container"
                :class="{
                  active: this.weeklyCorrespondenceLecturerFilterActivate,
                }"
              >
                <div
                  id="weekly-class-lecturer-filter-tag-title-container"
                  class="weekly-class-tag-title"
                >
                  <a
                    href="javascript:void(0);"
                    id="weekly-class-lecturer-filter-tag-title"
                    class="d-flex"
                  >
                    <span>授課講師</span>
                    <img
                      src="@/assets/icons/shop/icon_arrow_down.svg"
                      alt=""
                      v-show="!this.weeklyCorrespondenceLecturerFilterActivate"
                    />
                    <img
                      src="@/assets/icons/shop/icon_arrow_down_primary.svg"
                      alt=""
                      v-show="this.weeklyCorrespondenceLecturerFilterActivate"
                    />
                  </a>
                </div>

                <div class="option-menu-container">
                  <label
                    v-for="(item, index) in allowLecturers"
                    :key="index"
                    @click="
                      weeklyCorrespondenceFilterLecturerClick({
                        id: item.id,
                        name: item.name,
                        subject: item.subject,
                      })
                    "
                  >
                    <span class="button" style="">
                      {{ item.name }}
                    </span>
                  </label>
                </div>
              </div>

              <!-- 根據日期搜尋按鈕 -->
              <div
                class="search-by-day"
                :class="{
                  active: weeklyCorrespondenceDateFilter.length !== 0,
                }"
              >
                <a
                  href="javascript:void(0);"
                  class="d-flex"
                  @click="
                    weeklyCorrespondenceDatePickerShow =
                      !weeklyCorrespondenceDatePickerShow
                  "
                >
                  <img
                    src="@/assets/icons/shop/icon_date_black.svg"
                    v-show="!this.weeklyCorrespondenceDateFilterActivate"
                  />
                  <img
                    src="@/assets/icons/shop/icon_date_primary.svg"
                    v-show="this.weeklyCorrespondenceDateFilterActivate"
                  />
                  <span style="vertical-align: middle">日期搜尋</span>
                </a>
                <DatePicker
                  v-if="weeklyCorrespondenceDatePickerShow"
                  v-model="dateWeeklyCorrespondence"
                  :value="null"
                  is-range
                  style="position: absolute; z-index: 1; top: 50px; right: 0px"
                />
              </div>

              <!-- 清除搜尋條件 -->
              <div
                class="d-flex justify-content-center align-items-center clean-search"
                v-if="weeklyCorrespondenceClassFilterBtn == true"
              >
                <a href="javascript:void(0);"
                  ><img
                    src="@/assets/icons/shop/icon-cross-close-btn-gray.svg"
                    alt=""
                    @click="clearWeeklyCorrespondenceFilter"
                  />
                </a>
              </div>
            </div>

            <!-- 手機板進階選項按鈕 -->
            <div class="filter-ctrl" @click="openClassFilterMobilePageModal()">
              <img src="@/assets/icons/shop/icon_ctrl.svg" alt="" />
              <div
                style="
                  background-color: #fc2c2c;
                  width: 8px;
                  height: 8px;
                  border-radius: 100px;
                  position: absolute;
                  top: 8px;
                  right: 8px;
                "
                v-if="weeklyCorrespondenceMobileFilterEnable"
              ></div>
            </div>
          </div>

          <!-- 過濾器生效時選項顯示 -->
          <div
            class="d-flex justify-content-end align-items-center"
            style="margin-top: 12px; margin-bottom: 28px"
            v-if="
              (weeklyCorrespondenceSubjectFilter.length > 0) |
                (weeklyCorrespondenceLevelFilter.length > 0) |
                (weeklyCorrespondenceLecturerNameFilter.length > 0)
            "
          >
            <div
              class="cleanFilterSingle"
              v-if="weeklyCorrespondenceSubjectFilter[0]"
              @click="clearWeeklyCorrespondenceFilterLanguage"
            >
              <span>{{
                courseTypedecode(weeklyCorrespondenceSubjectFilter[0])
              }}</span>
              <img
                src="@/assets/icons/shop/icon-cross-close-btn-green.svg"
                alt=""
              />
            </div>
            <div
              class="cleanFilterSingle"
              v-if="weeklyCorrespondenceLevelFilter[0]"
              @click="clearWeeklyCorrespondenceFilterSubjectLevel"
            >
              <span>{{ weeklyCorrespondenceFilterSubjectLevelText }}</span>
              <img
                src="@/assets/icons/shop/icon-cross-close-btn-green.svg"
                alt=""
              />
            </div>
            <div
              class="cleanFilterSingle"
              v-if="weeklyCorrespondenceLecturerNameFilter[0]"
              @click="clearWeeklyCorrespondenceFilterLecturer"
            >
              <span>{{ weeklyCorrespondenceLecturerNameFilter[0] }}</span>
              <img
                src="@/assets/icons/shop/icon-cross-close-btn-green.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 課程卡片內容 -->
        <div
          class="d-flex flex-wrap justify-content-between course-card-container"
          v-if="viewsInside == 'weekly-class-filter-not-empty'"
        >
          <!-- @openModal指的是監聽裡面$emit的值 -->
          <CourseCard
            v-for="(item, index) in weeklyCorrespondenceCardInfo"
            :key="weeklyCorrespondenceCardInfo[index]"
            :info="weeklyCorrespondenceCardInfo[index]"
            :index="index"
            :item="'weeklyCorrespondenceCardInfo'"
            class="course-card-mb-0 course-card-margin"
            @favoriteStatusChange="toast"
            @favoriteIconClick="favoriteItemsUpdate"
            @addToExchangeList="addToExchangeList"
          />
        </div>
        <!-- 分頁切換功能，Pagination -->
        <div
          class="pagination d-flex flex-row align-items-center"
          v-if="viewsInside == 'weekly-class-filter-not-empty'"
        >
          <Pagination
            :isPending="pagination.weeklyCorrespondence.isPending"
            :data="pagination.weeklyCorrespondence.data"
            :currentPage="pagination.weeklyCorrespondence.currentPage"
            :totalPage="pagination.weeklyCorrespondence.totalPage"
            @changePage="changePage($event)"
          />
          <!-- <button class="switch-btn disabled">
              <i class="bi bi-chevron-left"></i>
            </button>
            <button class="page-btn">1</button>
            <button class="page-btn active">2</button>
            <button class="switch-btn"><i class="bi bi-chevron-right"></i></button> -->
        </div>
        <!-- 這裡使用v-if的原因是v-show會失效，目前認為的原因是因為v-show是透過display:none;因此只能在最外層的tag發生效用 -->
        <ClassFilterPageEmpty
          v-if="viewsInside == 'weekly-class-filter-empty'"
          :info="'weekly-class'"
          @backToAllCourse="backToAllCourse"
        />
      </div>

      <!-- 放大鏡搜尋影片結果頁面 -->
      <div
        class="course-content search-result"
        v-if="views == 'search-result' && apiData.isPending == false"
      >
        <div>
          <div class="d-flex align-items-center search-result-title">
            <div>
              <i class="bi bi-search" style="width: 12px; height: 12px"></i>
            </div>
            <div style="margin-left: 17px; font-size: 20px">搜尋結果：</div>
          </div>

          <!-- 搜尋結果數量顯示 -->
          <div class="d-flex result-quantity-title-container">
            <div>
              1對1視訊
              <span>{{ searchOneOnOneClassQuantity }}筆</span>
            </div>
            <div>
              週課影片
              <span>{{ searchWeeklyClassQuantity }}筆</span>
            </div>
            <div>
              函授教學影片
              <span>{{ searchCorrespondenceEducationQuantity }}筆</span>
            </div>
            <div>
              <a href="javascript:void(0);" @click="courseSearchClean()"
                >清除搜尋</a
              >
            </div>
          </div>

          <!-- 搜尋結果數量顯示，手機板 -->
          <div class="w-100">
            <div
              class="d-flex w-100 align-items-center justify-content-between result-quantity-title-container-mobile"
              style="margin-bottom: 30px"
            >
              <div
                class="d-flex"
                id="search-result-container-mobile"
                @click="searshResultCheckMobile"
                style="
                  border: 1px solid #cccccc;
                  background-color: white;
                  color: #5e5d5d;
                  padding-top: 16px;
                  padding-bottom: 16px;
                  padding-left: 12px;
                  border-radius: 5px;
                  min-width: 73%;
                  cursor: pointer;
                "
              >
                <img
                  src="@/assets/icons/shop/icon_arrow_down_black.svg"
                  alt=""
                  v-if="true"
                />
                <img
                  src="@/assets/icons/shop/icon_arrow_top_black.svg"
                  alt=""
                  v-if="false"
                />
                <!-- <p style="border:1px solid green">30筆搜尋結果</p> -->
                <span style="margin-left: 16px; font-size: 14px; color: #5e5d5d"
                  >{{
                    searchOneOnOneClassQuantity +
                    searchWeeklyClassQuantity +
                    searchCorrespondenceEducationQuantity
                  }}筆搜尋結果</span
                >
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  @click="courseSearchClean()"
                  style="font-size: 14px; color: #4f60f6"
                  >清除搜尋</a
                >
              </div>
            </div>
            <!-- 下拉清單，顯示詳細搜尋結果 -->
            <div class="search-result-detail-mobile">
              <div
                id="search-result-content-mobile"
                class="search-result-content-mobile"
              >
                <div>
                  1對1視訊 <span>{{ searchOneOnOneClassQuantity }}筆</span>
                </div>
                <div>
                  週課影片 <span>{{ searchWeeklyClassQuantity }}筆</span>
                </div>
                <div>
                  函授教學影片
                  <span>{{ searchCorrespondenceEducationQuantity }}筆</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 搜尋出的1對1視訊課程 -->
          <div
            class="one-on-one-class-title-container"
            v-if="searchOneOnOneClassQuantity > 0"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="class-title">1對1視訊</div>
              <a
                href="javascript:void(0)"
                style="text-decoration: none"
                @click="showSearchAllOneOnOne"
                v-if="searchOneOnOne.showAll"
              >
                <span
                  style="
                    margin-right: 19px;
                    vertical-align: middle;
                    color: #5e5d5d;
                    font-size: 14px;
                  "
                  >查看全部</span
                >
                <img
                  src="@/assets/icons/shop/icon_arrow_right_black.svg"
                  alt=""
                />
              </a>
            </div>

            <div
              class="d-flex flex-wrap justify-content-between one-on-one-class-container searchPcVersion"
            >
              <!-- 透過class one-on-one-class-margin控制卡片無法填滿一整排時的排版，搭配justify-content-between -->
              <CourseCard
                v-for="(item, index) in searchOneOnOneClassInfo"
                :key="searchOneOnOneClassInfo[index]"
                :info="searchOneOnOneClassInfo[index]"
                :index="index"
                :item="'searchOneOnOneClassInfo'"
                class="course-card-mb-0 course-card-margin"
                @favoriteStatusChange="toast"
                @favoriteIconClick="favoriteItemsUpdate"
                @addToExchangeList="addToExchangeList"
              />
            </div>

            <!-- 手機板放大鏡搜尋1對1課程顯示 -->
            <div
              class="d-flex flex-wrap justify-content-between one-on-one-class-container searchMobileVersion"
            >
              <!-- 透過class one-on-one-class-margin控制卡片無法填滿一整排時的排版，搭配justify-content-between -->
              <CourseCard
                v-for="(item, index) in searchOneOnOneClassInfo"
                :key="searchOneOnOneClassInfo[index]"
                :info="searchOneOnOneClassInfo[index]"
                :index="index"
                :item="'searchOneOnOneClassInfo'"
                class="course-card-mb-0 course-card-margin"
                @favoriteStatusChange="toast"
                @favoriteIconClick="favoriteItemsUpdate"
                @addToExchangeList="addToExchangeList"
                v-show="searchOneOnOneChange == index"
              />
              <button
                class="searchChangeReduce d-flex justify-content-center align-items-center"
                v-if="
                  searchOneOnOneClassInfo.length > 1 &&
                  searchOneOnOneChange != 0
                "
                @click="oneOnOneSearchReduce"
              >
                <i class="bi bi-arrow-left"></i>
              </button>
              <button
                class="searchChangeAdd d-flex justify-content-center align-items-center"
                v-if="
                  searchOneOnOneClassInfo.length > 1 &&
                  searchOneOnOneChange != searchOneOnOneClassInfo.length - 1
                "
                @click="oneOnOneSearchAdd"
              >
                <i class="bi bi-arrow-right"></i>
              </button>
            </div>
          </div>

          <!-- 搜尋出的週課影片 -->
          <div
            class="weekly-class-title-container"
            v-if="searchWeeklyClassQuantity > 0"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="class-title">週課影片</div>
              <a
                href="javascript:void(0)"
                style="text-decoration: none"
                @click="showSearchAllWeeklyClass"
                v-if="searchWeeklyClass.showAll"
              >
                <span
                  style="
                    margin-right: 19px;
                    vertical-align: middle;
                    color: #5e5d5d;
                    font-size: 14px;
                  "
                  >查看全部</span
                >
                <img
                  src="@/assets/icons/shop/icon_arrow_right_black.svg"
                  alt=""
                />
              </a>
            </div>

            <div
              class="d-flex flex-wrap justify-content-between weekly-class-container searchPcVersion"
            >
              <CourseCard
                v-for="(item, index) in searchWeeklyClassInfo"
                :key="searchWeeklyClassInfo[index]"
                :info="searchWeeklyClassInfo[index]"
                :index="index"
                :item="'searchWeeklyClassInfo'"
                class="course-card-mb-0 course-card-margin"
                @favoriteStatusChange="toast"
                @favoriteIconClick="favoriteItemsUpdate"
                @addToExchangeList="addToExchangeList"
              />
            </div>

            <!-- 手機板週課放大鏡搜尋顯示 -->
            <div
              class="d-flex flex-wrap justify-content-between weekly-class-container searchMobileVersion"
            >
              <CourseCard
                v-for="(item, index) in searchWeeklyClassInfo"
                :key="searchWeeklyClassInfo[index]"
                :info="searchWeeklyClassInfo[index]"
                :index="index"
                :item="'searchWeeklyClassInfo'"
                class="course-card-mb-0 course-card-margin"
                @favoriteStatusChange="toast"
                @favoriteIconClick="favoriteItemsUpdate"
                @addToExchangeList="addToExchangeList"
                v-show="searchWeeklyClassChange == index"
              />
              <button
                class="searchChangeReduce d-flex justify-content-center align-items-center"
                v-if="
                  searchWeeklyClassInfo.length > 1 &&
                  searchWeeklyClassChange != 0
                "
                @click="weeklyClassSearchReduce"
              >
                <i class="bi bi-arrow-left"></i>
              </button>
              <button
                class="searchChangeAdd d-flex justify-content-center align-items-center"
                v-if="
                  searchWeeklyClassInfo.length > 1 &&
                  searchWeeklyClassChange != searchWeeklyClassInfo.length - 1
                "
                @click="weeklyClassSearchAdd"
              >
                <i class="bi bi-arrow-right"></i>
              </button>
            </div>
          </div>

          <!-- 搜尋出的函授教學影片 -->
          <div
            class="weekly-class-title-container"
            v-if="searchCorrespondenceEducationQuantity > 0"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="class-title">函授教學影片</div>
              <a
                href="javascript:void(0)"
                style="text-decoration: none"
                @click="showSearchAllCorrespondenceEducation"
                v-if="searchCorrespondenceEducation.showAll"
              >
                <span
                  style="
                    margin-right: 19px;
                    vertical-align: middle;
                    color: #5e5d5d;
                    font-size: 14px;
                  "
                  >查看全部</span
                >
                <img
                  src="@/assets/icons/shop/icon_arrow_right_black.svg"
                  alt=""
                />
              </a>
            </div>

            <div
              class="d-flex flex-wrap justify-content-between weekly-class-container searchPcVersion"
            >
              <CourseCard
                v-for="(item, index) in searchCorrespondenceEducationInfo"
                :key="searchCorrespondenceEducationInfo[index]"
                :info="searchCorrespondenceEducationInfo[index]"
                :index="index"
                :item="'searchCorrespondenceEducationInfo'"
                class="course-card-mb-0 course-card-margin"
                @favoriteStatusChange="toast"
                @favoriteIconClick="favoriteItemsUpdate"
                @addToExchangeList="addToExchangeList"
              />
            </div>

            <!-- 手機板函授影片放大鏡搜尋 -->
            <div
              class="d-flex flex-wrap justify-content-between weekly-class-container searchMobileVersion"
            >
              <CourseCard
                v-for="(item, index) in searchCorrespondenceEducationInfo"
                :key="searchCorrespondenceEducationInfo[index]"
                :info="searchCorrespondenceEducationInfo[index]"
                :index="index"
                :item="'searchCorrespondenceEducationInfo'"
                class="course-card-mb-0 course-card-margin"
                @favoriteStatusChange="toast"
                @favoriteIconClick="favoriteItemsUpdate"
                @addToExchangeList="addToExchangeList"
                v-show="searchCorrespondenceEducationChange == index"
              />

              <button
                class="searchChangeReduce d-flex justify-content-center align-items-center"
                v-if="
                  searchCorrespondenceEducationInfo.length > 1 &&
                  searchCorrespondenceEducationChange != 0
                "
                @click="correspondenceEducationSearchReduce"
              >
                <i class="bi bi-arrow-left"></i>
              </button>
              <button
                class="searchChangeAdd d-flex justify-content-center align-items-center"
                v-if="
                  searchCorrespondenceEducationInfo.length > 1 &&
                  searchCorrespondenceEducationChange !=
                    searchCorrespondenceEducationInfo.length - 1
                "
                @click="correspondenceEducationSearchAdd"
              >
                <i class="bi bi-arrow-right"></i>
              </button>
            </div>
          </div>

          <ClassFilterPageEmpty
            v-if="viewsInside == 'search-result-all-empty'"
          />
        </div>
      </div>

      <!-- 放大鏡搜尋影片顯示全部頁面 search-result-all -->
      <div
        class="course-content"
        v-show="views == 'search-result-all' && apiData.isPending == false"
      >
        <!-- 課程名稱 -->
        <div
          class="d-flex align-items-center course-category"
          style="margin-bottom: 40px"
        >
          <div id="searchBackImg" class="d-flex align-items-center">
            <img
              src="@/assets/icons/shop/icon_arrow_left_black.svg"
              alt=""
              style="
                width: 12px;
                height: 12px;
                margin-right: 19px;
                cursor: pointer;
              "
            />
            <span style="cursor: pointer">{{ searchResultTitle }}</span>
          </div>
        </div>

        <!-- 課程卡片內容 -->
        <div
          class="d-flex flex-wrap justify-content-between course-card-container"
        >
          <!-- @openModal指的是監聽裡面$emit的值 -->
          <CourseCard
            v-for="(item, index) in searchResultAllInfo"
            :key="searchResultAllInfo[index]"
            :info="searchResultAllInfo[index]"
            :index="index"
            :item="'searchResultAllInfo'"
            class="course-card-mb-0 course-card-margin"
            @openModal="openCourseDetailModal"
            @favoriteStatusChange="toast"
            @favoriteIconClick="favoriteItemsUpdate"
            @addToExchangeList="addToExchangeList"
          />
        </div>
        <!-- 分頁切換功能，Pagination -->
        <div class="pagination d-flex flex-row align-items-center">
          <Pagination
            :isPending="pagination.searchResultAll.isPending"
            :data="pagination.searchResultAll.data"
            :currentPage="pagination.searchResultAll.currentPage"
            :totalPage="pagination.searchResultAll.totalPage"
            @changePage="changePage($event)"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Alert Toast -->
  <div id="exchange-alert" class="exchange-alert">
    <div class="d-flex flex-row">
      <span id="exchange-alert-text"></span>
      <i @click="hideToast()" class="bi bi-x"></i>
    </div>
  </div>

  <!-- <FavoriteList :key="favoriteList" info="favoriteList"/> -->
  <!-- 原本是套用上面的寫法，但會導致收藏清單內切換頁面按鈕失效，以及卡片內容無法順利照數量渲染，後來改成在裡面執行自己的axios -->
  <FavoriteList
    ref="shopPickFavoriteList"
    @updateExchangeList="updateExchangeListFromFavoriteList"
    @favoriteItemRemove="favoriteRemoveFromFavoriteList"
  />
  <OneOnoOneFilterPage
    ref="oneOnoOneFilterPage"
    @oneOnOneMobileFilter="oneOnOneMobileFilter"
  />
  <ClassFilterMobilePage
    ref="classFilterMobilePage"
    @weeklyClassMobileFilter="weeklyClassMobileFilter"
    :allLecturerList="allLecturerList"
    :allSubjects="allSubjects"
    :categoryLevelTypes="categoryLevelTypes"
  />
</template>

<script>
import { defineComponent } from "vue";
import mixins from "@/mixins/index";
import CourseCard from "@/components/OnlineShop/CourseCard.vue";
import FavoriteList from "@/components/OnlineShop/FavoriteList.vue";
import OneOnoOneFilterPage from "@/components/OnlineShop/OneOnoOneFilterPage.vue";
import ClassFilterMobilePage from "@/components/OnlineShop/ClassFilterMobliePage.vue";
import ClassFilterPageEmpty from "@/components/OnlineShop/ClassFilterPageEmpty.vue";
import ExchangeListItem from "@/components/OnlineShop/ExchangeListItem.vue";
import Pagination from "@/components/Pagination.vue";
import { Modal } from "bootstrap";
import axios from "axios";
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";

// ↓ 引入課程主視覺banner
// 由於是透過css的background-image進行圖片設定，並且要透過js進行圖片修改
// 由於webpack的設定關係，路徑會使用@/這樣的相對路徑形式，因此要先import引入生成路徑
// 之夠在js內直接引用，才可以正常顯示圖片
import oneOnOneBanner1920 from "@/assets/img/shop/banner/oneOnOne/1920.png";
import oneOnOneBanner1366 from "@/assets/img/shop/banner/oneOnOne/1366.png";
import oneOnOneBanner1024 from "@/assets/img/shop/banner/oneOnOne/1024.png";
import oneOnOneBanner768 from "@/assets/img/shop/banner/oneOnOne/768.png";
import oneOnOneBanner415 from "@/assets/img/shop/banner/oneOnOne/415.png";
import oneOnOneBanner320 from "@/assets/img/shop/banner/oneOnOne/320.png";

import weeklyClassBanner1920 from "@/assets/img/shop/banner/weeklyClass/1920.png";
import weeklyClassBanner1366 from "@/assets/img/shop/banner/weeklyClass/1366.png";
import weeklyClassBanner1024 from "@/assets/img/shop/banner/weeklyClass/1024.png";
import weeklyClassBanner768 from "@/assets/img/shop/banner/weeklyClass/768.png";
import weeklyClassBanner415 from "@/assets/img/shop/banner/weeklyClass/415.png";
import weeklyClassBanner320 from "@/assets/img/shop/banner/weeklyClass/320.png";

import correspondenceEducationBanner1920 from "@/assets/img/shop/banner/correspondenceEducation/1920.png";
import correspondenceEducationBanner1366 from "@/assets/img/shop/banner/correspondenceEducation/1366.png";
import correspondenceEducationBanner1024 from "@/assets/img/shop/banner/correspondenceEducation/1024.png";
import correspondenceEducationBanner768 from "@/assets/img/shop/banner/correspondenceEducation/768.png";
import correspondenceEducationBanner415 from "@/assets/img/shop/banner/correspondenceEducation/415.png";
import correspondenceEducationBanner320 from "@/assets/img/shop/banner/correspondenceEducation/320.png";

// ↑ 引入課程主視覺banner

// axios環境變數宣告
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}`;

export default defineComponent({
  name: "OnlineShop-Shop",
  components: {
    CourseCard,
    FavoriteList,
    OneOnoOneFilterPage,
    ClassFilterMobilePage,
    ClassFilterPageEmpty,
    ExchangeListItem,
    Pagination,
    DatePicker,
  },
  mixins: [mixins],
  data() {
    return {
      allSubjects: [], // api 取得的所有 subject
      allowSubjects: [], // 過濾過的 subject
      categoryLevelTypes: {
        language: [
          {
            label: "等級 L1~L3",
            value: 1,
          },
          {
            label: "等級 L4~L6",
            value: 4,
          },
          {
            label: "等級 L7~L9",
            value: 7,
          },
        ],
        skill: [
          {
            label: "入門",
            value: 1,
          },
          {
            label: "中階",
            value: 2,
          },
          {
            label: "進階",
            value: 3,
          },
        ],
        en: [
          {
            label: "等級 L1~L3",
            value: 1,
          },
          {
            label: "等級 L4~L6",
            value: 4,
          },
          {
            label: "等級 L7~L9",
            value: 7,
          },
        ],
        jp: [
          {
            label: "N5",
            value: 1,
          },
          {
            label: "N4",
            value: 2,
          },
          {
            label: "N3",
            value: 3,
          },
          {
            label: "N2",
            value: 4,
          },
          {
            label: "N1",
            value: 5,
          },
        ],
        special: [
          {
            label: "入門",
            value: 1,
          },
          {
            label: "中階",
            value: 2,
          },
          {
            label: "進階",
            value: 3,
          },
        ],
      },
      VIEW: {
        one_on_one: "one-on-one",
        weekly_class: "weekly-class",
        correspondence_education: "correspondence-education",
        search_result_all: "search-result-all",
      },
      allLecturerList: [],
      allowLecturerList: [],
      weeklyCorrespondenceClassLevels: [],

      searchOneOnOneChange: 0,
      searchWeeklyClassChange: 0,
      searchCorrespondenceEducationChange: 0,
      // 用於date Picker
      dateWeeklyCorrespondence: new Date(),
      dateWeeklyCorrespondenceMobile: new Date(),

      totalPage: 10000,
      // ↓  用於切換畫面
      // views:'search-result-all',
      views: "one-on-one",
      viewsInside: "weekly-class-filter-not-empty",
      viewsBuffer: "",
      // ↑  用於切換畫面

      // ↓ 顯示課程數量
      courseAllQuantity: 0,
      weeklyCorrespondenceQuantity: 1,
      searchOneOnOneClassQuantity: 66,
      searchWeeklyClassQuantity: 77,
      searchCorrespondenceEducationQuantity: 88,
      exchangeListItemsQuantity: 0,
      addToFavoriteListItem: {},

      // ↓ Modal用於mounted時宣告成new Modal
      courseDetailsModal: null,
      favoriteListModal: null,
      oneOnOneFilterPageModal: null,
      weeklyCorrespondenceFilterModal: null,
      // ↑ Modal用於mounted時宣告成new Modal

      // ↓將課程資訊傳入課程卡片內的變數
      // 這邊都宣告成空陣列就可以，裡面如果放假資料但格式錯誤，會造成課程卡片在渲染時出現資料可能讀不到
      // 而空陣列的彈性又在於每一個頁面可以分開打API ，切換頁面時才讀取該頁應該有的課程內容
      courseCardInfo: [],
      courseRecommendCardInfo: [],
      weeklyCorrespondenceCardInfo: [],
      correspondenceEducationCardInfo: [],

      // 放大鏡搜尋資料存取
      searchOneOnOneClassInfo: [],
      searchWeeklyClassInfo: [],
      searchCorrespondenceEducationInfo: [],
      searchResultAllInfo: [],
      searchResultAllStatus: {
        oneOnOne: false,
        weeklyClass: false,
        correspondenceEducation: false,
      },
      searchResultTitle: "",
      // 放大鏡搜尋狀態
      searchOneOnOne: {
        showAll: false,
      },
      searchWeeklyClass: {
        showAll: false,
      },
      searchCorrespondenceEducation: {
        showAll: false,
      },
      // 放大鏡搜尋結果狀態存取，手機板
      showSearchResultMobile: false,

      exchangeList: [],
      // ↑將課程資訊傳入課程卡片內的變數

      // 1對1課程過濾器
      oneOnOneFilter: [],
      oneOnOneFilterPoint: [],
      oneOnOneMobileFilterEnable: false,
      // 週課影片與函授課程過濾器
      weeklyCorrespondenceSubjectFilter: [],
      weeklyCorrespondenceLevelFilter: [],
      weeklyCorrespondenceLecturerFilter: [],
      weeklyCorrespondenceLecturerNameFilter: [],
      weeklyCorrespondenceDateFilter: [],
      weeklyCorrespondenceClassFilterBtn: false,
      weeklyCorrespondenceDatePickerShow: false,
      weeklyCorrespondenceGetAll: false,
      weeklyCorrespondenceMobileFilterEnable: false,

      apiData: {
        displayedNum: 0,
        isPending: false,
        searchDisplayedNum: 0,
      },

      pendingStatus: 0,

      // 換頁選項
      pagination: {
        oneOnOne: {
          isPending: false,
          data: [0],
          currentPage: 1,
          totalPage: 10,
        },
        weeklyCorrespondence: {
          isPending: false,
          data: [0],
          currentPage: 1,
          totalPage: 10,
        },
        searchResultAll: {
          isPending: false,
          data: [0],
          currentPage: 1,
          totalPage: 10,
        },
      },

      courseDetailsId: "",

      myCCoin: 9999,
      showAlert: false,
      toastContent: "",
      items: [
        {
          type: "ai",
          name: "AI互動教學題庫 - 英文 / 文法 / 單字 - 60天",
          ccoin: 600,
          discount: ["活動優惠", "10%off"],
          amount: 540,
          saved: false,
        },
        {
          type: "one",
          name: "1對1視訊課程 - 英文 / 日文 / 特殊語 - 120分鐘",
          ccoin: 800,
          discount: null,
          amount: 800,
          saved: true,
        },
        {
          type: "week",
          name: "週課影片 - 韓文-追星追劇學韓語",
          ccoin: 80,
          discount: ["月底到數折扣", "-30 C幣"],
          amount: 50,
          saved: false,
        },
      ],

      btns: [
        {
          category: "shop",
          imgActive: "shop_turkey",
          imgInactive: "shop_gray",
          name: "學習商店",
          route: "/online-shop?category=shop",
        },
        {
          category: "ccoin",
          imgActive: "ccoin_turkey",
          imgInactive: "ccoin_gray",
          name: "C幣儲值",
          route: "/online-shop?category=ccoin",
        },
        {
          category: "history",
          imgActive: "order_turkey",
          imgInactive: "order_gray",
          name: "兌換紀錄",
          route: "/online-shop?category=history",
        },
      ],

      mobileType: false,
      // 語言類別
    };
  },
  methods: {
    // ↓ axios
    // 目前是透過這一個函式一次將1對1視訊課程、週課影片、函授教學影片的課程資料從本地.json一次取回
    // 實際應用時要拆開

    // 實際應用的API
    userCoinGet() {
      axios
        .get(`${serverUrl}coin/balance`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.myCCoin = res.data.data.coin;
            // this.isPending = false;
          }
        })
        .catch((err) => {
          // console.log(`catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    addDefaultCourseBanner(category, type, picture) {
      if (picture !== null) {
        return picture;
      } else if (category === "skill") {
        return "/static/img/onlineShop/productBannerSkill.png";
      } else if (type == 1) {
        return "/static/img/onlineShop/productBanner0.png";
      } else if (type == 2) {
        return "/static/img/onlineShop/productBanner1.png";
      } else if (type == 3) {
        return "/static/img/onlineShop/productBanner2.png";
      }
    },
    oneOnOneCourseGet(page) {
      this.pendingStatus = this.pendingStatus + 1;

      let data = {
        page: 1, // 第幾頁
        displayedNum: 100, // 資料筆數
        type: 1, // 1 => 一對一視訊、 2 => 週課影片、 3 => 函授影片
        language: [], // null => 全部、13 => 英日、14 => 特殊語言、15 => 托福雅思 ，需為字串陣列
        point: [],
      };
      // 從data內的oneOnOneFilter取參數過來決定要取回什麼語言，原因是filter選項內也會對this.oneOnOneFilter的值進行改變
      // 這樣過濾器被選中後就會針對語言打API修改資料
      data.language = this.oneOnOneFilter;
      data.point = this.oneOnOneFilterPoint;
      data.displayedNum = this.apiData.displayedNum;
      if (page != undefined) {
        data.page = page;
      }
      if ((data.language.length > 0) | (data.point.length > 0)) {
        this.oneOnOneMobileFilterEnable = true;
      } else {
        this.oneOnOneMobileFilterEnable = false;
      }
      axios
        .post(`${serverUrl}learn_shop/list`, data, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          this.courseCardInfo = [];
          if (res.status == 200) {
            // let courseInfoBuffer = [];
            // console.log("1對1");
            // console.log(res.data.data.data);
            for (let i = 0; i < res.data.data.data.length; i++) {
              let courseInfoBuffer = {
                courseFeature: "",
                courseDiscount: "",
                productName: "",
                productDescription: "",
                price: "",
                soldQuantity: "",
                favoriteStatus: "",
                shoppingCartStatus: "",
                id: "",
                index: "",
                type: 0,
                picture: "",
                courseBought: false,
              };
              // 將API的資料轉換成需求格式
              courseInfoBuffer.index = i;

              // 課程特點
              courseInfoBuffer.courseFeature = "1對1視訊課程";

              // 折扣項目顯示
              if (res.data.data.data[i].discountText != undefined) {
                courseInfoBuffer.courseDiscount =
                  res.data.data.data[i].discountText;
              } else {
                courseInfoBuffer.courseDiscount = undefined;
              }
              // 課程標題
              if (res.data.data.data[i].title != undefined) {
                courseInfoBuffer.productName = res.data.data.data[i].title;
              }

              // 課程描述
              if (res.data.data.data[i].description != null) {
                courseInfoBuffer.productDescription =
                  res.data.data.data[i].description;
              } else {
                courseInfoBuffer.productDescription = "";
              }

              // 課程原價及售價
              // coinDiscount是用來判斷有無特價的基準，如果這筆資料有東西代表是課程有折扣
              if (res.data.data.data[i].coinDiscount == undefined) {
                // courseInfoBuffer.courseOriginalPrice是商品原價，會在畫面上槓掉
                courseInfoBuffer.courseOriginalPrice = undefined;
                // courseInfoBuffer.price是商品售價
                courseInfoBuffer.price = res.data.data.data[i].coin;
              } else {
                courseInfoBuffer.courseOriginalPrice =
                  res.data.data.data[i].coin;
                courseInfoBuffer.price = res.data.data.data[i].coinDiscount;
              }
              // 圖片來源
              courseInfoBuffer.picture = this.addDefaultCourseBanner(
                res.data.data.data[i].category,
                res.data.data.data[i].type,
                res.data.data.data[i].picture
              );

              // 已售出數量
              courseInfoBuffer.soldQuantity = res.data.data.data[i].sales;

              // 收藏狀態
              courseInfoBuffer.favoriteStatus = res.data.data.data[i].like;

              // 兌換清單狀態
              courseInfoBuffer.shoppingCartStatus =
                res.data.data.data[i].shoppingCart;

              // 是否購買
              courseInfoBuffer.courseBought = res.data.data.data[i].buy;
              // uid寫入
              courseInfoBuffer.id = res.data.data.data[i].id;

              courseInfoBuffer.type = res.data.data.data[i].type;

              // 是否購買
              courseInfoBuffer.courseBought = res.data.data.data[i].buy;

              this.courseCardInfo.push(courseInfoBuffer);
            }
            this.pagination.oneOnOne.currentPage = res.data.data.currentPage;
            this.pagination.oneOnOne.totalPage = res.data.data.totalPage;
            this.courseAllQuantity = this.courseQuantityCalculate(
              this.courseCardInfo
            );
            if (this.courseAllQuantity == 0) {
              this.viewsInside = "one-on-one-filter-empty";
            } else {
              this.viewsInside = "one-on-one-filter-not-empty";
            }

            // console.log('轉換後的資料')
            // console.log(this.courseCardInfo)

            this.pendingStatus = this.pendingStatus - 1;
          }
        })
        .catch((err) => {
          // console.log(`1對1的catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    recommendCourseGet() {
      this.pendingStatus = this.pendingStatus + 1;
      const limit = 4;
      const type = 0;
      // this.isPending = true;
      axios
        .get(`${serverUrl}learn_shop/recommend?limit=${limit}&type=${type}`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          this.courseRecommendCardInfo = [];
          if (res.status == 200) {
            for (let i = 0; i < res.data.data.length; i++) {
              let courseInfoBuffer = {
                courseFeature: "",
                courseDiscount: "",
                courseLevel: "",
                productName: "",
                productDescription: "",
                price: "",
                soldQuantity: "",
                favoriteStatus: "",
                shoppingCartStatus: "",
                id: "",
                index: 0,
                type: 0,
                picture: "",
                courseBought: false,
              };
              // 將API的資料轉換成需求格式

              courseInfoBuffer.index = i;
              // 課程特點
              if (res.data.data[i].type == "1") {
                courseInfoBuffer.courseFeature = "1對1視訊課程";
              } else if (res.data.data[i].type == "2") {
                courseInfoBuffer.courseFeature = "週課影片";
              } else if (res.data.data[i].type == "3") {
                courseInfoBuffer.courseFeature = "函授課程";
              }

              // 折扣百分比計算，四捨五入，透過折扣金額及販售金額計算
              if (res.data.data[i].discountText != null) {
                courseInfoBuffer.courseDiscount = res.data.data[i].discountText;
              } else {
                courseInfoBuffer.courseDiscount = undefined;
              }
              // 課程等級
              courseInfoBuffer.courseLevel = this.categorySubjectLevelMap(
                this.categorySubjectMap(
                  res.data.data[i].category,
                  res.data.data[i].language
                ),
                res.data.data[i].level
              );

              // 課程標題
              if (res.data.data[i].title != undefined) {
                courseInfoBuffer.productName = res.data.data[i].title;
              }

              // 課程說明
              if (res.data.data[i].description != null) {
                courseInfoBuffer.productDescription =
                  res.data.data[i].description;
              }

              if (res.data.data[i].expirationDate != null) {
                courseInfoBuffer.videoDate = `上架日期 : ${res.data.data[
                  i
                ].launchDate.substr(0, 10)}`;
                // 即將下架計算
                let actDate = new Date();
                let videoDate = new Date(
                  `${res.data.data[i].expirationDate.substr(0, 10)}`
                );
                let difference = Math.abs(videoDate - actDate);
                let days = Math.ceil(difference / (1000 * 3600 * 24));
                // 計算日期小於10天才顯示即將下架
                if (days <= 10) {
                  courseInfoBuffer.willBeDiscontinued = days;
                } else {
                  // 如果條件不滿足必須將其設定成undefined ，因為在courseCard內都是透過undefined判斷是否顯示
                  courseInfoBuffer.willBeDiscontinued = undefined;
                }
              }

              // 圖片來源
              courseInfoBuffer.picture = this.addDefaultCourseBanner(
                res.data.data[i].category,
                res.data.data[i].type,
                res.data.data[i].picture
              );

              // 課程原價及售價
              // coinDiscount是用來判斷有無特價的基準，如果這筆資料有東西代表是課程有折扣
              if (res.data.data[i].coinDiscount == undefined) {
                // courseInfoBuffer.courseOriginalPrice是商品原價，會在畫面上槓掉
                courseInfoBuffer.courseOriginalPrice = undefined;
                // courseInfoBuffer.price是商品售價
                courseInfoBuffer.price = res.data.data[i].coin;
              } else {
                courseInfoBuffer.courseOriginalPrice = res.data.data[i].coin;
                courseInfoBuffer.price = res.data.data[i].coinDiscount;
              }

              // 已售出數量
              courseInfoBuffer.soldQuantity = res.data.data[i].sales;

              // 收藏狀態
              courseInfoBuffer.favoriteStatus = res.data.data[i].like;

              // 兌換清單狀態
              courseInfoBuffer.shoppingCartStatus =
                res.data.data[i].shoppingCart;

              // 是否購買
              courseInfoBuffer.courseBought = res.data.data[i].buy;

              // uid寫入
              courseInfoBuffer.id = res.data.data[i].id;
              courseInfoBuffer.type = res.data.data[i].type;

              // 是否購買
              courseInfoBuffer.courseBought = res.data.data[i].buy;

              this.courseRecommendCardInfo.push(courseInfoBuffer);
            }
            this.pendingStatus = this.pendingStatus - 1;
            // console.log('為你推薦API')
            // console.log(res.data.data);
            // console.log('轉換後的資料')
            // console.log(this.courseRecommendCardInfo);
          }
        })
        .catch((err) => {
          // console.log(`為你推薦catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    formatWeeklyCourse(courseData) {
      return courseData.map((item, index) => {
        let courseDiscount = "";
        let willBeDiscontinued = "";
        let courseOriginalPrice = undefined;
        let price = "";
        let videoDate = "";

        // 折扣百分比計算，四捨五入，透過折扣金額及販售金額計算
        if (item.discountText != null) {
          courseDiscount = item.discountText;
        } else {
          courseDiscount = undefined;
        }

        videoDate = `上課日期 : ${item.classDate.substr(0, 10)}`;

        // 即將下架計算
        const actDate = new Date();
        const videoExpirationDate = new Date(
          `${item.expirationDate.substr(0, 10)}`
        );
        const difference = Math.abs(videoExpirationDate - actDate);
        const days = Math.ceil(difference / (1000 * 3600 * 24));
        // 計算日期小於10天才顯示即將下架
        if (days <= 10) {
          willBeDiscontinued = days;
        } else {
          // 如果條件不滿足必須將其設定成undefined ，因為在courseCard內都是透過undefined判斷是否顯示
          willBeDiscontinued = undefined;
        }

        // 課程原價及售價
        // coinDiscount是用來判斷有無特價的基準，如果這筆資料有東西代表是課程有折扣
        if (item.coinDiscount == undefined) {
          // courseInfoBuffer.courseOriginalPrice是商品原價，會在畫面上槓掉
          courseOriginalPrice = undefined;
          // courseInfoBuffer.price是商品售價
          price = item.coin;
        } else {
          courseOriginalPrice = item.coin;
          price = item.coinDiscount;
        }

        return {
          courseFeature: `週課影片 - ${this.courseTypedecode(item.language)}`,
          courseLevel: this.categorySubjectLevelMap(
            this.categorySubjectMap(item.category, item.language),
            item.level
          ),
          courseDiscount: courseDiscount,
          productName: item.title,
          productDescription: `授課講師 : ${item.foreignName}`,
          videoDate: videoDate,
          willBeDiscontinued: willBeDiscontinued,
          courseOriginalPrice: courseOriginalPrice,
          price: price,
          soldQuantity: item.sales,
          favoriteStatus: item.like,
          shoppingCartStatus: item.shoppingCart,
          id: item.id,
          language: item.language,
          index: index,
          type: item.type,
          courseBought: item.buy,
          picture: this.addDefaultCourseBanner(
            item.category,
            item.type,
            item.picture
          ),
        };
      });
    },
    formatCorrespondenceEducation(courseData) {
      return courseData.map((item, index) => {
        let courseDiscount = "";
        let willBeDiscontinued = "";
        let courseOriginalPrice = undefined;
        let price = "";
        let videoDate = "";

        // 折扣百分比計算，四捨五入，透過折扣金額及販售金額計算
        if (item.discountText != null) {
          courseDiscount = item.discountText;
        } else {
          courseDiscount = undefined;
        }
        let days;
        if (item.expirationDate != null) {
          // 影片日期
          videoDate = `上架日期 : ${item.launchDate.substr(0, 10)}`;

          // 即將下架計算
          const actDate = new Date();
          const launchVideoDate = new Date(
            `${item.expirationDate.substr(0, 10)}`
          );
          const difference = Math.abs(launchVideoDate - actDate);
          days = Math.ceil(difference / (1000 * 3600 * 24));
        }
        // 計算日期小於10天才顯示即將下架
        if (days <= 10) {
          willBeDiscontinued = days;
        } else {
          // 如果條件不滿足必須將其設定成undefined ，因為在courseCard內都是透過undefined判斷是否顯示
          willBeDiscontinued = undefined;
        }
        // 課程原價及售價
        // coinDiscount是用來判斷有無特價的基準，如果這筆資料有東西代表是課程有折扣
        if (item.coinDiscount == undefined) {
          // courseInfoBuffer.courseOriginalPrice是商品原價，會在畫面上槓掉
          courseOriginalPrice = undefined;
          // courseInfoBuffer.price是商品售價
          price = item.coin;
        } else {
          courseOriginalPrice = item.coin;
          price = item.coinDiscount;
        }
        return {
          courseFeature: `函授影片 - ${this.courseTypedecode(item.language)}`,
          courseLevel: this.categorySubjectLevelMap(
            this.categorySubjectMap(item.category, item.language),
            item.level
          ),
          courseDiscount: courseDiscount,
          productName: item.title,
          productDescription: `授課講師 : ${item.foreignName}`,
          videoDate: videoDate,
          willBeDiscontinued: willBeDiscontinued,
          courseOriginalPrice: courseOriginalPrice,
          price: price,
          soldQuantity: item.sales,
          favoriteStatus: item.like,
          shoppingCartStatus: item.shoppingCart,
          id: item.id,
          language: item.language,
          index: index,
          type: item.type,
          courseBought: item.buy,
          picture: this.addDefaultCourseBanner(
            item.category,
            item.type,
            item.picture
          ),
        };
      });
    },
    // 查詢週課或函授教學影片的資料
    weeklyCorrespondenceCourseGet(view, language, level, teacher, date, page) {
      this.weeklyCorrespondenceGetAll = true;
      this.weeklyCorrespondenceMobileFilterEnable = false;
      this.pendingStatus = this.pendingStatus + 1;
      let data = {
        page: 1, // 第幾頁
        displayedNum: this.apiData.displayedNum, // 資料筆數
        type: view === this.VIEW.weekly_class ? 2 : 3, // 1 => 一對一視訊、 2 => 週課影片、 3 => 函授影片
        language: [],
        level: [],
        teacher: [],
        date: [],
      };

      if (language != undefined) {
        data.language = language;
      }
      if (level != undefined) {
        data.level = level;
      }
      if (teacher != undefined) {
        data.teacher = teacher;
      }
      if (date != undefined) {
        data.date = date;
      }
      if (page != undefined) {
        data.page = page;
      }
      axios
        .post(`${serverUrl}learn_shop/list`, data, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          this.weeklyCorrespondenceCardInfo = [];
          if (res.status == 200) {
            const mainData = res.data.data.data;
            // 判斷 view 來決定要怎麼 format 資料
            if (view === this.VIEW.weekly_class) {
              this.weeklyCorrespondenceCardInfo =
                this.formatWeeklyCourse(mainData);
            } else {
              this.weeklyCorrespondenceCardInfo =
                this.formatCorrespondenceEducation(mainData);
            }
            this.weeklyCorrespondenceFilterModal.hide();
            this.weeklyCorrespondenceQuantity = this.courseQuantityCalculate(
              this.weeklyCorrespondenceCardInfo
            );
            if (this.weeklyCorrespondenceQuantity == 0) {
              this.viewsInside = "weekly-class-filter-empty";
            } else {
              this.viewsInside = "weekly-class-filter-not-empty";
            }

            this.pagination.weeklyCorrespondence.data =
              this.weeklyCorrespondenceCardInfo;
            this.pagination.weeklyCorrespondence.currentPage =
              res.data.data.currentPage;
            this.pagination.weeklyCorrespondence.totalPage =
              res.data.data.totalPage;

            this.weeklyCorrespondenceGetAll = false;
          }
          this.pendingStatus = this.pendingStatus - 1;
        })
        .catch((err) => {
          console.log(`週課影片catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    weeklyCorrespondenceCourseFilterGet(
      view,
      language,
      level,
      teacher,
      date,
      page
    ) {
      this.pendingStatus = this.pendingStatus + 1;
      if (
        (language.length > 0) |
        (level.length > 0) |
        (teacher.length > 0) |
        (date.length > 0)
      ) {
        this.weeklyCorrespondenceClassFilterBtn = true;
      }

      let data = {
        page: 1, // 第幾頁
        displayedNum: 16, // 資料筆數
        type: view === this.VIEW.weekly_class ? 2 : 3, // 1 => 一對一視訊、 2 => 週課影片、 3 => 函授影片
        language: language,
        level: level,
        teacher: teacher,
        date: [],
      };
      data.displayedNum = this.apiData.displayedNum;
      if (page != undefined) {
        data.page = page;
      }
      if (date != undefined) {
        data.date = date;
      }

      axios
        .post(`${serverUrl}learn_shop/list`, data, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          this.weeklyCorrespondenceCardInfo = [];
          if (res.status == 200) {
            const mainData = res.data.data.data;
            if (view === this.VIEW.weekly_class) {
              this.weeklyCorrespondenceCardInfo =
                this.formatWeeklyCourse(mainData);
            } else {
              this.weeklyCorrespondenceCardInfo =
                this.formatCorrespondenceEducation(mainData);
            }

            this.pagination.weeklyCorrespondence.data =
              this.weeklyCorrespondenceCardInfo;
            this.pagination.weeklyCorrespondence.currentPage =
              res.data.data.currentPage;
            this.pagination.weeklyCorrespondence.totalPage =
              res.data.data.totalPage;

            this.weeklyCorrespondenceQuantity = this.courseQuantityCalculate(
              this.weeklyCorrespondenceCardInfo
            );
            // 判斷是否顯示空白畫面

            if (this.weeklyCorrespondenceQuantity == 0) {
              this.viewsInside = "weekly-class-filter-empty";
            } else {
              this.viewsInside = "weekly-class-filter-not-empty";
            }
            this.pendingStatus = this.pendingStatus - 1;
          }
        })
        .catch((err) => {
          console.log(`週課過濾器catch觸發 :${err}`);
        });
    },

    // 週課語言過濾器功能綁定
    weeklyCorrespondenceFilterSubject(subjectInfo, category) {
      this.weeklyCorrespondenceSubjectFilter = [];
      this.weeklyCorrespondenceSubjectFilter.push(subjectInfo.subject);
      // 根據 category，找出對應的 level 選項

      this.weeklyCorrespondenceClassLevels =
        this.categoryLevelTypes[
          this.categorySubjectMap(category, subjectInfo.subject)
        ] ?? [];
      this.weeklyCorrespondenceLevelFilter = [];
    },
    clearWeeklyCorrespondenceFilterLanguage() {
      this.weeklyCorrespondenceSubjectFilter = [];
      this.weeklyCorrespondenceClassLevels = [];
      this.weeklyCorrespondenceLevelFilter = [];
    },
    weeklyCorrespondenceFilterSubjectLevel(level) {
      this.weeklyCorrespondenceLevelFilter = [];
      this.weeklyCorrespondenceLevelFilter = [level];
    },
    clearWeeklyCorrespondenceFilterSubjectLevel() {
      this.weeklyCorrespondenceLevelFilter = [];
    },
    // 清除週課過濾器
    clearWeeklyCorrespondenceFilter() {
      // 清除語言選項
      this.weeklyCorrespondenceSubjectFilter = [];

      // 清除課程等級
      this.weeklyCorrespondenceClassLevels = [];
      this.weeklyCorrespondenceLevelFilter = [];

      // 清除老師選項
      this.weeklyCorrespondenceLecturerFilter = [];
      this.weeklyCorrespondenceLecturerNameFilter = [];
      this.weeklyCorrespondenceClassFilterBtn = false;

      // 清除時間日期選項

      this.dateWeeklyCorrespondence = null;
      this.weeklyCorrespondenceDateFilter = [];
      this.weeklyCorrespondenceDatePickerShow = false;
    },

    backToAllCourse(courseType) {
      if (courseType === this.VIEW.one_on_one) {
        this.oneOnOneFilter = [];
        this.oneOnOneFilterPoint = [];
        this.$refs.oneOnoOneFilterPage.cleanSearch();
        this.courseSelectOneOnOne();
      } else if (
        courseType === this.VIEW.weekly_class ||
        courseType === this.VIEW.correspondence_education
      ) {
        this.courseSelectWeeklyCorrespondenceClass(courseType);
        this.$refs.classFilterMobilePage.cleanSearch();
        this.clearWeeklyCorrespondenceFilter();
      }
    },

    // 放大鏡搜尋
    // 最外層第一次搜尋，用來顯示三筆資料
    oneOnOneCourseSearch(keyWord, page, displayedNum) {
      this.pendingStatus = this.pendingStatus + 1;
      let data = {
        page: 0, // 第幾頁
        displayedNum: 9999999, // 資料筆數
        type: 1, // 1 => 一對一視訊、 2 => 週課影片、 3 => 函授影片
        keyWord: keyWord,
      };
      if (page != undefined) {
        data.page = page;
        // console.log(`API打得˙頁數${data.page}`)
      }
      if (displayedNum != undefined) {
        data.displayedNum = displayedNum;
      }
      this.searchOneOnOneClassInfo = [];
      axios
        .post(`${serverUrl}learn_shop/list`, data, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          // console.log(res.data.data)
          let length = 0;
          let screenWidth = window.innerWidth;
          if (res.data.data.total > 3) {
            if (screenWidth >= 1920) {
              length = 4;
            } else {
              length = 3;
            }
          } else {
            length = res.data.data.data.length;
          }
          if (res.status == 200) {
            for (let i = 0; i < length; i++) {
              let courseInfoBuffer = {
                courseFeature: "",
                courseDiscount: "",
                productName: "",
                productDescription: "",
                price: "",
                soldQuantity: "",
                favoriteStatus: "",
                shoppingCartStatus: "",
                id: "",
                index: "",
                type: 0,
                courseBought: false,
              };
              // 將API的資料轉換成需求格式
              courseInfoBuffer.index = i;

              // 課程特點
              courseInfoBuffer.courseFeature = "1對1視訊課程";

              // 折扣項目顯示
              if (res.data.data.data[i].discountText != undefined) {
                courseInfoBuffer.courseDiscount =
                  res.data.data.data[i].discountText;
              } else {
                courseInfoBuffer.courseDiscount = undefined;
              }
              // 課程標題
              if (res.data.data.data[i].title != undefined) {
                courseInfoBuffer.productName = res.data.data.data[i].title;
              }

              // 課程描述
              if (res.data.data.data[i].description != null) {
                courseInfoBuffer.productDescription =
                  res.data.data.data[i].description;
              } else {
                courseInfoBuffer.productDescription = "";
              }

              // 課程原價及售價
              // coinDiscount是用來判斷有無特價的基準，如果這筆資料有東西代表是課程有折扣
              if (res.data.data.data[i].coinDiscount == undefined) {
                // courseInfoBuffer.courseOriginalPrice是商品原價，會在畫面上槓掉
                courseInfoBuffer.courseOriginalPrice = undefined;
                // courseInfoBuffer.price是商品售價
                courseInfoBuffer.price = res.data.data.data[i].coin;
              } else {
                courseInfoBuffer.courseOriginalPrice =
                  res.data.data.data[i].coin;
                courseInfoBuffer.price = res.data.data.data[i].coinDiscount;
              }

              // 圖片來源
              courseInfoBuffer.picture = this.addDefaultCourseBanner(
                res.data.data.data[i].category,
                res.data.data.data[i].type,
                res.data.data.data[i].picture
              );

              // 已售出數量
              courseInfoBuffer.soldQuantity = res.data.data.data[i].sales;

              // 收藏狀態
              courseInfoBuffer.favoriteStatus = res.data.data.data[i].like;

              // 兌換清單狀態
              courseInfoBuffer.shoppingCartStatus =
                res.data.data.data[i].shoppingCart;

              // 是否購買
              courseInfoBuffer.courseBought = res.data.data.data[i].buy;

              // uid寫入
              courseInfoBuffer.id = res.data.data.data[i].id;

              courseInfoBuffer.type = res.data.data.data[i].type;

              // 購買狀態
              courseInfoBuffer.courseBought = res.data.data.data[i].buy;

              this.searchOneOnOneClassInfo.push(courseInfoBuffer);
            }
            this.searchOneOnOneClassQuantity = res.data.data.total;
            this.pendingStatus = this.pendingStatus - 1;

            // 判斷總頁數是否大於1，若是則顯示顯示全部的選項
            if (res.data.data.total > 3) {
              this.searchOneOnOne.showAll = true;
            } else {
              this.searchOneOnOne.showAll = false;
            }
          }
        })
        .catch((err) => {
          console.log(`放大鏡搜尋1對1的catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    weeklyClassCourseSearch(keyWord, page, displayedNum) {
      this.pendingStatus = this.pendingStatus + 1;
      let data = {
        page: 1, // 第幾頁
        displayedNum: 3, // 資料筆數
        type: 2, // 1 => 一對一視訊、 2 => 週課影片、 3 => 函授影片
        keyWord: keyWord,
      };
      if (page != undefined) {
        data.page = page;
      }
      if (displayedNum != undefined) {
        data.displayedNum = displayedNum;
      }
      this.searchWeeklyClassInfo = [];
      axios
        .post(`${serverUrl}learn_shop/list`, data, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          let displayCourseNum = 0; // 依照螢幕寬度決定顯示課程數量
          let screenWidth = window.innerWidth;
          if (res.data.data.total > 3) {
            if (screenWidth >= 1920) {
              displayCourseNum = 4;
            } else {
              displayCourseNum = 3;
            }
          } else {
            displayCourseNum = res.data.data.data.length;
          }
          if (res.status == 200) {
            const mainData = res.data.data.data;
            this.searchWeeklyClassInfo = this.formatWeeklyCourse(
              mainData.slice(0, displayCourseNum)
            );
            this.searchWeeklyClassQuantity = res.data.data.total;
            this.pendingStatus = this.pendingStatus - 1;

            // 判斷總頁數是否大於1，若是則顯示顯示全部的選項
            if (res.data.data.total > 3) {
              this.searchWeeklyClass.showAll = true;
            } else {
              this.searchWeeklyClass.showAll = false;
            }
          }
        })
        .catch((err) => {
          console.log(`週課搜尋catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    correspondenceEducationCourseSearch(keyWord, page, displayedNum) {
      // console.log('函授課程API發送')

      this.pendingStatus = this.pendingStatus + 1;
      let data = {
        page: 1, // 第幾頁
        displayedNum: 100, // 資料筆數
        type: 3, // 1 => 一對一視訊、 2 => 週課影片、 3 => 函授影片
        keyWord: keyWord,
      };
      if (page != undefined) {
        data.page = page;
      }
      if (displayedNum != undefined) {
        data.displayedNum = displayedNum;
      }
      this.searchCorrespondenceEducationInfo = [];
      axios
        .post(`${serverUrl}learn_shop/list`, data, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          let displayCourseNum = 0; // 依照螢幕寬度決定顯示課程數量
          let screenWidth = window.innerWidth;
          if (res.data.data.total > 3) {
            if (screenWidth >= 1920) {
              displayCourseNum = 4;
            } else {
              displayCourseNum = 3;
            }
          } else {
            displayCourseNum = res.data.data.data.length;
          }
          if (res.status == 200) {
            const mainData = res.data.data.data;
            this.searchCorrespondenceEducationInfo =
              this.formatCorrespondenceEducation(
                mainData.slice(0, displayCourseNum)
              );

            this.searchCorrespondenceEducationQuantity = res.data.data.total;
            this.pendingStatus = this.pendingStatus - 1;

            // 判斷總頁數是否大於1，若是則顯示顯示全部的選項
            if (res.data.data.total > 3) {
              this.searchCorrespondenceEducation.showAll = true;
            } else {
              this.searchCorrespondenceEducation.showAll = false;
            }
          }
        })
        .catch((err) => {
          console.log(`搜尋函授影片課程catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    oneOnOneSearchAdd() {
      this.searchOneOnOneChange = this.searchOneOnOneChange + 1;
      // console.log('放大鏡增加')
      // console.log(this.searchOneOnOneChange)
    },
    oneOnOneSearchReduce() {
      this.searchOneOnOneChange = this.searchOneOnOneChange - 1;
      // console.log('放大鏡減少')
      // console.log(this.searchOneOnOneChange)
    },

    weeklyClassSearchAdd() {
      this.searchWeeklyClassChange = this.searchWeeklyClassChange + 1;
      // console.log('週課放大鏡增加')
      // console.log(this.searchWeeklyClassChange)
    },
    weeklyClassSearchReduce() {
      this.searchWeeklyClassChange = this.searchWeeklyClassChange - 1;
      // console.log('週課放大鏡減少')
      // console.log(this.searchWeeklyClassChange)
    },

    correspondenceEducationSearchAdd() {
      this.searchCorrespondenceEducationChange =
        this.searchCorrespondenceEducationChange + 1;
      // console.log('函授放大鏡增加')
      // console.log(this.searchCorrespondenceEducationChange)
    },
    correspondenceEducationSearchReduce() {
      this.searchCorrespondenceEducationChange =
        this.searchCorrespondenceEducationChange - 1;
      // console.log('函授放大鏡減少')
      // console.log(this.searchCorrespondenceEducationChange)
    },
    // 搜尋後如果要查看全部，用這裡的function執行，差異在於變動的課程資料陣列
    oneOnOneCourseSearchAll(keyWord, page, displayedNum) {
      this.pendingStatus = this.pendingStatus + 1;
      let data = {
        page: 0, // 第幾頁
        displayedNum: 9999999, // 資料筆數
        type: 1, // 1 => 一對一視訊、 2 => 週課影片、 3 => 函授影片
        keyWord: keyWord,
      };
      if (page != undefined) {
        data.page = page;
      }
      if (displayedNum != undefined) {
        data.displayedNum = displayedNum;
      }
      this.searchResultAllInfo = [];
      axios
        .post(`${serverUrl}learn_shop/list`, data, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            for (let i = 0; i < res.data.data.data.length; i++) {
              let courseInfoBuffer = {
                courseFeature: "",
                courseDiscount: "",
                productName: "",
                productDescription: "",
                price: "",
                soldQuantity: "",
                favoriteStatus: "",
                shoppingCartStatus: "",
                id: "",
                index: "",
                type: 0,
                courseBought: false,
              };
              // 將API的資料轉換成需求格式
              courseInfoBuffer.index = i;

              // 課程特點
              courseInfoBuffer.courseFeature = "1對1視訊課程";

              // 折扣項目顯示
              if (res.data.data.data[i].discountText != undefined) {
                courseInfoBuffer.courseDiscount =
                  res.data.data.data[i].discountText;
              } else {
                courseInfoBuffer.courseDiscount = undefined;
              }
              // 課程標題
              if (res.data.data.data[i].title != undefined) {
                courseInfoBuffer.productName = res.data.data.data[i].title;
              }

              // 課程描述
              if (res.data.data.data[i].description != null) {
                courseInfoBuffer.productDescription =
                  res.data.data.data[i].description;
              } else {
                courseInfoBuffer.productDescription = "";
              }

              // 課程原價及售價
              // coinDiscount是用來判斷有無特價的基準，如果這筆資料有東西代表是課程有折扣
              if (res.data.data.data[i].coinDiscount == undefined) {
                // courseInfoBuffer.courseOriginalPrice是商品原價，會在畫面上槓掉
                courseInfoBuffer.courseOriginalPrice = undefined;
                // courseInfoBuffer.price是商品售價
                courseInfoBuffer.price = res.data.data.data[i].coin;
              } else {
                courseInfoBuffer.courseOriginalPrice =
                  res.data.data.data[i].coin;
                courseInfoBuffer.price = res.data.data.data[i].coinDiscount;
              }

              // 圖片來源
              courseInfoBuffer.picture = this.addDefaultCourseBanner(
                res.data.data.data[i].category,
                res.data.data.data[i].type,
                res.data.data.data[i].picture
              );

              // 已售出數量
              courseInfoBuffer.soldQuantity = res.data.data.data[i].sales;

              // 收藏狀態
              courseInfoBuffer.favoriteStatus = res.data.data.data[i].like;

              // 兌換清單狀態
              courseInfoBuffer.shoppingCartStatus =
                res.data.data.data[i].shoppingCart;

              // 是否購買
              courseInfoBuffer.courseBought = res.data.data.data[i].buy;

              // uid寫入
              courseInfoBuffer.id = res.data.data.data[i].id;

              courseInfoBuffer.type = res.data.data.data[i].type;

              // 購買狀態
              courseInfoBuffer.courseBought = res.data.data.data[i].buy;
              this.searchResultAllInfo.push(courseInfoBuffer);
            }
            // console.log(res.data.data)
            // console.log(this.pagination.searchResultAll.data)
            // console.log("放大鏡搜尋一對一查看全部");
            // console.log(this.searchResultAllInfo);
            this.pagination.searchResultAll.data = this.searchResultAllInfo;
            this.pagination.searchResultAll.currentPage =
              res.data.data.currentPage;
            this.pagination.searchResultAll.totalPage = res.data.data.totalPage;
            this.pendingStatus = this.pendingStatus - 1;

            // 判斷總頁數是否大於1，若是則顯示顯示全部的選項
            //   if (res.data.data.total > 3) {
            //   this.searchOneOnOne.showAll = true;
            // } else {
            //   this.searchOneOnOne.showAll = false;
            // }
          }
        })
        .catch((err) => {
          console.log(`放大鏡搜尋1對1的catch觸發 :${err}`);
          // this.isPending = false;
        });
    },

    weeklyClassCourseSearchAll(keyWord, page, displayedNum) {
      this.searchResultAllInfo = [];
      this.pendingStatus = this.pendingStatus + 1;
      let data = {
        page: 1, // 第幾頁
        displayedNum: 3, // 資料筆數
        type: 2, // 1 => 一對一視訊、 2 => 週課影片、 3 => 函授影片
        keyWord: keyWord,
      };
      if (page != undefined) {
        data.page = page;
      }
      if (displayedNum != undefined) {
        data.displayedNum = displayedNum;
      }
      // this.searchweeklyClassInfo = [];
      axios
        .post(`${serverUrl}learn_shop/list`, data, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            const mainData = res.data.data.data;
            this.searchResultAllInfo = this.formatWeeklyCourse(mainData);
            this.pagination.searchResultAll.data = this.searchResultAllInfo;
            this.pagination.searchResultAll.currentPage =
              res.data.data.currentPage;
            this.pagination.searchResultAll.totalPage = res.data.data.totalPage;
            this.searchWeeklyClassQuantity = res.data.data.total;
            this.pendingStatus = this.pendingStatus - 1;
          }
        })
        .catch((err) => {
          console.log(`週課搜尋catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    correspondenceEducationSearchAll(keyWord, page, displayedNum) {
      this.searchResultAllInfo = [];
      this.pendingStatus = this.pendingStatus + 1;
      let data = {
        page: 1, // 第幾頁
        displayedNum: 3, // 資料筆數
        type: 3, // 1 => 一對一視訊、 2 => 週課影片、 3 => 函授影片
        keyWord: keyWord,
      };
      if (page != undefined) {
        data.page = page;
      }
      if (displayedNum != undefined) {
        data.displayedNum = displayedNum;
      }
      // this.searchweeklyClassInfo = [];
      axios
        .post(`${serverUrl}learn_shop/list`, data, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            const mainData = res.data.data.data;
            this.searchResultAllInfo =
              this.formatCorrespondenceEducation(mainData);
            this.pagination.searchResultAll.data = this.searchResultAllInfo;
            this.pagination.searchResultAll.currentPage =
              res.data.data.currentPage;
            this.pagination.searchResultAll.totalPage = res.data.data.totalPage;
            this.pendingStatus = this.pendingStatus - 1;
          }
        })
        .catch((err) => {
          console.log(`週課搜尋catch觸發 :${err}`);
          // this.isPending = false;
        });
    },

    showSearchAllOneOnOne() {
      let courseSearchInput = document.getElementById("courseSearch");
      if (courseSearchInput.value == "") {
        return;
      } else {
        // console.log('現在顯示放大鏡搜尋一對一全部課程')
        // console.log(`views是 : ${this.views}`)
        // 參數的999999只是為了要搜尋全部的內容
        this.searchResultTitle = "1對1視訊課程";
        // this.viewsBuffer = this.views;
        this.views = this.VIEW.search_result_all;
        this.oneOnOneCourseSearchAll(
          courseSearchInput.value,
          this.pagination.searchResultAll.currentPage,
          this.apiData.searchDisplayedNum
        );
        this.searchResultAllStatus.oneOnOne = true;
        this.searchResultAllStatus.weeklyClass = false;
        this.searchResultAllStatus.correspondenceEducation = false;
        // 存放搜尋前是在哪一個頁面

        // searchResultAllInfo: [],
        // searchResultTitle: '',
        window.scrollTo(0, 0);
      }
    },
    showSearchAllWeeklyClass() {
      let courseSearchInput = document.getElementById("courseSearch");
      if (courseSearchInput.value == "") {
        return;
      } else {
        // console.log('現在顯示放大鏡搜尋週課影片全部課程')
        // console.log(`views是 : ${this.views}`)
        this.searchResultTitle = "週課影片";
        // this.viewsBuffer = this.views;
        this.views = this.VIEW.search_result_all;
        this.weeklyClassCourseSearchAll(
          courseSearchInput.value,
          this.pagination.searchResultAll.currentPage,
          this.apiData.searchDisplayedNum
        );
        this.searchResultAllStatus.oneOnOne = false;
        this.searchResultAllStatus.weeklyClass = true;
        this.searchResultAllStatus.correspondenceEducation = false;
        window.scrollTo(0, 0);
      }
    },
    showSearchAllCorrespondenceEducation() {
      let courseSearchInput = document.getElementById("courseSearch");
      if (courseSearchInput.value == "") {
        return;
      } else {
        // console.log('現在顯示放大鏡搜尋函授影片全部課程')
        // console.log(`views是 : ${this.views}`)
        this.searchResultTitle = "函授影片";
        // this.viewsBuffer = this.views;
        this.views = this.VIEW.search_result_all;
        this.correspondenceEducationSearchAll(
          courseSearchInput.value,
          this.pagination.searchResultAll.currentPage,
          this.apiData.searchDisplayedNum
        );
        this.searchResultAllStatus.oneOnOne = false;
        this.searchResultAllStatus.weeklyClass = false;
        this.searchResultAllStatus.correspondenceEducation = true;
        window.scrollTo(0, 0);
      }
    },
    showSearchAllBack() {
      this.oneOnOneCourseSearch(courseSearchInput.value, 1, 999999);
      this.weeklyClassCourseSearch(courseSearchInput.value, 1, 999999);
      this.correspondenceEducationCourseSearch(
        courseSearchInput.value,
        1,
        999999
      );
    },

    // 收藏 / 取消收藏課程
    // 取得收藏清單的資料在favoriteList.vue內執行，因為是彈出視窗，再彈出時在要資料就可以
    favoriteItemAdd(id) {
      axios
        .patch(
          `${serverUrl}learn_shop/like/${id}`,
          {
            add: true,
          },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // console.log(`${id} : 商品收藏成功`)
          }
        })
        .catch((error) => {
          console.error(`收藏/取消收藏catch錯誤： ${error}`);
        });
    },
    favoriteItemRemove(id) {
      axios
        .patch(
          `${serverUrl}learn_shop/like/${id}`,
          {
            add: false,
          },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // console.log(`${id} : 商品移除收藏`)
          }
        })
        .catch((error) => {
          console.error(`移除收藏錯誤： ${error}`);
        });
    },
    // 收藏清單內的卡片按下移除收藏
    favoriteRemoveFromFavoriteList(obj) {
      // 這邊直接傳入false觸發toast的原因是，在這個函式內一定是將收藏移除，所以不需要外部參數來判斷
      // 課程現在的狀態
      this.toast(false);
      // 搜尋一對一頁面
      if (this.courseCardInfo.length > 0) {
        for (let i = 0; i < this.courseCardInfo.length; i++) {
          if (this.courseCardInfo[i].id == obj.id) {
            this.courseCardInfo[i].favoriteStatus = false;
            // console.log('一對一頁面移除收藏')
            // console.log(this.courseCardInfo)
          }
        }
      }
      // 搜尋為你推薦頁面
      if (this.courseRecommendCardInfo.length > 0) {
        for (let i = 0; i < this.courseRecommendCardInfo.length; i++) {
          if (this.courseRecommendCardInfo[i].id == obj.id) {
            this.courseRecommendCardInfo[i].favoriteStatus = false;
          }
        }
      }
      // 搜尋週課影片頁面
      if (this.weeklyCorrespondenceCardInfo.length > 0) {
        for (let i = 0; i < this.weeklyCorrespondenceCardInfo.length; i++) {
          if (this.weeklyCorrespondenceCardInfo[i].id == obj.id) {
            this.weeklyCorrespondenceCardInfo[i].favoriteStatus = false;
            // console.log('週課影片移除收藏')
          }
        }
      }
      // 搜尋函授課程影片介面
      if (this.correspondenceEducationCardInfo.length > 0) {
        for (let i = 0; i < this.correspondenceEducationCardInfo.length; i++) {
          if (this.correspondenceEducationCardInfo[i].id == obj.id) {
            this.correspondenceEducationCardInfo[i].favoriteStatus = false;
            // console.log('函授課程移除收藏')
          }
        }
      }
      // 搜尋放大鏡搜尋一對一視訊頁面
      if (this.searchOneOnOneClassInfo.length > 0) {
        for (let i = 0; i < this.searchOneOnOneClassInfo.length; i++) {
          if (this.searchOneOnOneClassInfo[i].id == obj.id) {
            this.searchOneOnOneClassInfo[i].favoriteStatus = false;
            // console.log('放大鏡一對一視訊移除收藏')
          }
        }
      }
      // 搜尋放大鏡搜尋週課影片視訊頁面
      if (this.searchWeeklyClassInfo.length > 0) {
        for (let i = 0; i < this.searchWeeklyClassInfo.length; i++) {
          if (this.searchWeeklyClassInfo[i].id == obj.id) {
            this.searchWeeklyClassInfo[i].favoriteStatus = false;
            // console.log('放大鏡週課影片移除收藏')
          }
        }
      }
      // 搜尋放大鏡搜尋函授課程影片頁面
      if (this.searchCorrespondenceEducationInfo.length > 0) {
        for (
          let i = 0;
          i < this.searchCorrespondenceEducationInfo.length;
          i++
        ) {
          if (this.searchCorrespondenceEducationInfo[i].id == obj.id) {
            this.searchCorrespondenceEducationInfo[i].favoriteStatus = false;
            // console.log('放大鏡顯示函授課程移除收藏')
          }
        }
      }
      // 搜尋放大鏡搜尋顯示全部頁面
      if (this.searchResultAllInfo.length > 0) {
        for (let i = 0; i < this.searchResultAllInfo.length; i++) {
          if (this.searchResultAllInfo[i].id == obj.id) {
            this.searchResultAllInfo[i].favoriteStatus = false;
            // console.log('放大鏡顯示全部頁面移除收藏')
          }
        }
      }
    },

    // 取得兌換清單資料
    exchangeListGet() {
      this.pendingStatus = this.pendingStatus + 1;
      axios
        .get(`${serverUrl}learn_shop/cart`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          this.exchangeList = [];
          if (res.status == 200) {
            for (let i = 0; i < res.data.data.length; i++) {
              let courseInfoBuffer = {
                productName: "",
                index: 0,
                type: 0, // type是用來在內部判斷小圖示要顯示成哪一個
                id: "",
              };
              // 將API的資料轉換成需求格式

              // 索引標籤綁定
              courseInfoBuffer.index = i;

              // 商品名稱
              courseInfoBuffer.productName = res.data.data[i].title;

              // 商品類別
              if (res.data.data[i].type != undefined) {
                courseInfoBuffer.type = res.data.data[i].type;
              } else {
                courseInfoBuffer.type = 1;
              }

              // uid寫入
              courseInfoBuffer.id = res.data.data[i].id;

              this.exchangeList.push(courseInfoBuffer);
            }
            this.pendingStatus = this.pendingStatus - 1;
            // console.log(res.data.data);
            this.exchangeListItemsQuantity = this.exchangeList.length;
          }
        })
        .catch((err) => {
          console.log(`取得兌換清單catch觸發 :${err}`);
          // this.isPending = false;
        });
    },

    // 加入兌換清單
    // 新增一筆商品成功後就會重新打一次取得兌換清單資料的API，讓內容可以進行更新
    exchangeItemAdd(id) {
      // console.log('加入兌換清單');
      axios
        .patch(
          `${serverUrl}learn_shop/cart/${id}`,
          {
            add: true,
          },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // console.log(`${id} : 商品加入兌換清單成功`);
          }
        })
        .catch((error) => {
          console.error(`加入兌換清單catch錯誤： ${error}`);
        });
    },

    // 週課影片授課講師清單取得
    weeklyCorrespondenceLecturerListGet() {
      this.pendingStatus = this.pendingStatus + 1;
      axios
        .get(`${serverUrl}teainfo`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          this.allLecturerList = [];
          this.allowLecturerList = [];
          if (res.status == 200) {
            for (let i = 0; i < res.data.data.teachersInfo.length; i++) {
              let buffer = {
                id: -1,
                name: "",
                subject: [],
                avatar: "",
              };

              // 老師對應的ID寫入
              buffer.id = res.data.data.teachersInfo[i].id;

              // 老師的姓名寫入，如果有外文名字就是外文，沒有才是中文
              buffer.name = res.data.data.teachersInfo[i].foreignName;

              // 老師教授的語言
              buffer.subject = res.data.data.teachersInfo[i].subject;
              buffer.avatar = res.data.data.teachersInfo[i].avatar;
              this.allLecturerList.push(buffer);
              this.allowLecturerList.push(buffer);
            }
          }
          this.pendingStatus = this.pendingStatus - 1;
        })

        .catch((err) => {
          console.log(`週課影片授課講師清單取得catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    weeklyCorrespondenceFilterLecturerClick(lecturerInfo) {
      this.weeklyCorrespondenceLecturerFilter = [lecturerInfo.id];
      this.weeklyCorrespondenceLecturerNameFilter = [lecturerInfo.name];
      // 過濾出老師可以上的 subject
      this.allowSubjects = this.filterLecturerSubject(lecturerInfo.subject);
    },
    filterLecturerSubject(subjects) {
      return this.allSubjects
        .map((categoryInfo) => {
          return {
            ...categoryInfo,
            subjects: categoryInfo.subjects.filter((subjectInfo) => {
              return subjects.includes(subjectInfo.subject);
            }),
          };
        })
        .filter((categoryInfo) => {
          // 只留下有 subject 的 category
          return categoryInfo.subjects.length > 0;
        });
    },
    clearWeeklyCorrespondenceFilterLecturer() {
      this.weeklyCorrespondenceLecturerFilter = [];
      this.weeklyCorrespondenceLecturerNameFilter = [];

      // 恢復篩選的 subject
      this.allowSubjects = this.allSubjects;
    },
    // 函授課程授課講師清單取得

    // ↑ axios

    // 彈出視窗呼叫
    openCourseDetailModal(obj) {
      // console.log(`課程卡片ID${obj.id}`)
      this.$refs.courseDetails.courseInfoGet(obj.id);
      this.courseDetailsModal.show();
    },
    openFavoriteListModal() {
      this.favoriteListModal.show();
      this.favoriteListCourseApiCall();
    },
    openoneOnOneFilterPageModal() {
      this.oneOnOneFilterPageModal.show();
    },
    openClassFilterMobilePageModal() {
      this.weeklyCorrespondenceFilterModal.show();
    },

    // 課程選擇按鈕按下後，修改外觀以及切換畫面↓
    courseSelectOneOnOne() {
      // 清除1對1影片過濾器
      this.oneOnOneFilter = [];
      this.oneOnOneFilterPoint = [];
      this.$refs.oneOnoOneFilterPage.cleanSearch();

      this.clearWeeklyCorrespondenceFilter();
      this.oneOnOneCourseGet();
      this.viewsBuffer = "";
      this.bannerImageChange(this.VIEW.one_on_one);

      this.clearWeeklyCorrespondenceFilter();
      this.views = this.VIEW.one_on_one;
    },
    courseSelectWeeklyCorrespondenceClass(classType) {
      this.clearWeeklyCorrespondenceFilter();
      this.$refs.classFilterMobilePage.cleanSearch();
      this.weeklyCorrespondenceCourseGet(classType);
      this.weeklyCorrespondenceLecturerListGet();
      this.bannerImageChange(classType);

      this.viewsBuffer = "";
      this.views = classType;
    },

    // 課程選擇按鈕按下後，修改外觀以及切換畫面↑

    // toast
    showToast() {
      document.getElementById("exchange-alert").style.visibility = "visible";
      document.getElementById("exchange-alert").style.opacity = "1";
      // 這邊setTimeout會有在背景疊加的問題，假設快速點收藏按鈕，會累積一堆關閉toast的執行，會導致
      // 視覺上有時候看到toast會很快消失，之後再進行修改
      setTimeout(() => {
        this.hideToast();
      }, 3000);
    },
    hideToast() {
      document.getElementById("exchange-alert").style.visibility = "hidden";
      document.getElementById("exchange-alert").style.opacity = "0";
    },
    toast(status) {
      // status是從子階容器課程卡片內得到的收藏狀態
      if (status == "addExchangeList") {
        document.getElementById("exchange-alert-text").textContent =
          "已加入兌換清單";
      }
      if (status == true) {
        document.getElementById("exchange-alert-text").textContent =
          "已加入收藏";
      } else if (status == false) {
        document.getElementById("exchange-alert-text").textContent =
          "已移除收藏";
      }
      // console.log(status);
      this.showToast();
    },

    // 課程數量計算
    courseQuantityCalculate(data) {
      return data.length;
    },
    // 課程分類代碼轉換，用在週課及函授影片
    courseTypedecode(data) {
      return this.setLangCh2(data);
    },
    courseTypeEncode(data) {
      return this.setLangCh3(data);
    },
    // banner主視覺圖片切換
    bannerImageChange(nowAt) {
      let screenWidth = document.body.clientWidth;
      let actScreenWidth = 0;

      if (screenWidth >= 1920) {
        actScreenWidth = 1920;
      } else if ((screenWidth < 1920) & (screenWidth >= 1440)) {
        actScreenWidth = 1366;
      } else if ((screenWidth < 1440) & (screenWidth >= 1024)) {
        actScreenWidth = 1024;
      } else if ((screenWidth < 1024) & (screenWidth >= 768)) {
        actScreenWidth = 768;
      } else if ((screenWidth < 768) & (screenWidth >= 415)) {
        actScreenWidth = 415;
      } else if (screenWidth < 415) {
        actScreenWidth = 320;
      }

      // 修改課程主視覺banner
      let banner = document.getElementById("banner-block");
      let url = "";
      if (nowAt === this.VIEW.one_on_one) {
        if (actScreenWidth == 1920) {
          url = oneOnOneBanner1920;
        } else if (actScreenWidth == 1366) {
          url = oneOnOneBanner1366;
        } else if (actScreenWidth == 1024) {
          url = oneOnOneBanner1024;
        } else if (actScreenWidth == 768) {
          url = oneOnOneBanner768;
        } else if (actScreenWidth == 415) {
          url = oneOnOneBanner415;
        } else if (actScreenWidth == 320) {
          url = oneOnOneBanner320;
        }
      } else if (nowAt === this.VIEW.weekly_class) {
        if (actScreenWidth == 1920) {
          url = weeklyClassBanner1920;
        } else if (actScreenWidth == 1366) {
          url = weeklyClassBanner1366;
        } else if (actScreenWidth == 1024) {
          url = weeklyClassBanner1024;
        } else if (actScreenWidth == 768) {
          url = weeklyClassBanner768;
        } else if (actScreenWidth == 415) {
          url = weeklyClassBanner415;
        } else if (actScreenWidth == 320) {
          url = weeklyClassBanner320;
        }
      } else if (nowAt === this.VIEW.correspondence_education) {
        if (actScreenWidth == 1920) {
          url = correspondenceEducationBanner1920;
        } else if (actScreenWidth == 1366) {
          url = correspondenceEducationBanner1366;
        } else if (actScreenWidth == 1024) {
          url = correspondenceEducationBanner1024;
        } else if (actScreenWidth == 768) {
          url = correspondenceEducationBanner768;
        } else if (actScreenWidth == 415) {
          url = correspondenceEducationBanner415;
        } else if (actScreenWidth == 320) {
          url = correspondenceEducationBanner320;
        }
      }

      banner.style.backgroundImage = `url(${url})`;
    },
    // banner的搜尋欄功能
    // 按下搜尋列的放大鏡圖示進行搜尋
    courseSearchIconClick() {
      let courseSearchInput = document.getElementById("courseSearch");
      if (courseSearchInput.value == "") {
        return;
      } else {
        // 參數的999999只是為了要搜尋全部的內容
        this.oneOnOneCourseSearch(courseSearchInput.value, 1, 999999);
        this.weeklyClassCourseSearch(courseSearchInput.value, 1, 999999);
        this.correspondenceEducationCourseSearch(
          courseSearchInput.value,
          1,
          999999
        );
        // 存放搜尋前是在哪一個頁面
        this.viewsBuffer = this.views;
        this.views = "search-result";
      }
    },
    // 按下搜尋列的X圖示進行輸入內容清除
    courseSearchCleanIconClick() {
      let courseSearchInput = document.getElementById("courseSearch");
      courseSearchInput.value = "";
    },
    // 在放大鏡搜尋頁面按下清除搜尋後的頁面回復
    courseSearchClean() {
      // console.log("放大鏡搜尋頁面按下清除搜尋");
      // console.log(`views現在是 : ${this.views}`);
      this.views = this.viewsBuffer;
      // console.log(`views修改後是 : ${this.views}`);
      // 改變按鈕外觀回到1對1視訊課程
      if (
        (this.viewsBuffer == this.VIEW.one_on_one) |
        (this.viewsBuffer == this.VIEW.search_result_all)
      ) {
        this.courseSelectOneOnOne();
      } else {
        this.courseSelectWeeklyCorrespondenceClass(viewsBuffer);
      }

      window.scrollTo(0, 0);
      this.searchResultAllInfo = [];
      this.viewsBuffer = "";
    },

    addToExchangeList(obj) {
      this.toast("addExchangeList");
      // obj會接收到由課程卡片往外傳的課程分類名稱以及索引編號兩個參數，用來判斷要選取哪一筆課程資料
      // console.log("接收到按下加入兌換清單");
      // console.log(obj);
      if (obj) {
        this.exchangeItemAdd(obj.id);
        // obj.item = courseCardInfo type = 1
        // obj.item = weeklyCorrespondenceCardInfo type = 2
        // obj.item = correspondenceEducationCardInfo type = 3

        // 在這邊將課程資料push進exchangeList讓內容可以先更新，取代目前單靠API加入後重新取得的做法

        // 以下判斷是要將哪裡的課程加入
        let buffer = {
          id: "",
          index: 0,
          type: 0,
          productName: "",
        };

        buffer.id = obj.id;
        buffer.index = obj.index;
        buffer.productName = obj.productName;

        if (obj.type) {
          buffer.type = obj.type;
        } else {
          if (
            (obj.item == "courseCardInfo") |
            (obj.item == "searchOneOnOneClassInfo")
          ) {
            buffer.type = 1;
          } else if (
            (obj.item == "weeklyCorrespondenceCardInfo") |
            (obj.item == "searchWeeklyClassInfo")
          ) {
            buffer.type = 2;
          } else if (
            (obj.item == "correspondenceEducationCardInfo") |
            (obj.item == "searchCorrespondenceEducationInfo")
          ) {
            buffer.type = 3;
          } else {
            buffer.type = 4;
          }
        }

        // console.log(buffer);
        this.exchangeList.push(buffer);
        this.exchangeListItemsQuantity = this.exchangeList.length;
      }
    },
    removeFromExchangeList() {
      // console.log('a');
    },

    favoriteListCourseApiCall() {
      this.$refs.shopPickFavoriteList.favoriteListGet();
      // console.log('商店頁面觸發收藏清單API');
    },
    searshResultCheckMobile() {
      this.showSearchResultMobile = !this.showSearchResultMobile;
      let target = document.getElementById("search-result-content-mobile");
      let container = document.getElementById("search-result-container-mobile");
      if (this.showSearchResultMobile == true) {
        target.style.display = "block";
        container.style.backgroundColor = "#CCCCCC";
      } else {
        target.style.display = "none";

        container.style.backgroundColor = "#F6F6F6";
      }
    },

    // 按下收藏按鈕將課程加入到收藏清單
    favoriteItemsUpdate(obj) {
      // console.log(`學習商店接收到在卡片內按下收藏按鈕${obj}`);
      // console.log(obj.item);
      // console.log(obj.id);
      // console.log(obj.status);
      // obj是從收藏清單內將資料傳遞出來的接收暫存器
      // obj.id是指定商品的id
      // obj.status是收藏符號的狀態
      if (obj.status == true) {
        this.favoriteItemAdd(obj.id);
      } else if (obj.status == false) {
        this.favoriteItemRemove(obj.id);
      }
    },

    removeArrayItem(arr, item) {
      let index = arr.indexOf(item);
      if (index > -1) {
        return arr.splice(index, 1);
      }
    },

    closeCourseDetailsModal() {
      this.courseDetailsModal.hide();
      this.$router.push("online-shop?category=exchangeList");
    },

    // 收藏清單內按下加入兌換更新兌換清單的資料
    updateExchangeListFromFavoriteList(obj) {
      this.toast("addExchangeList");
      if (obj) {
        this.exchangeList.push(obj);
        this.exchangeListItemsQuantity = this.exchangeList.length;
      }

      // console.log('在學習商店接收到收藏清單內按下商品加入兌換');
      // console.log(obj);
    },

    // 課程詳細視窗內加入/取消收藏狀態連帶改變
    favoriteItemAddRemoveFromCourseDetails(obj) {
      // console.log('商品頁面接收到詳細課程內收藏狀態更新')
      // console.log(obj)
      this.toast(!obj.favoriteStatus);

      // 搜尋一對一頁面
      if (this.courseCardInfo.length > 0) {
        for (let i = 0; i < this.courseCardInfo.length; i++) {
          if (this.courseCardInfo[i].id == obj.id) {
            this.courseCardInfo[i].favoriteStatus = !obj.favoriteStatus;
            // console.log('一對一頁面移除收藏')
            // console.log(this.courseCardInfo)
          }
        }
      }
      // 搜尋為你推薦頁面
      if (this.courseRecommendCardInfo.length > 0) {
        for (let i = 0; i < this.courseRecommendCardInfo.length; i++) {
          if (this.courseRecommendCardInfo[i].id == obj.id) {
            this.courseRecommendCardInfo[i].favoriteStatus =
              !obj.favoriteStatus;
          }
        }
      }
      // 搜尋週課影片頁面
      if (this.weeklyCorrespondenceCardInfo.length > 0) {
        for (let i = 0; i < this.weeklyCorrespondenceCardInfo.length; i++) {
          if (this.weeklyCorrespondenceCardInfo[i].id == obj.id) {
            this.weeklyCorrespondenceCardInfo[i].favoriteStatus =
              !obj.favoriteStatus;
            // console.log('週課影片移除收藏')
          }
        }
      }
      // 搜尋函授課程影片介面
      if (this.correspondenceEducationCardInfo.length > 0) {
        for (let i = 0; i < this.correspondenceEducationCardInfo.length; i++) {
          if (this.correspondenceEducationCardInfo[i].id == obj.id) {
            this.correspondenceEducationCardInfo[i].favoriteStatus =
              !obj.favoriteStatus;
            // console.log('函授課程移除收藏')
          }
        }
      }
      // 搜尋放大鏡搜尋一對一視訊頁面
      if (this.searchOneOnOneClassInfo.length > 0) {
        for (let i = 0; i < this.searchOneOnOneClassInfo.length; i++) {
          if (this.searchOneOnOneClassInfo[i].id == obj.id) {
            this.searchOneOnOneClassInfo[i].favoriteStatus =
              !obj.favoriteStatus;
            // console.log('放大鏡一對一視訊移除收藏')
          }
        }
      }
      // 搜尋放大鏡搜尋週課影片視訊頁面
      if (this.searchWeeklyClassInfo.length > 0) {
        for (let i = 0; i < this.searchWeeklyClassInfo.length; i++) {
          if (this.searchWeeklyClassInfo[i].id == obj.id) {
            this.searchWeeklyClassInfo[i].favoriteStatus = !obj.favoriteStatus;
            // console.log('放大鏡週課影片移除收藏')
          }
        }
      }
      // 搜尋放大鏡搜尋函授課程影片頁面
      if (this.searchCorrespondenceEducationInfo.length > 0) {
        for (
          let i = 0;
          i < this.searchCorrespondenceEducationInfo.length;
          i++
        ) {
          if (this.searchCorrespondenceEducationInfo[i].id == obj.id) {
            this.searchCorrespondenceEducationInfo[i].favoriteStatus =
              !obj.favoriteStatus;
            // console.log('放大鏡顯示函授課程移除收藏')
          }
        }
      }
      // 搜尋放大鏡搜尋顯示全部頁面
      if (this.searchResultAllInfo.length > 0) {
        for (let i = 0; i < this.searchResultAllInfo.length; i++) {
          if (this.searchResultAllInfo[i].id == obj.id) {
            this.searchResultAllInfo[i].favoriteStatus = !obj.favoriteStatus;
            // console.log('放大鏡顯示全部頁面移除收藏')
          }
        }
      }
    },
    // 課程詳細視窗加入兌換清單
    exchangeItemAddCourseDetails(obj) {
      this.toast("addExchangeList");
      // console.log('學習商店收到課程詳細視窗加入兌換清單請求')
      // console.log(obj)
      if (obj) {
        this.exchangeList.push({
          type: obj.type,
          productName: obj.productName,
        });
        this.exchangeListItemsQuantity = this.exchangeList.length;
      }
      // console.log('學習商店接收到課程詳細視窗加入兌換清單請求')
      // console.log(obj)
    },

    // 1對1視訊手機板過濾器搜尋
    oneOnOneMobileFilter(obj) {
      // console.log('學習商店街收到1對1手機板開始搜尋')
      // console.log(obj)
      this.oneOnOneFilterPoint = obj.timeClass;
      this.oneOnOneFilter = obj.language;

      this.oneOnOneCourseGet();
      this.oneOnOneFilterPageModal.hide();
    },
    // 週課手機板過濾器搜尋 language, level, teacher, date
    weeklyClassMobileFilter(obj) {
      this.weeklyCorrespondenceCourseGet(
        this.views,
        obj.language,
        obj.level,
        obj.lecturer,
        obj.addedTime
      );
      this.weeklyCorrespondenceSubjectFilter = obj.language;
      this.weeklyCorrespondenceLevelFilter = obj.level;
      this.weeklyCorrespondenceLecturerFilter = obj.lecturer;
      this.weeklyCorrespondenceDateFilter = obj.addedTime;
      if (
        (obj.language.length > 0) |
        (obj.level.length > 0) |
        (obj.lecturer.length > 0) |
        (obj.addedTime.length > 0)
      ) {
        this.weeklyCorrespondenceMobileFilterEnable = true;
      }
      if (
        (obj.language.length == 0) &
        (obj.level.length == 0) &
        (obj.lecturer.length == 0) &
        (obj.addedTime.length == 0)
      ) {
        this.weeklyCorrespondenceMobileFilterEnable = false;
      }
      this.weeklyCorrespondenceFilterModal.hide();
    },

    // 分頁切換
    changePage(page) {
      // this.pagination.weeklyCorrespondence.currentPage = page;
      // console.log(page);
      window.scrollTo(0, 0);
      if (this.views == this.VIEW.one_on_one) {
        this.pagination.oneOnOne.currentPage = page;
        this.oneOnOneCourseGet(this.pagination.oneOnOne.currentPage);
      }
      if (
        this.views == this.VIEW.weekly_class ||
        this.views === this.VIEW.correspondence_education
      ) {
        this.pagination.weeklyCorrespondence.currentPage = page;
        if (
          this.weeklyCorrespondenceClassFilterBtn |
          this.weeklyCorrespondenceMobileFilterEnable
        ) {
          this.weeklyCorrespondenceCourseFilterGet(
            this.views,
            this.weeklyCorrespondenceSubjectFilter,
            this.weeklyCorrespondenceLevelFilter,
            this.weeklyCorrespondenceLecturerFilter,
            this.weeklyCorrespondenceDateFilter,
            this.pagination.weeklyCorrespondence.currentPage
          );
        } else {
          this.weeklyCorrespondenceCourseGet(
            this.views,
            [],
            [],
            [],
            [],
            this.pagination.weeklyCorrespondence.currentPage
          );
        }
      }
      if (this.views == this.VIEW.search_result_all) {
        this.pagination.searchResultAll.currentPage = page;
        if (this.searchResultAllStatus.oneOnOne) {
          // console.log('重新觸發1對1')
          this.showSearchAllOneOnOne();
        }
        if (this.searchResultAllStatus.weeklyClass) {
          // console.log('重新觸發週課影片')
          this.showSearchAllWeeklyClass();
        }
        if (this.searchResultAllStatus.correspondenceEducation) {
          // console.log('重新觸發函授影片')
          this.showSearchAllCorrespondenceEducation();
        }
      }
    },

    // 課程詳細視窗內前往課程查詢頁面，進行url切換以及關閉彈出視窗
    goToClassSchedule() {
      this.courseDetailsModal.hide();
      this.$router.push("/course-arrangement?category=1");
    },
  },
  watch: {
    // 監視日期選擇，電腦版若有變化要直接觸發過濾器，重新發送API
    dateWeeklyCorrespondence: function () {
      if (this.dateWeeklyCorrespondence != null) {
        // 起始日期
        let startYear = this.dateWeeklyCorrespondence.start
          .getFullYear()
          .toString();
        let startMonth = (
          this.dateWeeklyCorrespondence.start.getMonth() + 1
        ).toString();
        if (startMonth.length == 1) {
          startMonth = "0" + startMonth;
        }
        let startDate = this.dateWeeklyCorrespondence.start
          .getDate()
          .toString();
        if (startDate.length == 1) {
          startDate = "0" + startDate;
        }

        // 結束日期
        let endYear = this.dateWeeklyCorrespondence.end
          .getFullYear()
          .toString();
        let endMonth = (
          this.dateWeeklyCorrespondence.end.getMonth() + 1
        ).toString();
        if (endMonth.length == 1) {
          endMonth = "0" + endMonth;
        }
        let endDate = this.dateWeeklyCorrespondence.end.getDate().toString();
        if (endDate.length == 1) {
          endDate = "0" + endDate;
        }

        let start = `${startYear}-${startMonth}-${startDate}`;
        let end = `${endYear}-${endMonth}-${endDate}`;
        this.weeklyCorrespondenceDateFilter = [start, end];
        this.weeklyCorrespondenceDatePickerShow = false;
      } else {
        this.weeklyCorrespondenceDateFilter = [];
      }
    },
    dateWeeklyCorrespondenceMobile: function () {
      // 起始日期
      let startYear = this.dateWeeklyCorrespondenceMobile.start
        .getFullYear()
        .toString();
      let startMonth = (
        this.dateWeeklyCorrespondenceMobile.start.getMonth() + 1
      ).toString();
      if (startMonth.length == 1) {
        startMonth = "0" + startMonth;
      }
      let startDate = this.dateWeeklyCorrespondenceMobile.start
        .getDate()
        .toString();
      if (startDate.length == 1) {
        startDate = "0" + startDate;
      }

      // 結束日期
      let endYear = this.dateWeeklyCorrespondenceMobile.end
        .getFullYear()
        .toString();
      let endMonth = (
        this.dateWeeklyCorrespondenceMobile.end.getMonth() + 1
      ).toString();
      if (endMonth.length == 1) {
        endMonth = "0" + endMonth;
      }
      let endDate = this.dateWeeklyCorrespondenceMobile.end
        .getDate()
        .toString();
      if (endDate.length == 1) {
        endDate = "0" + endDate;
      }
    },

    // 電腦版週課影片過濾器資料監視，如果有變化就重新打一次API
    weeklyCorrespondenceSubjectFilter: function () {
      if (this.weeklyCorrespondenceGetAll == false) {
        this.weeklyCorrespondenceCourseFilterGet(
          this.views,
          this.weeklyCorrespondenceSubjectFilter,
          this.weeklyCorrespondenceLevelFilter,
          this.weeklyCorrespondenceLecturerFilter,
          this.weeklyCorrespondenceDateFilter,
          1
        );
      }
    },
    weeklyCorrespondenceLevelFilter: function () {
      if (this.weeklyCorrespondenceGetAll == false) {
        this.weeklyCorrespondenceCourseFilterGet(
          this.views,
          this.weeklyCorrespondenceSubjectFilter,
          this.weeklyCorrespondenceLevelFilter,
          this.weeklyCorrespondenceLecturerFilter,
          this.weeklyCorrespondenceDateFilter,
          1
        );
      }
    },
    weeklyCorrespondenceLecturerFilter: function () {
      if (this.weeklyCorrespondenceGetAll == false) {
        this.weeklyCorrespondenceCourseFilterGet(
          this.views,
          this.weeklyCorrespondenceSubjectFilter,
          this.weeklyCorrespondenceLevelFilter,
          this.weeklyCorrespondenceLecturerFilter,
          this.weeklyCorrespondenceDateFilter,
          1
        );
      }
    },
    weeklyCorrespondenceDateFilter: function () {
      if (this.weeklyCorrespondenceGetAll == false) {
        this.weeklyCorrespondenceCourseFilterGet(
          this.views,
          this.weeklyCorrespondenceSubjectFilter,
          this.weeklyCorrespondenceLevelFilter,
          this.weeklyCorrespondenceLecturerFilter,
          this.weeklyCorrespondenceDateFilter,
          1
        );
      }
    },

    // 載入狀態監視中
    pendingStatus: function () {
      // console.log(`轉圈狀態是${this.pendingStatus}`)
      // console.log(this.apiData.isPending)

      if (this.pendingStatus > 0) {
        this.apiData.isPending = true;
      }

      if (this.pendingStatus == 0) {
        // console.log('狀態=0')
        // setTimeout(() => (this.apiData.isPending = false), 100);
        this.apiData.isPending = false;
      }
    },
  },
  mounted() {
    // console.log("mounted");
    this.getNewSubject().then((categories) => {
      // todo 需要更改產品類型的過濾
      // console.log(categories);
      let filterWeeklySubject = categories.map((category) => {
        return {
          ...category,
          // 篩選出周課會有的 subject
          subjects: category.subjects.filter((subject) =>
            subject.include_service.includes("B")
          ),
        };
      });
      // console.log(filterWeeklySubject);
      this.allSubjects = filterWeeklySubject;
      this.allowSubjects = filterWeeklySubject;
    });

    // 主視覺banner載入
    this.bannerImageChange(this.VIEW.one_on_one);
    // 偵測螢幕寬度，並且用來切換單頁顯示課程的最大數量
    // let screenWidth = document.body.clientWidth;
    // document.body.clientWidth是不包含瀏覽器右邊滾動條的寬度，會造成判斷誤差，因此改用window.innerWidth
    let screenWidth = window.innerWidth;
    if (screenWidth >= 1920) {
      this.apiData.displayedNum = 16;
      this.apiData.searchDisplayedNum = 24;
    }
    if ((screenWidth < 1920) & (screenWidth >= 768)) {
      this.apiData.displayedNum = 15;
      this.apiData.searchDisplayedNum = 24;
    }
    if (screenWidth < 768) {
      this.mobileType = true;
      this.apiData.displayedNum = 6;
      this.apiData.searchDisplayedNum = 12;
    } else {
      this.mobileType = false;
    }

    // 新增彈出視窗Modal ，這樣在js內才可以進行控制
    // 課程詳細資料 modal 已禁用
    // this.courseDetailsModal = new Modal(
    //   document.getElementById("course-details-modal")
    // );
    this.favoriteListModal = new Modal(
      document.getElementById("favorite-list-modal")
    );

    this.oneOnOneFilterPageModal = new Modal(
      document.getElementById("one-on-one-filter-page-modal")
    );
    this.weeklyCorrespondenceFilterModal = new Modal(
      document.getElementById("weekly-class-filter-page-modal")
    );

    // 偵測裝置按下瀏覽器的返回按鍵，用來關閉彈出視窗，否則會發生背景被禁用的效果一值存在
    window.addEventListener(
      "popstate",
      function (e) {
        closeAllModal();
      },
      false
    );

    // 這裡必須用箭頭函式，否則this會讀不到
    let closeAllModal = () => {
      this.courseDetailsModal.hide();
      this.favoriteListModal.hide();
      this.oneOnOneFilterPageModal.hide();
      this.weeklyCorrespondenceFilterModal.hide();
    };

    // C幣數量取得
    this.userCoinGet();
    // 課程資料取得，目前的目的地是本地.json
    // this.courseGet();
    this.exchangeListGet();

    // 課程資料取得
    // 1對1視訊課程
    this.oneOnOneCourseGet();
    // 為你推薦
    this.recommendCourseGet();

    // 1對1視訊課程過濾器監控，電腦版
    // 先取過濾器input回來
    let oneOnOneFilterAllInput = document.getElementById("oneOnOneFilterAll");
    let oneOnOneFilterEnJpInput = document.getElementById("oneOnOneFilterEnJp");
    let oneOnOneFilterSpecialInput = document.getElementById(
      "oneOnOneFilterSpecial"
    );
    let oneOnOneFilterIeltsInput = document.getElementById(
      "oneOnOneFilterIelts"
    );

    let oneOnOneFilterSkillInput = document.getElementById(
      "oneOnOneFilterSkill"
    );

    // 過濾器全部打勾或取消時，將另外三個選項的狀態改為跟全部的狀態一樣，同時改變oneOnOneFilter的內容，讓其在watch內觸發API
    oneOnOneFilterAllInput.addEventListener("change", (event) => {
      const allInputChecked = event.target.checked;
      if (allInputChecked) {
        this.oneOnOneFilter = ["14", "15", "16", "19"];
      } else {
        this.oneOnOneFilter = [];
      }
      oneOnOneFilterEnJpInput.checked = allInputChecked;
      oneOnOneFilterSpecialInput.checked = allInputChecked;
      oneOnOneFilterIeltsInput.checked = allInputChecked;
      oneOnOneFilterSkillInput.checked = allInputChecked;
      this.oneOnOneCourseGet();
    });
    // 英日選項
    oneOnOneFilterEnJpInput.addEventListener("change", (event) => {
      if (oneOnOneFilterEnJpInput.checked) {
        this.oneOnOneFilter.push("14");
      } else {
        this.removeArrayItem(this.oneOnOneFilter, "14");
        oneOnOneFilterAllInput.checked = false;
      }
      this.oneOnOneCourseGet();
    });
    // 特殊語言選項
    oneOnOneFilterSpecialInput.addEventListener("change", (event) => {
      if (oneOnOneFilterSpecialInput.checked) {
        this.oneOnOneFilter.push("15");
      } else {
        this.removeArrayItem(this.oneOnOneFilter, "15");
        oneOnOneFilterAllInput.checked = false;
      }
      this.oneOnOneCourseGet();
    });
    // 雅思選項
    oneOnOneFilterIeltsInput.addEventListener("change", (event) => {
      if (oneOnOneFilterIeltsInput.checked) {
        this.oneOnOneFilter.push("16");
      } else {
        this.removeArrayItem(this.oneOnOneFilter, "16");
        oneOnOneFilterAllInput.checked = false;
      }
      this.oneOnOneCourseGet();
    });

    oneOnOneFilterSkillInput.addEventListener("change", (event) => {
      if (oneOnOneFilterSkillInput.checked) {
        this.oneOnOneFilter.push("19");
      } else {
        this.removeArrayItem(this.oneOnOneFilter, "19");
        oneOnOneFilterAllInput.checked = false;
      }
      this.oneOnOneCourseGet();
    });

    // 關鍵字搜尋觸發偵測
    // 目前的作法是，搜尋後分別打三次API，分別是1對1、週課、函授影片的資料都各取一次
    let courseSearchInput = document.getElementById("courseSearch");
    courseSearchInput.addEventListener("keypress", (event) => {
      if (courseSearchInput.value == "") {
        return;
      }
      // input按下enter
      if (event.which === 13) {
        this.oneOnOneCourseSearch(courseSearchInput.value, 1, 999999);
        this.weeklyClassCourseSearch(courseSearchInput.value, 1, 999999);
        this.correspondenceEducationCourseSearch(
          courseSearchInput.value,
          1,
          999999
        );
        // 存放搜尋前是在哪一個頁面

        // 為了避免在搜尋頁面再次搜尋時，會造成上一頁狀態被清除，因此無法回到上一頁
        if (
          this.views != "search-result" &&
          this.views != this.VIEW.search_result_all
        ) {
          this.viewsBuffer = this.views;
        }

        this.views = "search-result";
      }
    });

    // 放大鏡搜尋查看全部頁面，回到搜尋結果
    // 做法是點下返回圖示後，直接重新觸發一次關鍵字搜尋
    let courseSearchBack = document.getElementById("searchBackImg");
    courseSearchBack.addEventListener("click", (event) => {
      if (courseSearchInput.value == "") {
        return;
      }
      this.oneOnOneCourseSearch(courseSearchInput.value, 1, 999999);
      this.weeklyClassCourseSearch(courseSearchInput.value, 1, 999999);
      this.correspondenceEducationCourseSearch(
        courseSearchInput.value,
        1,
        999999
      );
      this.views = "search-result";
    });
  },
  computed: {
    // 自動計算有教授當前 Subject 的老師
    allowLecturers() {
      if (this.weeklyCorrespondenceSubjectFilter[0]) {
        return this.allLecturerList.filter((lecturer) => {
          return lecturer.subject.includes(
            this.weeklyCorrespondenceSubjectFilter[0]
          );
        });
      } else {
        return this.allLecturerList;
      }
    },

    // 是否有搜尋，需要做樣式處理
    weeklyCorrespondenceSubjectFilterActivate() {
      return this.weeklyCorrespondenceSubjectFilter.length !== 0;
    },
    weeklyCorrespondenceLevelFilterActivate() {
      return this.weeklyCorrespondenceLevelFilter.length !== 0;
    },
    weeklyCorrespondenceLecturerFilterActivate() {
      return this.weeklyCorrespondenceLecturerFilter.length !== 0;
    },
    weeklyCorrespondenceDateFilterActivate() {
      return this.weeklyCorrespondenceDateFilter.length !== 0;
    },
    weeklyCorrespondenceFilterSubjectLevelText() {
      // 計算過濾等級的標籤
      // 需要根據 Category 來顯示等級文字
      let foundCategorySubject = "";
      this.allSubjects.forEach((categoryInfo) => {
        const foundSubject = categoryInfo.subjects.find(
          (subjectInfo) =>
            subjectInfo.subject === this.weeklyCorrespondenceSubjectFilter[0]
        );
        if (foundSubject) {
          foundCategorySubject = categoryInfo;
        }
      });
      if (this.weeklyCorrespondenceLevelFilter[0]) {
        return this.categorySubjectLevelMap(
          this.categorySubjectMap(
            foundCategorySubject.category,
            this.weeklyCorrespondenceSubjectFilter[0]
          ),
          this.weeklyCorrespondenceLevelFilter[0]
        );
      } else {
        return "";
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss"; // 全域 CSS
$selectTextColor: #1e1f1fd9;
$selectGroupColor: #1f878a;
$selectOptionColor: #1e1f1fa6;
$selectBorderColor: #3b3f3f33;

// 電腦等尺寸較大的顯示
@mixin CourseTypeTabContainer() {
  .course-type-tab-container-mobile {
    display: none;
  }

  .course-type-tab-container {
    display: flex;
    gap: 10px;
    padding: 12px 0px;
    margin-left: 100px;
  }

  .course-type-tab {
    width: 165px;
    height: 40px;
    border-radius: 100px;
    display: inline-block;
    border: 1px solid #cccccc;
    color: #5e5d5d;
    background-color: white;
    cursor: pointer;

    span {
      vertical-align: middle;
      margin-left: 10.7px;
    }

    div.content {
      text-align: center;
      margin-top: 6px;
    }
  }

  .course-type-tab:hover {
    box-shadow: 2px 2px 5px 1px #ebebeb;
  }

  .course-type-tab.active {
    border: none;
    color: white;
    background-color: #25a2a6;
  }
}

// 在手機上的顯示
@mixin MobileCourseTypeTabContainer() {
  .course-type-tab-container {
    display: none;
  }

  .course-type-tab-container-mobile {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px 16px;
  }

  .course-type-tab {
    border-radius: 100px;
    display: inline-block;
    border: 1px solid #cccccc;
    color: #5e5d5d;

    div.content {
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: center;

      span {
        vertical-align: middle;
        margin-left: 10.7px;
      }
    }
  }

  .course-type-tab:hover {
    box-shadow: 2px 2px 5px 1px #ebebeb;
  }

  .course-type-tab.active {
    border: none;
    color: white;
    background-color: #25a2a6;
  }
}

/* 大於 1920px 時，套用以下 css */
@media screen and (min-width: 1920px) {
  // banner大圖
  .banner-block {
    // boder 1920時顯示，方便開發用
    // border: 1px solid red;
    background-image: url("~@/assets/img/shop/banner/oneOnOne/1920.png");
    border-radius: 10px;
    @include set-size(100%, 136px);
    // width: 1433px;
    // height: 136px;
    padding: 0;
    position: relative;

    .search-all-container {
      position: absolute;
      right: 24px;
      bottom: 44px;
      width: 284px;
      height: 48px;
      background-color: #ffffffe5;
      opacity: 0.8;
      border-radius: 8px;

      .search-all-icon:hover {
        cursor: pointer;
      }

      .search-all-icon {
        padding: 12px;
        background-color: #ffffffe5;
        border-radius: 8px;
      }

      .search-all-input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        color: #909090;
      }

      .search-clean-icon:hover {
        cursor: pointer;
      }

      .search-clean-icon {
        margin: 16px;
        width: 11.67px;
        height: 11.67px;
      }
    }
  }

  // 最新上架的超連結
  .new-arrival {
    margin-top: 12px;

    .title {
      margin-left: 20px;
      font-size: 14px;
      line-height: 19.6px;
      color: #5e5d5d;
      font-weight: bold;
    }

    .category0 {
      margin-left: 32px;
      font-size: 14px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category1 {
      margin-left: 20px;
      font-size: 14px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category2 {
      margin-left: 20px;
      font-size: 14px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category3 {
      margin-left: 20px;
      font-size: 14px;
      line-height: 17.5px;
      color: #5e5d5d;
    }

    .ccoin-quantity {
      margin-right: 16px;
      font-size: 14px;
      line-height: 26px;
      float: right;
      color: #4f60f6;
    }
  }

  // 手機板C幣顯示隱藏
  .ccoin-quantity-container-mobile {
    display: none;
  }

  // 課程選擇
  .course-container {
    margin-bottom: 85px;
  }

  .result-quantity-title-container-mobile {
    display: none !important;
  }

  .weekly-class-title-container {
    margin-top: 16px;
  }

  .course-selection {
    margin-top: 18px;

    @include CourseTypeTabContainer();

    // 選擇標籤
    .select-tab {
      border-radius: 10px 10px 0 0;
      background-color: #ffffff;
      height: 64px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
      position: relative;
      z-index: 1;
    }

    // 收藏及兌換清單手機板隱藏
    .collect-exchange-container-mobile {
      display: none !important;
    }

    // 將收藏清單以及兌換清單包在一個容器，然後整體作偏移設置
    .collect-exchange-container {
      display: inline-block;
      position: absolute;
      right: 100px;
      // top 30%是為了讓文字垂直置中
      top: 30%;
    }

    // 收藏清單
    .collect:hover {
      background-color: #ffffff;
      background: rgba(0, 0, 0, 0.05);
    }

    .collect {
      color: #25a2a6;
      padding: 10px 18px 10px 14px;
      text-decoration: none;
      margin-left: 473px;
      border-radius: 100px;

      span {
        vertical-align: middle;
        padding-left: 8.67px;
      }
    }

    .exchange {
      color: #25a2a6;
      padding: 0 16px 0 12px;
      text-decoration: none;
      margin-left: 10px;

      span:nth-child(2) {
        vertical-align: middle;
        padding-left: 8.67px;
      }

      span:nth-child(3) {
        vertical-align: middle;
        background-color: #25a2a6;
        color: #ffffff;
        display: inline-block;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: 999em;
        margin-left: 8px;
      }
    }

    .exchange-dropdown {
      position: relative;
      display: inline-block;
      // border: 1px solid red;
      height: 40px;
    }

    .exchange-list-box-triangle {
      display: none;
    }

    .exchange-dropdown-content {
      display: none;
      position: absolute;
      top: 40px;
      right: -16px;
      width: 273px;

      max-height: 283px;
      overflow-y: scroll;
      background-color: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
      z-index: 1;

      .view-exchange-list {
        width: 241px;
        height: 48px;
        margin: 0 16px 16px 16px;
        line-height: 18px;
        font-weight: 500;
      }
    }

    .exchange-dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .exchange-dropdown-content a:hover {
      background-color: #f2f5f5;
    }

    .exchange-dropdown:hover {
      .exchange-dropdown-content {
        display: block;
      }

      .exchange-list-box-triangle {
        display: block;
      }
    }
  }

  // 課程內容
  .course-content {
    // border: 1px solid red;
    background-color: #ffffff;
    padding-top: 43.5px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;

    .course-category {
      img {
        width: 16.76px;
        // 設定高度會影響到圖片偏移的位置，暫時不設定
        // height: 18px;
        // border: 1px solid red;
        color: #5e5d5d;
      }

      span {
        font-size: 20px;
        margin-left: 13.23px;
        color: #5e5d5d;
        font-weight: 500;
      }
    }

    .course-category-hr {
      margin-top: 9px;
      border: 1px solid #ececec;
      width: 99.7%;
    }

    .filter {
      margin-top: 40px;
      margin-right: 16px;
      margin-bottom: 20px;

      input {
        margin-right: 15px;
      }

      .all {
        margin-right: 22px;
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .dividing-line {
        border-left: 1px solid #e0e0e0;
        margin-right: 21px;
      }

      .en-jp {
        margin-right: 28px;
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .question-mark-content-container:hover {
        cursor: default;

        .question-mark-content {
          display: block;
        }
      }

      .question-mark {
        margin-right: 28px;
      }

      .question-mark-content {
        display: none;
        position: absolute;
        top: -50px;
        left: 42px;
        width: 186px;
        background-color: #207c98;
        color: #ffffff;
        padding: 10px 0px 10px 0px;
        text-align: center;
        border-radius: 100px;
        font-size: 12px;
        font-weight: 400;

        img {
          position: absolute;
          bottom: -8px;
          left: 24px;
        }
      }

      .special-language {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .ielts {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
        margin-right: 28px;
      }

      .skill {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .filter-ctrl {
        display: none;
      }
    }

    .cleanFilterSingle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      padding: 2px 6.75px 2px 6px;
      border-radius: 100px;
      margin-left: 5px;
      cursor: default;

      span {
        color: #25a2a6;
        font-size: 14px;
      }
    }

    .course-card-container {
      margin-bottom: 44px;
    }

    .one-on-one-quantity-all {
      font-size: 14px;
      color: #5e5d5d;
    }

    .quantity-all {
      font-size: 14px;
      color: #5e5d5d;
      height: 40px;
    }
  }

  // 課程推薦
  .course-recommend {
    span {
      margin-left: 13.54px;
      font-size: 18px;
      color: #5e5d5d;
    }
  }

  .course-recommend-hr {
    margin-top: 9px;
    border: 1px solid #ececec;
    width: 99.7%;
  }

  .course-recommend-container {
    padding-bottom: 65px;
  }

  // 課程卡片與下方距離調整
  .course-card-mb-0 {
    margin-bottom: 24px;
  }

  .course-card-mb-1 {
    margin-bottom: 24px;
  }

  .course-card-mb-2 {
    margin-bottom: 24px;
  }

  .course-card-mb-3 {
    margin-bottom: 24px;
  }

  .course-card-mb-4 {
    margin-bottom: 24px;
  }

  .course-card-mb-5 {
    margin-bottom: 24px;
  }

  .course-card-mb-6 {
    margin-bottom: 24px;
  }

  .course-card-mb-7 {
    margin-bottom: 24px;
  }

  .course-card-mb-8 {
  }

  .course-card-mb-9 {
  }

  .course-card-mb-10 {
  }

  .course-card-mb-11 {
  }

  .course-recommend-card-mr-0 {
    margin-right: 1.4%;
  }

  .course-recommend-card-mr-1 {
    margin-right: 1.4%;
  }

  // 提示方塊
  .exchange-alert {
    right: 97px;
    width: 288px;
    bottom: 24px;
  }
}

/* 遊覽區域寬度範圍在1440px~1919px，則下方CSS描述會立即被套用： */
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .banner-block {
    background-image: url("~@/assets/img/shop/banner/oneOnOne/1366.png");
    border-radius: 10px;
    @include set-size(100%, 120px);
    // width: 1026px;
    // height: 120px;
    position: relative;

    .search-all-container {
      position: absolute;
      right: 28px;
      bottom: 36px;

      width: 257px;
      height: 48px;
      background-color: #ffffffe5;
      opacity: 0.8;
      border-radius: 8px;

      .search-all-icon:hover {
        cursor: pointer;
      }

      .search-all-icon {
        padding: 12px;
        background-color: #ffffffe5;
        border-radius: 8px;
      }

      .search-all-input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        color: #909090;
      }

      .search-clean-icon:hover {
        cursor: pointer;
      }

      .search-clean-icon {
        margin: 16px;
        background-color: #ffffffe5;
        opacity: 0.8;
        width: 11.67px;
        height: 11.67px;
      }
    }
  }

  // 最新上架的超連結
  .new-arrival {
    margin-top: 12px;

    .title {
      margin-left: 20px;
      font-size: 14px;
      line-height: 19.6px;
      color: #5e5d5d;
      font-weight: bold;
    }

    .category0 {
      margin-left: 32px;
      font-size: 14px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category1 {
      margin-left: 20px;
      font-size: 14px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category2 {
      margin-left: 20px;
      font-size: 14px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category3 {
      margin-left: 20px;
      font-size: 14px;
      line-height: 17.5px;
      color: #5e5d5d;
    }

    .ccoin-quantity {
      margin-right: 16px;
      font-size: 14px;
      line-height: 24px;
      float: right;
      color: #4f60f6;
    }
  }

  // 手機板C幣顯示隱藏
  .ccoin-quantity-container-mobile {
    display: none;
  }

  // 課程選擇
  .course-container {
    margin-bottom: 85px;
    width: 100%;
  }

  .result-quantity-title-container-mobile {
    display: none !important;
  }

  .weekly-class-title-container {
    margin-top: 16px;
  }

  .course-selection {
    margin-top: 18px;

    // 選擇標籤手機板隱藏
    @include CourseTypeTabContainer();

    // 選擇標籤
    .select-tab {
      border-radius: 10px 10px 0 0;
      background-color: #ffffff;
      height: 64px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
      position: relative;
      z-index: 1;

      // img {
      //   width: 13.97px;
      //   height: 15px;
      // }
    }

    // 收藏及兌換清單手機板隱藏
    .collect-exchange-container-mobile {
      display: none !important;
    }

    // 將收藏清單以及兌換清單包在一個容器，然後整體作偏移設置
    .collect-exchange-container {
      display: inline-block;
      position: absolute;
      right: 64px;
      // top 30%是為了讓文字垂直置中
      top: 30%;
    }

    // 收藏清單
    .collect:hover {
      background-color: #ffffff;
      background: rgba(0, 0, 0, 0.05);
    }

    .collect {
      color: #25a2a6;
      padding: 10px 18px 10px 14px;
      text-decoration: none;
      // margin-right: 190px;
      border-radius: 100px;

      span {
        vertical-align: middle;
        padding-left: 8.67px;
      }
    }

    .exchange {
      color: #25a2a6;
      padding: 0 16px 0 12px;
      text-decoration: none;
      // margin-left: 10px;
      // margin-right: 0px;

      span:nth-child(2) {
        vertical-align: middle;
        padding-left: 8.67px;
      }

      span:nth-child(3) {
        vertical-align: middle;
        background-color: #25a2a6;
        color: #ffffff;
        display: inline-block;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: 999em;
        margin-left: 8px;
      }
    }

    .exchange-dropdown {
      position: relative;
      display: inline-block;
      height: 40px;
    }

    .exchange-list-box-triangle {
      display: none;
    }

    .exchange-dropdown-content {
      display: none;
      position: absolute;
      top: 40px;
      right: -16px;
      width: 273px;
      max-height: 283px;
      overflow-y: auto;
      background-color: #ffffff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.18);
      z-index: 1;

      .exchange-item {
        margin: 8px 16px;
        border-radius: 10px;

        span {
          margin-left: 8px;
          font-size: 14px;
        }
      }

      .view-exchange-list {
        width: 241px;
        height: 48px;
        margin: 0 16px 16px 16px;
        line-height: 18px;
        font-weight: 500;
      }
    }

    .exchange-dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .exchange-dropdown-content a:hover {
      background-color: #f2f5f5;
    }

    .exchange-dropdown:hover {
      .exchange-dropdown-content {
        display: block;
      }

      .exchange-list-box-triangle {
        display: block;
      }
    }
  }

  // 課程內容
  .course-content {
    // border: 1px solid red;
    background-color: #ffffff;
    padding-top: 43.5px;
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;
    min-width: 898px;

    .course-category {
      img {
        width: 16.76px;
        // 設定高度會影響到圖片偏移的位置，暫時不設定
        // height: 18px;
        // border: 1px solid red;
        color: #5e5d5d;
      }

      span {
        font-size: 20px;
        margin-left: 13.23px;
        color: #5e5d5d;
        font-weight: 500;
      }
    }

    .course-category-hr {
      margin-top: 9px;
      border: 1px solid #ececec;
      width: 99%;
    }

    .filter {
      margin-top: 40px;
      margin-right: 16px;
      margin-bottom: 20px;

      input {
        margin-right: 15px;
      }

      .all {
        margin-right: 22px;
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .dividing-line {
        border-left: 1px solid #e0e0e0;
        margin-right: 21px;
      }

      .en-jp {
        margin-right: 28px;
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .question-mark-content-container:hover {
        cursor: default;

        .question-mark-content {
          display: block;
        }
      }

      .question-mark {
        margin-right: 28px;
      }

      .question-mark-content {
        display: none;
        position: absolute;
        top: -50px;
        left: 44px;
        width: 186px;
        background-color: #207c98;
        color: #ffffff;
        padding: 10px 0px 10px 0px;
        text-align: center;
        border-radius: 100px;
        font-size: 12px;
        font-weight: 400;

        img {
          position: absolute;
          bottom: -8px;
          left: 24px;
        }
      }

      .special-language {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .ielts {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
        margin-right: 28px;
      }

      .skill {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .filter-ctrl {
        display: none;
      }
    }

    .cleanFilterSingle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      padding: 2px 6.75px 2px 6px;
      border-radius: 100px;
      margin-left: 5px;
      cursor: pointer;

      span {
        color: #25a2a6;
        font-size: 14px;
      }
    }

    .course-card-container {
      margin-bottom: 40.5px;
    }

    .one-on-one-quantity-all {
      font-size: 14px;
      color: #5e5d5d;
    }

    .quantity-all {
      font-size: 14px;
      color: #5e5d5d;
      height: 40px;
    }
  }

  // 課程推薦
  .course-recommend {
    span {
      margin-left: 13.54px;
      font-size: 18px;
      color: #5e5d5d;
    }
  }

  .course-recommend-hr {
    margin-top: 9px;
    border: 1px solid #ececec;
    width: 99%;
  }

  .course-recommend-container {
    padding-bottom: 65px;
    justify-content: space-between;
  }

  // 課程卡片與下方距離調整
  .course-card-mb-0 {
    margin-bottom: 24px;
  }

  .course-card-mb-1 {
    margin-bottom: 24px;
  }

  .course-card-mb-2 {
    margin-bottom: 24px;
  }

  .course-card-mb-3 {
    margin-bottom: 24px;
  }

  .course-card-mb-4 {
    margin-bottom: 24px;
  }

  .course-card-mb-5 {
    margin-bottom: 24px;
  }

  .course-card-mb-6 {
    margin-bottom: 24px;
  }

  .course-card-mb-7 {
    margin-bottom: 24px;
  }

  .course-card-mb-8 {
    margin-bottom: 24px;
  }

  .course-card-mb-9 {
  }

  .course-card-mb-10 {
  }

  .course-card-mb-11 {
  }

  // 提示方塊
  .exchange-alert {
    right: 97px;
    width: 288px;
    bottom: 24px;
  }
}

/* 遊覽區域寬度範圍在1024px~1440px，則下方CSS描述會立即被套用： */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .banner-block {
    background-image: url("~@/assets/img/shop/banner/oneOnOne/1024.png");
    @include set-size(100%, 102px);
    // width: 811px;
    // height: 102px;
    border-radius: 10px;

    position: relative;

    .search-all-container {
      position: absolute;
      right: 28px;
      bottom: 27px;

      width: 180px;
      height: 48px;
      background-color: #ffffffe5;
      opacity: 0.8;
      border-radius: 8px;

      .search-all-icon:hover {
        cursor: pointer;
      }

      .search-all-icon {
        padding: 12px;
        background-color: #ffffffe5;
        border-radius: 8px;
      }

      .search-all-input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        color: #909090;
      }

      .search-clean-icon:hover {
        cursor: pointer;
      }

      .search-clean-icon {
        margin: 16px;
        background-color: #ffffffe5;
        opacity: 0.8;
        width: 11.67px;
        height: 11.67px;
      }
    }
  }

  // 最新上架的超連結
  .new-arrival {
    margin-top: 12px;

    .title {
      margin-left: 20px;
      font-size: 12px;
      line-height: 19.6px;
      color: #5e5d5d;
      font-weight: bold;
    }

    .category0 {
      margin-left: 32px;
      font-size: 12px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category1 {
      margin-left: 20px;
      font-size: 12px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category2 {
      display: none;
      margin-left: 20px;
      font-size: 12px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category3 {
      margin-left: 20px;
      font-size: 12px;
      line-height: 17.5px;
      color: #5e5d5d;
    }

    .ccoin-quantity {
      margin-right: 16px;
      font-size: 12px;
      line-height: 24px;
      float: right;
      color: #4f60f6;
    }
  }

  // 手機板C幣顯示隱藏
  .ccoin-quantity-container-mobile {
    display: none;
  }

  // 課程選擇
  .course-container {
    margin-bottom: 85px;
    width: 100%;
  }

  .result-quantity-title-container-mobile {
    display: none !important;
  }

  .weekly-class-title-container {
    margin-top: 16px;
  }

  .course-selection {
    margin-top: 18px;

    // 選擇標籤手機板隱藏
    @include CourseTypeTabContainer();

    .course-type-tab-container {
      margin-left: 24px;
    }

    // 選擇標籤
    .select-tab {
      border-radius: 10px 10px 0 0;
      background-color: #ffffff;
      height: 64px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
      position: relative;
      z-index: 1;

      // img {
      //   width: 13.97px;
      //   height: 15px;
      // }
    }

    // 收藏及兌換清單手機板隱藏
    .collect-exchange-container-mobile {
      display: none !important;
    }

    // 將收藏清單以及兌換清單包在一個容器，然後整體作偏移設置
    .collect-exchange-container {
      display: inline-block;
      position: absolute;
      right: 24px;
      // top 30%是為了讓文字垂直置中
      top: 30%;
    }

    // 收藏清單
    .collect:hover {
      background-color: #ffffff;
      background: rgba(0, 0, 0, 0.05);
    }

    .collect {
      color: #25a2a6;
      padding: 10px 18px 10px 14px;
      text-decoration: none;
      // margin-right: 190px;
      border-radius: 100px;

      span {
        vertical-align: middle;
        padding-left: 8.67px;
      }
    }

    .exchange {
      color: #25a2a6;
      padding: 0 16px;
      text-decoration: none;
      // margin-left: 10px;
      // margin-right: 0px;

      span:nth-child(2) {
        vertical-align: middle;
        padding-left: 8.67px;
      }

      span:nth-child(3) {
        vertical-align: middle;
        background-color: #25a2a6;
        color: #ffffff;
        display: inline-block;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: 999em;
        margin-left: 8px;
      }
    }

    .exchange-dropdown {
      position: relative;
      display: inline-block;
      height: 40px;
    }

    .exchange-list-box-triangle {
      display: none;
    }

    .exchange-dropdown-content {
      display: none;
      position: absolute;
      top: 40px;
      right: -16px;
      width: 232px;
      max-height: 283px;
      overflow-y: auto;
      background-color: #ffffff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.18);
      z-index: 1;

      .exchange-item {
        margin: 6px 16px;
        border-radius: 10px;

        span {
          margin-left: 8px;
          font-size: 14px;
        }
      }

      .view-exchange-list {
        width: 208px;
        height: 48px;
        margin: 0 16px 16px 16px;
        line-height: 18px;
        font-weight: 500;
      }
    }

    .exchange-dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .exchange-dropdown-content a:hover {
      background-color: #f2f5f5;
    }

    .exchange-dropdown:hover {
      .exchange-dropdown-content {
        display: block;
      }

      .exchange-list-box-triangle {
        display: block;
      }
    }
  }

  // 課程內容
  .course-content {
    // border: 1px solid red;
    background-color: #ffffff;
    padding-top: 43.5px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;
    min-width: 811px;

    .course-category {
      img {
        width: 16.76px;
        // 設定高度會影響到圖片偏移的位置，暫時不設定
        // height: 18px;
        // border: 1px solid red;
        color: #5e5d5d;
      }

      span {
        font-size: 20px;
        margin-left: 13.23px;
        color: #5e5d5d;
        font-weight: 500;
      }
    }

    .course-category-hr {
      margin-top: 9px;
      border: 1px solid #ececec;
      width: 98.5%;
    }

    // 過濾選項
    .filter {
      margin-top: 40px;
      margin-right: 16px;
      margin-bottom: 20px;

      input {
        margin-right: 15px;
        color: #777777;
      }

      .all {
        margin-right: 22px;
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .dividing-line {
        border-left: 1px solid #e0e0e0;
        margin-right: 21px;
      }

      .en-jp {
        margin-right: 28px;
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .question-mark-content-container:hover {
        cursor: default;

        .question-mark-content {
          display: block;
        }
      }

      .question-mark {
        margin-right: 28px;
      }

      .question-mark-content {
        display: none;
        position: absolute;
        top: -50px;
        left: 35px;
        width: 186px;
        background-color: #207c98;
        color: #ffffff;
        padding: 10px 0px 10px 0px;
        text-align: center;
        border-radius: 100px;
        font-size: 12px;
        font-weight: 400;

        img {
          position: absolute;
          bottom: -8px;
          left: 33px;
        }
      }

      .special-language {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .ielts {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
        margin-right: 28px;
      }

      .skill {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
      }

      .filter-ctrl {
        display: none;
      }
    }

    .cleanFilterSingle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      padding: 2px 6.75px 2px 6px;
      border-radius: 100px;
      margin-left: 5px;
      cursor: pointer;

      span {
        color: #25a2a6;
        font-size: 14px;
      }
    }

    .course-card-container {
      margin-bottom: 40.5px;
    }

    .one-on-one-quantity-all {
      font-size: 14px;
      color: #5e5d5d;
    }

    .quantity-all {
      font-size: 14px;
      color: #5e5d5d;
      height: 40px;
    }
  }

  // 課程推薦
  .course-recommend {
    span {
      margin-left: 13.54px;
      font-size: 18px;
      color: #5e5d5d;
    }
  }

  .course-recommend-hr {
    margin-top: 9px;
    border: 1px solid #ececec;
    width: 98.5%;
  }

  .course-recommend-container {
    padding-bottom: 65px;
    justify-content: space-between;
  }

  // 課程卡片與下方距離調整
  .course-card-mb-0 {
    margin-bottom: 24px;
  }

  .course-card-mb-1 {
    margin-bottom: 24px;
  }

  .course-card-mb-2 {
    margin-bottom: 24px;
  }

  .course-card-mb-3 {
    margin-bottom: 24px;
  }

  .course-card-mb-4 {
    margin-bottom: 24px;
  }

  .course-card-mb-5 {
    margin-bottom: 24px;
  }

  .course-card-mb-6 {
    margin-bottom: 24px;
  }

  .course-card-mb-7 {
    margin-bottom: 24px;
  }

  .course-card-mb-8 {
    margin-bottom: 24px;
  }

  .course-card-mb-9 {
  }

  .course-card-mb-10 {
  }

  .course-card-mb-11 {
  }

  // 提示方塊
  .exchange-alert {
    right: 97px;
    width: 288px;
    bottom: 24px;
  }
}

/* 遊覽區域寬度範圍在768px~1023px，則下方CSS描述會立即被套用： */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .banner-block {
    background-image: url("~@/assets/img/shop/banner/oneOnOne/768.png");
    @include set-size(100%, 150px);
    // width: 768px;
    // height: 150px;
    position: relative;

    .search-all-container {
      position: absolute;
      right: 28px;
      bottom: 51px;

      width: 182px;
      height: 48px;
      background-color: #ffffffe5;
      opacity: 0.8;
      border-radius: 8px;

      .search-all-icon:hover {
        cursor: pointer;
      }

      .search-all-icon {
        padding: 12px;
        background-color: #ffffffe5;
        border-radius: 8px;
      }

      .search-all-input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        color: #909090;
      }

      .search-clean-icon:hover {
        cursor: pointer;
      }

      .search-clean-icon {
        margin: 16px;
        background-color: #ffffffe5;
        opacity: 0.8;
        width: 11.67px;
        height: 11.67px;
      }
    }
  }

  .result-quantity-title-container-mobile {
    display: none !important;
  }

  .weekly-class-title-container {
    margin-top: 24px;
  }

  // 最新上架的超連結
  .new-arrival {
    margin-top: 12px;

    .title {
      margin-left: 20px;
      font-size: 12px;
      line-height: 19.6px;
      color: #5e5d5d;
      font-weight: bold;
    }

    .category0 {
      margin-left: 32px;
      font-size: 12px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category1 {
      margin-left: 20px;
      font-size: 12px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category2 {
      display: none;
      margin-left: 20px;
      font-size: 12px;
      line-height: 17.5px;
      color: #25a2a6;
    }

    .category3 {
      margin-left: 20px;
      font-size: 12px;
      line-height: 17.5px;
      color: #5e5d5d;
    }

    .ccoin-quantity {
      margin-right: 16px;
      font-size: 12px;
      line-height: 24px;
      float: right;
      color: #4f60f6;
    }
  }

  // 手機板C幣顯示隱藏
  .ccoin-quantity-container-mobile {
    display: none;
  }

  // 課程選擇
  .course-container {
    margin-bottom: 85px;
    width: 100%;
  }

  .course-selection {
    margin-top: 18px;

    // 手機板選擇標籤隱藏
    @include CourseTypeTabContainer();

    .course-type-tab-container {
      margin-left: 16px;
    }

    // 選擇標籤
    .select-tab {
      border-radius: 10px 10px 0 0;
      background-color: #ffffff;
      height: 64px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
      position: relative;
      z-index: 1;

      // img {
      //   width: 13.97px;
      //   height: 15px;
      // }
    }

    // 收藏及兌換清單手機板隱藏
    .collect-exchange-container-mobile {
      display: none !important;
    }

    // 將收藏清單以及兌換清單包在一個容器，然後整體作偏移設置
    .collect-exchange-container {
      display: inline-block;
      position: absolute;
      right: 16px;
      // top 30%是為了讓文字垂直置中
      top: 30%;
    }

    // 收藏清單
    .collect:hover {
      background-color: #ffffff;
      background: rgba(0, 0, 0, 0.05);
    }

    .collect {
      color: #25a2a6;
      padding: 10px 18px 10px 14px;
      text-decoration: none;
      // margin-right: 190px;
      border-radius: 100px;

      img {
        width: 13.5px;
        height: 11.72px;
      }

      span {
        vertical-align: middle;
        padding-left: 8.67px;
        font-size: 14px;
      }
    }

    .exchange:hover {
      background-color: #ffffff;
      background: rgba(0, 0, 0, 0.05);
    }

    .exchange {
      color: #25a2a6;
      text-decoration: none;
      padding: 10px 10px 10px 14px;
      border-radius: 100px;

      span:nth-child(2) {
        vertical-align: middle;
        padding-left: 8.67px;
        font-size: 14px;
      }

      span:nth-child(3) {
        vertical-align: middle;
        background-color: #25a2a6;
        color: #ffffff;
        display: inline-block;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: 999em;
        margin-left: 8px;
      }
    }

    .exchange-dropdown {
      position: relative;
      display: inline-block;
      height: 40px;
    }

    .exchange-list-box-triangle {
      display: none;
    }

    .exchange-dropdown-content {
      display: none;
      position: absolute;
      top: 40px;
      right: -16px;
      width: 232px;
      max-height: 283px;
      background-color: #ffffff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.18);
      z-index: 1;

      .view-exchange-list {
        width: 208px;
        height: 48px;
        margin: 0 auto 12px;
        line-height: 18px;
        font-weight: 500;
      }
    }

    .exchange-dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .exchange-dropdown-content a:hover {
      background-color: #f2f5f5;
    }

    // 因為768px版本不需要浮動顯示內容，因此直接取消觸發條件
    // .exchange-dropdown:hover .exchange-dropdown-content {
    //   display: block;
    // }
  }

  // 課程內容
  .course-content {
    // border: 1px solid red;
    background-color: #ffffff;
    padding-top: 43.5px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;
    min-width: 768px;

    .course-category {
      img {
        width: 16.76px;
        // 設定高度會影響到圖片偏移的位置，暫時不設定
        // height: 18px;
        // border: 1px solid red;
        color: #5e5d5d;
      }

      span {
        font-size: 20px;
        margin-left: 13.23px;
        color: #5e5d5d;
        font-weight: 500;
      }
    }

    .course-category-hr {
      margin-top: 9px;
      border: 1px solid #ececec;
      max-width: 100%;
    }

    .filter-select {
      display: none !important;
    }

    // 過濾選項
    .filter {
      margin-top: 40px;
      margin-right: 16px;
      margin-bottom: 20px;

      input {
        margin-right: 15px;
        color: #777777;
      }

      .all {
        margin-right: 22px;
      }

      .dividing-line {
        border-left: 1px solid #e0e0e0;
        margin-right: 21px;
      }

      .en-jp {
        margin-right: 28px;
      }

      .question-mark {
        margin-right: 28px;
      }

      .filter-select {
        display: none;
      }

      .filter-ctrl:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .filter-ctrl {
        // 透過transition增加變色時的動畫效果
        transition: all 0.5s ease-out;
        line-height: 0px;
        padding: 12px;
        border-radius: 100px;
        position: relative;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .cleanFilterSingle {
      display: none !important;
    }
  }

  // 課程推薦
  .course-recommend {
    span {
      margin-left: 13.54px;
      font-size: 18px;
      color: #5e5d5d;
    }
  }

  .course-recommend-hr {
    margin-top: 9px;
    border: 1px solid #ececec;
    width: 98.5%;
  }

  .course-recommend-container {
    padding-bottom: 65px;
    justify-content: space-between;
  }

  // 課程卡片與下方距離調整
  .course-card-mb-0 {
    margin-bottom: 16px;
  }

  .course-card-mb-1 {
    margin-bottom: 16px;
  }

  .course-card-mb-2 {
    margin-bottom: 16px;
  }

  .course-card-mb-3 {
    margin-bottom: 16px;
  }

  .course-card-mb-4 {
    margin-bottom: 16px;
  }

  .course-card-mb-5 {
    margin-bottom: 16px;
  }

  .course-card-mb-6 {
    margin-bottom: 16px;
  }

  .course-card-mb-7 {
    margin-bottom: 16px;
  }

  .course-card-mb-8 {
    margin-bottom: 16px;
  }

  .course-card-mb-9 {
    margin-bottom: 16px;
  }

  .course-card-mb-10 {
    margin-bottom: 16px;
  }

  .course-card-mb-11 {
    margin-bottom: 16px;
  }

  // 提示方塊
  .exchange-alert {
    right: 22.15%;
    width: 55.7%;
    bottom: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .search-result-title {
    padding-bottom: 9px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
  }
}

/* 遊覽區域寬度範圍在415px~767px，則下方CSS描述會立即被套用： */
@media only screen and (min-width: 415px) and (max-width: 767px) {
  .banner-block {
    background-image: url("~@/assets/img/shop/banner/oneOnOne/415.png");
    @include set-size(100%, 281px);
    width: 100%;
    // height: 281px;
    position: relative;
    // padding: 0 16px;

    .search-all-container {
      position: absolute;
      // right: 16px;
      bottom: 16px;
      margin: 0 5%;
      width: 90%;
      height: 48px;
      background-color: #ffffffe5;
      opacity: 0.8;
      border-radius: 8px;

      .search-all-icon:hover {
        cursor: pointer;
      }

      .search-all-icon {
        padding: 12px;
        background-color: #ffffffe5;
        border-radius: 8px;
      }

      .search-all-input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        color: #909090;
      }

      .search-clean-icon:hover {
        cursor: pointer;
      }

      .search-clean-icon {
        margin: 16px;
        background-color: #ffffffe5;
        opacity: 0.8;
        width: 11.67px;
        height: 11.67px;
      }
    }
  }

  // 最新上架的超連結，該版本不顯示，並且將裡面的內容刪除，class name可以給其他物件使用
  .new-arrival {
    display: none;
  }

  .ccoin-quantity-container-mobile {
    margin: 14px 17px 0px 17px;
    height: 20px;

    .ccoin-quantity {
      width: 50%;
      line-height: 20px;
      color: #25a2a6;
      float: left;
    }

    .stored-value {
      line-height: 20px;
      color: #25a2a6;
      float: right;
    }

    a {
      text-decoration: none;
    }
  }

  // 課程選擇
  .course-container {
    margin-bottom: 85px;
    width: 100%;
  }

  .search-result-title {
    padding-bottom: 9px;
  }

  .result-quantity-title-container {
    display: none !important;
  }

  .weekly-class-title-container {
    margin-top: 24px;
    margin-bottom: 6px;
  }

  .course-selection {
    margin-top: 18px;

    // 選擇標籤
    .select-tab {
      display: none;
    }

    // 選擇標籤手機板
    @include MobileCourseTypeTabContainer();
    .course-type-tab-container-mobile {
      padding: 16px 16px;
    }

    // 收藏及兌換清單手機板
    .collect-exchange-container-mobile {
      width: 100%;
      height: 60px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: white;
      z-index: 9;
      padding: 10px 16px;
      text-align: center;

      .collect {
        margin: 0 auto;
        width: 48%;
        border: 1px solid #cccccc;
        height: 40px;
        line-height: 35px;

        span {
          margin-left: 10.3px;
          vertical-align: middle;
        }
      }

      .exchange {
        margin: 0 auto;
        background-color: #25a2a6;
        color: white;
        width: 48%;
        border-radius: 5px;
        height: 40px;

        line-height: 35px;

        span {
          margin-left: 10.3px;
          vertical-align: middle;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  // 課程內容
  .course-content {
    // border: 1px solid red;
    background-color: #ffffff;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;
    // min-width: 415px;

    .course-category {
      img {
        width: 16.76px;
        // 設定高度會影響到圖片偏移的位置，暫時不設定
        // height: 18px;
        // border: 1px solid red;
        color: #5e5d5d;
      }

      span {
        font-size: 20px;
        margin-left: 13.23px;
        color: #5e5d5d;
        font-weight: 500;
      }
    }

    .course-category-hr {
      margin-top: 9px;
      border: 1px solid #ececec;
      max-width: 100%;
    }

    // 過濾選項
    .filter-select {
      display: none !important;
    }

    .filter {
      margin-top: 26px;
      margin-bottom: 16px;

      input {
        margin-right: 15px;
        color: #777777;
      }

      .all {
        margin-right: 22px;
      }

      .dividing-line {
        border-left: 1px solid #e0e0e0;
        margin-right: 21px;
      }

      .en-jp {
        margin-right: 28px;
      }

      .question-mark {
        margin-right: 28px;
      }

      .filter-select {
        display: none;
      }

      .filter-ctrl:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .filter-ctrl {
        // 透過transition增加變色時的動畫效果
        transition: all 0.5s ease-out;
        line-height: 0px;
        padding: 12px;
        border-radius: 100px;
        position: relative;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .cleanFilterSingle {
      display: none !important;
    }

    .course-card-container {
      margin-bottom: 16px;
    }

    .one-on-one-quantity-all {
      font-size: 14px;
      color: #5e5d5d;
    }

    .quantity-all {
      font-size: 14px;
      color: #5e5d5d;
      height: 40px;
    }
  }

  // 課程推薦
  .course-recommend {
    span {
      margin-left: 13.54px;
      font-size: 18px;
      color: #5e5d5d;
    }
  }

  .course-recommend-hr {
    margin-top: 9px;
    border: 1px solid #ececec;
    width: 100%;
  }

  // 課程卡片與下方距離調整
  .course-card-mb-0 {
    margin-bottom: 16px;
  }

  .course-card-mb-1 {
    margin-bottom: 16px;
  }

  .course-card-mb-2 {
    margin-bottom: 16px;
  }

  .course-card-mb-3 {
    margin-bottom: 16px;
  }

  .course-card-mb-4 {
    margin-bottom: 16px;
  }

  .course-card-mb-5 {
    margin-bottom: 16px;
  }

  .course-card-mb-6 {
    margin-bottom: 16px;
  }

  .course-card-mb-7 {
    margin-bottom: 16px;
  }

  .course-card-mb-8 {
    margin-bottom: 16px;
  }

  .course-card-mb-9 {
    margin-bottom: 16px;
  }

  .course-card-mb-10 {
    margin-bottom: 16px;
  }

  .course-card-mb-11 {
  }

  .course-recommend-card-mb-0 {
    margin-bottom: 16px;
  }

  .course-recommend-card-mb-1 {
    margin-bottom: 16px;
  }

  // 提示方塊
  .exchange-alert {
    right: 2.5%;
    width: 95%;
    bottom: 70px;
  }
}

/* 遊覽區域寬度範圍小於415px，則下方CSS描述會立即被套用： */
@media only screen and (max-width: 414px) {
  .banner-block {
    background-image: url("~@/assets/img/shop/banner/oneOnOne/320.png");
    @include set-size(100%, 281px);
    // width: 320px;
    // height: 281px;
    position: relative;

    .search-all-container {
      position: absolute;
      // right: 16px;
      bottom: 16px;
      margin-left: 5%;
      margin-right: 5%;
      width: 90%;
      height: 48px;
      background-color: #ffffffe5;
      opacity: 0.8;
      border-radius: 8px;

      .search-all-icon:hover {
        cursor: pointer;
      }

      .search-all-icon {
        padding: 12px;
        background-color: #ffffffe5;
        border-radius: 8px;
      }

      .search-all-input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        color: #909090;
      }

      .search-clean-icon:hover {
        cursor: pointer;
      }

      .search-clean-icon {
        margin: 16px;
        background-color: #ffffffe5;
        opacity: 0.8;
        width: 11.67px;
        height: 11.67px;
      }
    }
  }

  // 最新上架的超連結，該版本不顯示，並且將裡面的內容刪除，class name可以給其他物件使用
  .new-arrival {
    display: none;
  }

  .ccoin-quantity-container-mobile {
    margin: 14px 16px 0px 16px;
    height: 20px;

    .ccoin-quantity {
      width: 80%;
      line-height: 20px;
      color: #25a2a6;
      float: left;
    }

    .stored-value {
      max-width: 30%;
      line-height: 20px;
      color: #25a2a6;
      float: right;
    }

    a {
      text-decoration: none;
    }
  }

  // 課程選擇
  .course-container {
    margin-bottom: 85px;
    width: 100%;

    .search-result-title {
      padding-bottom: 9px;
    }

    .result-quantity-title-container {
      display: none !important;
    }

    .weekly-class-title-container {
      margin-top: 24px;
    }
  }

  .course-selection {
    margin-top: 18px;

    // 選擇標籤
    .select-tab {
      display: none;
    }

    // 選擇標籤手機板
    @include MobileCourseTypeTabContainer();
    .course-type-tab-container-mobile {
      padding: 16px 16px;
    }

    // 收藏及兌換清單手機板
    .collect-exchange-container-mobile {
      width: 100%;
      height: 60px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: white;
      z-index: 9;
      padding: 10px 16px;
      text-align: center;

      .collect {
        margin: 0 auto;
        width: 48%;
        border: 1px solid #cccccc;
        height: 40px;
        line-height: 35px;

        span {
          margin-left: 10.3px;
          vertical-align: middle;
        }
      }

      .exchange {
        margin: 0 auto;
        background-color: #25a2a6;
        color: white;
        width: 48%;
        border-radius: 5px;
        height: 40px;

        line-height: 35px;

        span {
          margin-left: 10.3px;
          vertical-align: middle;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  // 課程內容
  .course-content {
    // border: 1px solid red;
    background-color: #ffffff;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;
    // min-width: 320px;

    .course-category {
      img {
        width: 16.76px;
        // 設定高度會影響到圖片偏移的位置，暫時不設定
        // height: 18px;
        // border: 1px solid red;
        color: #5e5d5d;
      }

      span {
        font-size: 20px;
        margin-left: 13.23px;
        color: #5e5d5d;
        font-weight: 500;
      }
    }

    .course-category-hr {
      margin-top: 9px;
      border: 1px solid #ececec;
      max-width: 100%;
    }

    .filter-select {
      display: none !important;
    }

    // 過濾選項
    .filter {
      margin-top: 26px;
      margin-bottom: 16px;

      input {
        margin-right: 15px;
        color: #777777;
      }

      .all {
        margin-right: 22px;
      }

      .dividing-line {
        border-left: 1px solid #e0e0e0;
        margin-right: 21px;
      }

      .en-jp {
        margin-right: 28px;
      }

      .question-mark {
        margin-right: 28px;
      }

      .filter-select {
        display: none;
      }

      .filter-ctrl:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .filter-ctrl {
        // 透過transition增加變色時的動畫效果
        transition: all 0.5s ease-out;
        line-height: 0px;
        padding: 12px;
        border-radius: 100px;
        position: relative;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .cleanFilterSingle {
      display: none !important;
    }

    .course-card-container {
      margin-bottom: 16px;
    }

    .one-on-one-quantity-all {
      font-size: 14px;
      color: #5e5d5d;
    }

    .quantity-all {
      font-size: 14px;
      color: #5e5d5d;
      height: 40px;
    }
  }

  // 課程推薦
  .course-recommend {
    span {
      margin-left: 13.54px;
      font-size: 18px;
      color: #5e5d5d;
    }
  }

  .course-recommend-hr {
    margin-top: 9px;
    border: 1px solid #ececec;
    width: 100%;
  }

  // 課程卡片與下方距離調整
  .course-card-mb-0 {
    margin-bottom: 16px;
  }

  .course-card-mb-1 {
    margin-bottom: 16px;
  }

  .course-card-mb-2 {
    margin-bottom: 16px;
  }

  .course-card-mb-3 {
    margin-bottom: 16px;
  }

  .course-card-mb-4 {
    margin-bottom: 16px;
  }

  .course-card-mb-5 {
    margin-bottom: 16px;
  }

  .course-card-mb-6 {
    margin-bottom: 16px;
  }

  .course-card-mb-7 {
    margin-bottom: 16px;
  }

  .course-card-mb-8 {
    margin-bottom: 16px;
  }

  .course-card-mb-9 {
    margin-bottom: 16px;
  }

  .course-card-mb-10 {
    margin-bottom: 16px;
  }

  .course-card-mb-11 {
  }

  .course-recommend-card-mb-0 {
    margin-bottom: 16px;
  }

  .course-recommend-card-mb-1 {
    margin-bottom: 16px;
  }

  // 提示方塊
  .exchange-alert {
    right: 2.5%;
    width: 95%;
    bottom: 70px;
  }
}

/* 其他固定CSS */
.banner-block {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
}

.button:hover {
}

// 週課過濾選項標籤
.weekly-class-filter-container {
  // 多重選項的css
  display: flex;
  gap: 12px;

  input[type="radio"] {
    display: none;
  }

  .button {
    font-size: 14px;
    display: block;
    padding: 7px 12px;
    width: 100%;
    overflow-x: hidden;
    white-space: pre-wrap;
  }

  .button:hover {
    background-color: white;
    cursor: pointer;
  }

  .select-menu {
    .select-option-group {
      color: $selectGroupColor;
      font-size: 14px;
      padding: 8px 10px;
    }

    .select-option {
      font-size: 16px;
      display: block;
      padding: 3px 0px 3px 24px;
      width: 100%;
      overflow-x: hidden;
      white-space: pre-wrap;
      color: $selectOptionColor;
    }

    .select-option:hover {
      background: #f7f7f7;
      cursor: pointer;
    }
  }

  .btnDisabled {
    font-size: 16px;
    display: none;
    padding: 3px 0px 3px 24px;
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    background-color: #cccccc;
    color: white;
  }

  .btnDisabled:hover {
    cursor: auto;
    background-color: #cccccc;
  }

  .weekly-class-tag-container:hover {
    .option-menu-container {
      display: block !important;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 140px;
      border-radius: 4px;
      // 為了取消label之間的空白間距
      font-size: 0px;

      label {
        width: 100%;
      }
    }

    .option-menu-container div:not(:first-child) {
      border-top: 1px solid #3b3f3f33;
    }
  }

  .weekly-class-tag-container {
    border: 1px solid $selectBorderColor;
    border-radius: 5px;
    color: #5e5d5d;

    .weekly-class-tag-title {
      width: 140px;
      height: 40px;
      display: flex;
      align-items: center;

      a {
        width: 100%;
        text-decoration: none;
        color: $selectTextColor;
        display: flex;
        justify-content: space-between;
        padding: 0px 10px;

        span {
          color: $selectOptionColor;
          font-weight: 700;
        }
      }
    }

    .option-menu-container {
      // width: 122px;
      position: absolute;
      display: none !important;
      background-color: #ffffff;
      z-index: 1;
      border: 1px solid #cccccc;
      font-size: 14px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08),
        0px 0px 6px 0px rgba(0, 0, 0, 0.02);

      span.button {
        color: $selectOptionColor;
      }

      span.button:hover {
        background: #f7f7f7;
      }
    }
  }

  .weekly-class-tag-container.active {
    border: 1px solid $selectGroupColor;
  }

  .weekly-class-tag-container.disabled {
    background-color: #cccccc;
    cursor: auto;
  }

  .search-by-day {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    width: 128px;

    a {
      text-decoration: none;
      color: #5e5d5d;

      span {
        margin-left: 12px;
      }
    }
  }

  .clean-search {
    margin-left: 21px;
  }
}

// 分頁切換功能，PAGINATION
.pagination {
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 40px;

  .switch-btn {
    background-color: transparent;
    margin: 0px 15px;
    color: #2d2d2d;
    font-size: 10px;
  }

  .switch-btn.disabled {
    color: #cccccc;
  }

  .page-btn {
    border-radius: 5px;
    background-color: white;
    padding: 4px 10px;
    margin: 0px 4px;
    color: #5e5d5d;
    font-size: 14px;
    font-weight: 400;
  }

  .page-btn.active {
    background-color: #25a2a6;
    color: white;
    font-weight: 700;
  }

  button {
    border: none;
  }
}

.exchange-alert {
  padding: 0px 16px;
  position: fixed;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;

  div {
    background-color: #2797b8;
    height: 48px;
    width: 100%;
    box-shadow: 0px 0px 6px 0px #bdbdbd;
    border-radius: 5px;
    padding: 0px 20px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    justify-content: space-between;

    i:hover {
      cursor: pointer;
    }

    i {
      color: white;
      font-size: 24px;
    }
  }
}

// 放大鏡搜尋結果的css

// 大於 1920px 時，套用以下 css
@media screen and (min-width: 1920px) {
  // 使用justify-content-between自動分配課程卡片的間距，
  /* 如果最後一行是3個元素 */
  .course-card-margin:last-child:nth-child(4n - 1) {
    margin-right: calc(24% + 4% / 3);
  }

  /* 如果最後一行是2個元素 */
  .course-card-margin:last-child:nth-child(4n - 2) {
    margin-right: calc(48% + 8% / 3);
  }
}

// 大於 1024px 時，套用以下 css
@media screen and (min-width: 1024px) {
  .search-result {
    .result-quantity-title-container {
      margin-top: 40px;
      margin-bottom: 34px;
    }

    .one-on-one-class-title-container {
      margin-top: 28px;
      margin-bottom: 6px;
    }
  }
}

// 大於 768px 時，套用以下 css
@media screen and (min-width: 768px) and (max-width: 1919px) {
  // 使用justify-content-between自動分配課程卡片的間距，
  /* 如果最後一行是2個元素 */
  .course-card-margin:last-child:nth-child(3n - 1) {
    margin-right: calc(33.8%);
  }

  /* 如果最後一行是1個元素 */
  .course-card-margin:last-child:nth-child(3n - 2) {
    margin-right: calc(66.67%);
  }

  .result-quantity-title-container-mobile {
    display: none;
  }
}

// 大於 320px 時，套用以下 css
@media screen and (min-width: 320px) {
  .search-result {
    .result-quantity-title-container {
      margin-top: 36px;
    }

    .one-on-one-class-title-container {
      margin-top: 30px;
    }
  }
}

// 搜尋結果顯示修改
@media screen and (min-width: 768px) {
  .searchPcVersion {
  }

  .searchMobileVersion {
    display: none !important;
  }

  .searchChangeReduce {
    display: none !important;
  }

  .searchChangeAdd {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .searchPcVersion {
    display: none !important;
  }

  .searchMobileVersion {
    position: relative;
  }

  .searchChangeReduce {
    position: absolute;
    top: 38%;
    left: -7px;
    width: 32px;
    height: 32px;
    background-color: #cccccc;
    border-radius: 100px;
    border: none;
    opacity: 0.5;

    span {
      color: #5e5d5d;
    }
  }

  .searchChangeAdd {
    position: absolute;
    top: 38%;
    right: -7px;
    width: 32px;
    height: 32px;
    background-color: #cccccc;
    border-radius: 100px;
    border: none;
    opacity: 0.5;

    span {
      color: #5e5d5d;
    }
  }
}

// 其他固定CSS
.flag {
  width: 24px;
  height: 24px;
}

.search-result {
  .result-quantity-title-container {
    div {
      font-size: 14px;
      margin-right: 16px;
    }

    span {
      margin-left: 4px;
      color: #fc2c2c;
    }

    a {
      font-weight: 500;
      color: #4f60f6;
    }
  }

  .class-title {
    font-size: 20px;
    color: #5e5d5d;
  }

  .one-on-one-class-container {
    margin-top: 20px;
  }

  .weekly-class-container {
    margin-top: 20px;
  }
}

// 手機板搜尋結果，顯示視窗
.search-result-content-mobile {
  margin-top: -30px;
  padding: 8px 0px;
  max-width: 73%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 1px #cccccc;
  display: none;
  position: relative;
  z-index: 1;
}

.search-result-content-mobile > div {
  font-size: 14px;
  padding: 14px 0px 14px 12px;
  color: #5e5d5d;

  > span {
    color: #fc2c2c;
  }
}
</style>
