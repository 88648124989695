
import { defineComponent, PropType } from "vue";
import { Tooltip } from "bootstrap";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface info {
  customerName: string;
  customerMail: string;
  invoiceTo: string; // B 統編發票 ; C 電子發票
  buyer: string; // 非營業人則為 null
  companyId: string; // 非營業人則為 null
}
export default defineComponent({
  name: "UnpaidInvoiceModal",
  props: {
    // type: {
    //   type: String,
    //   required: true,
    // },
    info: {
      type: Object as PropType<info>,
      required: true,
    },
  },
});
