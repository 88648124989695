
import { defineComponent } from "vue";

export default defineComponent({
  name: "ClassFilterPageEmpty",
  props: ["info"],
  emits: ["backToAllCourse"],
  data() {
    return {};
  },
  methods: {
    backToAllCourse() {
      this.$emit("backToAllCourse", this.info);
    },
  },
});
