
import { defineComponent, vShow } from "vue";

export default defineComponent({
  name: "OnlineShopAltCategoryCard",
  data() {
    return {
      btns: [
        {
          category: "shop",
          imgActive: "shop_turkey",
          imgInactive: "shop_gray",
          name: "學習商店",
          route: "/online-shop?category=shop",
          show: true,
        },
        {
          category: "ccoin",
          imgActive: "ccoin_turkey",
          imgInactive: "ccoin_gray",
          name: "C幣儲值",
          route: "/online-shop?category=ccoin",
          show: true,
        },
        {
          category: "history",
          imgActive: "order_turkey",
          imgInactive: "order_gray",
          name: "兌換紀錄",
          route: "/online-shop?category=history",
          show: true,
        },
      ],
    };
  },
  computed: {
    pageRoute() {
      return this.$route.query.category;
    },
  },
  watch: {
    pageRoute() {
      if (this.$route.query.category == "exchangeList") {
        this.btns[0].category = "exchangeList";
      } else {
        this.btns[0].category = "shop";
      }
    },
  },
  props: {
    active: {
      type: String,
      required: true,
    },
  },
});
