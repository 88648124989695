
import { defineComponent, PropType } from "vue";
import { Modal } from "bootstrap";

interface favorite {
  coin: number;
  coinDiscount: number;
  discountText: string;
  enable: boolean;
  expirationDate: string;
  classDate: string;
  id: string;
  level: null | number;
  picture: null | string;
  sales: number;
  shoppingCart: boolean;
  title: string;
  type: number;
  cnName: string;
  foreignName: string;
  launchDate: string;
  setId: string;
}

export default defineComponent({
  name: "CourseCard",
  emits: [
    "openModal",
    "favoriteStatusChange",
    "addToCart",
    "favoriteIconClick",
    "removeItem",
  ],
  props: {
    info: {
      type: Object as PropType<favorite>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    calcDueDate(date: string) {
      const today = new Date().getTime();
      const diff = Math.abs(today - new Date(date).getTime());
      const days = Math.round(diff / (1000 * 3600 * 24));
      // 計算日期小於10天才顯示即將下架
      if (days <= 10) {
        return days + 1;
      } else {
        return null;
      }
    },
    typeMap(type: number) {
      switch (type) {
        case 1:
          return "一對一視訊課程";

        case 2:
          return "週課影片";

        case 3:
          return "函授課程";

        default:
          break;
      }
    },
    removeItem(id: string, index: number, setId: string) {
      this.$emit("removeItem", {
        id: id,
        index: index,
        setId: setId,
      });
    },
    addToCart(id: string, index: number, setId: string) {
      // 按下加入兌換的小圖示後，會將item及index兩個參數傳遞出去，從shop內執行將指定課程加入兌換清單的動作
      this.$emit("addToCart", { id: id, index: index, setId: setId });
    },
    // 點擊卡片跳轉到課程介紹
    goToCoursePage() {
      // this.$router.push(`product-info?pid=${this.info.id}`);
      // 開啟新視窗，非直接在原畫面跳轉
      let routeUrl = this.$router.resolve({
        path: `product-info`,
        query: { pid: this.info.id },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
});
