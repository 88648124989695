<template>
  <!-- 電腦版，768px以上 -->
  <div class="course-card d-flex" v-if="courseInfo.enable == true">
    <!-- 課程卡片內容顯示 -->
    <span
      class="course-will-be-discontinued"
      v-show="courseInfo.willBeDiscontinued != undefined"
      >再過{{ courseInfo.willBeDiscontinued }}天即將下架</span
    >
    <!-- 課程圖片 -->
    <!-- <div>
            <img v-bind:src="courseInfo.picture" alt="" class="course-card-img">
        </div> -->

    <div
      :style="{ backgroundImage: `url(${courseInfo.picture})` }"
      class="course-card-img"
      @click="goToCoursePage"
    ></div>

    <div class="product-info-container">
      <div class="product-info">
        <a
          href="javascript:void(0);"
          style="text-decoration: none; cursor: pointer"
          @click="goToCoursePage"
        >
          <p class="product-name">
            {{ courseInfo.productName }}
          </p>
          <p class="product-description">
            {{ courseInfo.productDescription }}
          </p>
          <p class="video-date">
            {{ courseInfo.videoDate }}
          </p>
        </a>
      </div>

      <div class="favorite-ccoin-container d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <!-- 收藏符號 -->
          <a
            href="javascript:void(0)"
            class="course-favorite"
            @click="removeItem"
          >
            <i class="bi bi-suit-heart-fill" style="color: #fc2c2c"></i>
          </a>
          <a
            href="javascript:void(0);"
            class="add-exchange"
            @click="exchangeItemAdd(courseInfo.id, courseInfo.setId)"
          >
            加入兌換
          </a>
        </div>
        <div
          class="price-sold-container d-flex flex-column justify-content-between"
        >
          <p class="product-price" style="margin: 0">
            <span class="price">{{ courseInfo.price }}</span>
            <span class="unit">C幣</span>
          </p>

          <p class="product-sold-quantity" style="margin: 0">
            <span class="sold-quantity"
              >已售出 {{ courseInfo.soldQuantity }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- 手機板，767px以下 -->
  <div
    class="course-card-mobile d-flex flex-column"
    v-if="courseInfo.enable == true"
  >
    <!-- 課程卡片內容顯示 -->
    <span
      class="course-will-be-discontinued"
      v-show="courseInfo.willBeDiscontinued != undefined"
      >再過{{ courseInfo.willBeDiscontinued }}天即將下架</span
    >

    <div class="product-info-container">
      <div class="product-info d-flex">
        <!-- 課程圖片 -->
        <!-- <div>
                    <img v-bind:src="courseInfo.picture" alt="" class="course-card-img">
                </div> -->
        <div
          :style="{ backgroundImage: `url(${courseInfo.picture})` }"
          class="course-card-img"
          @click="goToCoursePage"
        ></div>
        <a
          href="javascript:void(0);"
          style="text-decoration: none; margin-left: 8px"
          @click="goToCoursePage"
        >
          <p class="product-name">
            {{ courseInfo.productName }}
          </p>
          <p class="product-description">
            {{ courseInfo.productDescription }}
          </p>
          <p class="video-date">
            {{ courseInfo.videoDate }}
          </p>
        </a>
      </div>

      <div class="favorite-ccoin-container d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <!-- 收藏符號 -->
          <a
            href="javascript:void(0);"
            class="course-favorite"
            @click="removeItem"
          >
            <!-- <img class="course-favorite-icon" src="@/assets/icons/shop/icon_favorit_black.svg" alt=""> -->
            <i class="bi bi-suit-heart-fill" style="color: #fc2c2c"></i>
          </a>
          <a
            href="javascript:void(0);"
            class="add-exchange"
            @click="exchangeItemAdd(courseInfo.id, courseInfo.setId)"
          >
            加入兌換
          </a>
        </div>
        <div
          class="price-sold-container d-flex flex-column justify-content-between"
        >
          <p class="product-price" style="margin: 0">
            <span class="price">{{ courseInfo.price }}</span>
            <span class="unit">C幣</span>
          </p>

          <p class="product-sold-quantity">
            <span class="sold-quantity"
              >已售出 {{ courseInfo.soldQuantity }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- 已經下架的HTML跟CSS由於跟未下架時差不多，因此直接沿用，差異的部分直接在標籤上用style覆蓋 -->
  <!-- 電腦版已經下架，768px以上 -->
  <div
    class="course-card d-flex"
    v-if="courseInfo.enable == false"
    style="background-color: #cccccc"
  >
    <!-- 課程卡片內容顯示 -->
    <span
      class="course-will-be-discontinued"
      style="background-color: #777777; color: white"
      >商品已下架</span
    >
    <!-- 課程圖片 -->
    <!-- <div>
            <img v-bind:src="courseInfo.picture" alt="" class="course-card-img">
        </div> -->
    <div
      :style="{ backgroundImage: `url(${courseInfo.picture})` }"
      class="course-card-img"
    ></div>

    <div class="product-info-container">
      <div class="product-info">
        <a style="text-decoration: none">
          <p class="product-name">
            {{ courseInfo.productName }}
          </p>
          <p class="product-description">
            {{ courseInfo.productDescription }}
          </p>
          <p class="video-date">
            {{ courseInfo.videoDate }}
          </p>
        </a>
      </div>

      <div class="favorite-ccoin-container d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <a
            href="javascript:void(0);"
            style="text-decoration: none; margin-left: 20px; color: black"
            @click="removeItem"
          >
            移除
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- 手機板已經下架，767px以下 -->
  <div
    class="course-card-mobile d-flex flex-column"
    v-if="courseInfo.enable == false"
    style="background-color: #cccccc"
  >
    <!-- 課程卡片內容顯示 -->
    <span
      class="course-will-be-discontinued"
      style="background-color: #777777; color: white"
      >商品已下架</span
    >

    <div class="product-info-container">
      <div class="product-info d-flex">
        <!-- 課程圖片 -->
        <!-- <div>
                    <img v-bind:src="courseInfo.picture" alt="" class="course-card-img">
                </div> -->
        <div
          :style="{ backgroundImage: `url(${courseInfo.picture})` }"
          class="course-card-img"
        ></div>
        <a
          href="javascript:void(0);"
          style="text-decoration: none; margin-left: 8px"
        >
          <p class="product-name">
            {{ courseInfo.productName }}
          </p>
          <p class="product-description">
            {{ courseInfo.productDescription }}
          </p>
          <p class="video-date">
            {{ courseInfo.videoDate }}
          </p>
        </a>
      </div>

      <div class="favorite-ccoin-container d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <a
            href="javascript:void(0);"
            class="add-exchange"
            style="text-decoration: none; margin-left: 20px; color: black"
            @click="removeItem"
          >
            移除
          </a>
        </div>
        <div
          class="price-sold-container d-flex flex-column justify-content-between"
        >
          <p class="product-price" style="margin: 0">
            <span class="price">{{ courseInfo.price }}</span>
            <span class="unit">C幣</span>
          </p>

          <p class="product-sold-quantity" style="margin: 0">
            <span class="sold-quantity"
              >已售出 {{ courseInfo.soldQuantity }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";

// axios環境變數宣告
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}`;

export default defineComponent({
  name: "FavoriteListCourseCard",
  props: ["info"],
  emits: ["openModal", "removeItem", "updateExchangeList"],
  data() {
    return {
      courseInfo: {
        enable: false,
        courseFeature: "1對1視訊課程",
        courseLevel: "2~3",
        courseDiscount: "25",
        courseBought: false,
        willBeDiscontinued: "8",
        productName: " 英、日1對1 - 60分鐘 英、日",
        productDescription: "全程1對1教學，高效學習方案。1920",
        videoDate: "2022/6/22",
        courseOriginalPrice: "99999",
        price: "9001920",
        soldQuantity: "13221920",
        favoriteStatus: true,
        classification: [],
        shoppingCartStatus: false,
        type: -1,
      },
    };
  },
  methods: {
    openModal() {
      this.$emit("openModal");
    },
    removeItem() {
      this.$emit("removeItem", {
        id: this.courseInfo.id,
        classification: this.courseInfo.classification,
        type: this.courseInfo.type,
        setId: this.courseInfo.setId,
      });
    },
    // 加入兌換清單
    // 新增一筆商品成功後就會重新打一次取得兌換清單資料的API，讓內容可以進行更新
    exchangeItemAdd(id, setId) {
      if (this.courseInfo.shoppingCartStatus != true) {
        // console.log('加入兌換清單');
        let url = "";
        if (setId === null) {
          url = `${serverUrl}learn_shop/cart/${id}`;
        } else {
          url = `${serverUrl}learn_shop/cart/${setId}/${id}`;
        }
        axios
          .patch(
            url,
            {
              add: true,
            },
            {
              headers: {
                Authorization: `Bearer ${loginToken}`,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.courseInfo.shoppingCartStatus = true;
              // API成功加入商品後，將type以及名稱傳出去，在外面的兌換清單推入商品
              this.$emit("updateExchangeList", {
                type: this.courseInfo.type,
                productName: this.courseInfo.productName,
              });
              // console.log(`${id} : 商品加入兌換清單成功`);
            }
          })
          .catch((error) => {
            console.error(`錯誤： ${error}`);
          });
      } else {
        this.$emit("updateExchangeList");
      }
    },
    // 跳轉到課程介紹頁面
    goToCoursePage() {
      let hideModal = () => {
        // 抓取class = "modal-backdrop"的物件，該物件是透過bootstrap Modal彈出時會產生的灰色背景
        let modalBackdrop = document.querySelector(".modal-backdrop");

        // 如果沒有灰色背景，則直接return ，後續的程式就不需要執行
        if (modalBackdrop == null) {
          return;
        } else {
          // 如果有灰色背景，將其移除
          modalBackdrop.remove();
        }

        // Modal產生時，整體畫面會被加上padding以及將滾動條禁用，如果單純關閉上面的灰色背景
        // 會發生無法操作滾動條的現象
        let body = document.getElementsByTagName("body");
        // 將被Modal加在body標籤上的css移除
        // 這裡需要訪問[0]的原因是，document.getElementsByTagName會取出整個數組，而非單一物件
        // 因此需要針對訪問，才可以取到我們要的body標籤，即使body在整個網頁內是唯一的存在
        body[0].style.overflow = "";
        body[0].style.paddingRight = "";
        // 判斷body目前有的class ，如果目前沒有任何class則不需要進入執行迴圈
        if (body[0].classList.length > 0) {
          for (let i = 0; i < body[0].classList.length; i++) {
            // 當class有"modal-open"時，將其移除
            if (body[0].classList[i] == "modal-open") {
              body[0].classList.remove("modal-open");
              break;
            }
          }
        }
        // location.reload();
      };
      hideModal();
      // 開啟新視窗，非直接在原畫面跳轉
      let routeUrl = this.$router.resolve({
        path: `product-info`,
        query: { pid: this.courseInfo.id },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    // console.log("收藏卡片內容是");
    // console.log(this.info);
    this.courseInfo = this.info;
    // console.log(this.info);
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss"; // 全域 CSS

// 大於 768px 時，套用以下 css
@media screen and (min-width: 768px) {
  .course-card-mobile {
    display: none !important;
  }

  .course-card:hover {
    border: 1px solid #b2ebf2;

    box-shadow: 0 0 15px #cccccc;

    .price {
      color: #25a2a6;
    }
  }

  .course-card {
    max-width: 100%;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    margin-bottom: 16px;

    .course-card-img {
      width: 145.5px;
      height: 110px;
      border-radius: 10px;
      cursor: pointer;
    }

    .course-will-be-discontinued {
      position: absolute;
      right: 0;
      bottom: 54px;
      color: white;
      padding: 4px 16px;
      background-color: #fc2c2c;
      font-size: 12px;
      line-height: 125%;
    }

    .product-info-container {
      margin-left: 8px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .product-info {
        height: 60%;

        .product-name {
          font-size: 16px;
          color: #777777;
          line-height: 20px;
          font-weight: 500;
          color: #2d2d2d;
        }

        .product-description {
          margin-top: -10px;
          margin-bottom: 0;
          font-size: 12px;
          color: #777777;
          line-height: 15px;
          font-weight: 400;
        }

        .video-date {
          margin-top: 2px;
          margin-bottom: 0;
          font-size: 12px;
          color: #777777;
          line-height: 15px;
          font-weight: 400;
        }
      }

      .favorite-ccoin-container {
        width: 100%;
        height: 40%;
        border-top: 1px solid #ececec;

        .course-favorite:hover {
          background: rgba(246, 246, 246, 1);
        }

        .course-favorite {
          padding: 9.5px;
          border-radius: 100px;
          // 這邊設置line-height與圖示高度相同的原因是，這樣子容器與圖片才會同心，否則在padding產出陰影效果後，會發現不是正圓
          line-height: 15.63px;
          margin-left: 18.76px;

          .course-favorite-icon {
            opacity: 0.8;
            width: 18px;
            height: 15.63px;
          }
        }

        .add-exchange:hover {
          // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
          background: rgba(246, 246, 246, 1);
        }

        .add-exchange {
          text-decoration: none;
          font-size: 14px;
          padding: 7px 12px;
          margin-left: 19.24px;
          color: #25a2a6;
          border-radius: 100px;
          font-weight: 500;

          // display: inline-block;
          // border: 1px solid red;
        }

        .price-sold-container {
          // border: 1px solid red;

          .product-price {
            text-align: right;

            .price {
              font-size: 18px;
              // border: 1px solid green;
              vertical-align: middle;
              font-weight: 500;
            }

            .unit {
              margin-left: 4px;
              font-size: 14px;
              // border: 1px solid gray;
              vertical-align: middle;
              color: #777777;
              font-weight: 400;
            }
          }

          .product-sold-quantity {
            margin-bottom: 6px;
            line-height: 15.8px;

            .sold-quantity {
              font-size: 12px;
              color: #777777;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

// 遊覽區域寬度範圍在415px~767px，則下方CSS描述會立即被套用：
@media screen and (max-width: 767px) {
  .course-card {
    display: none !important;
  }

  .course-card-mobile:hover {
    border: 1px solid #54dad1;

    box-shadow: 0 0 15px #cccccc;

    .price {
      color: #25a2a6;
    }
  }

  .course-card-mobile {
    border: 1px solid #cccccc;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 2px;
    position: relative;
    margin-bottom: 16px;
    display: flex;

    .course-card-img {
      width: 80px;
      height: 80px;
      min-width: 80px;
      border-radius: 10px;
    }

    .course-will-be-discontinued {
      position: absolute;
      right: 0;
      bottom: 48px;
      color: white;
      padding: 4px 16px;
      background-color: #fc2c2c;
      font-size: 12px;
      line-height: 125%;
    }

    .product-info-container {
      .product-info {
        padding: 8px;

        .product-name {
          font-size: 16px;
          color: #777777;
          line-height: 20px;
          font-weight: 500;
          color: #2d2d2d;
        }

        .product-description {
          margin-top: -10px;
          margin-bottom: 0;
          font-size: 12px;
          color: #777777;
          line-height: 15px;
          font-weight: 400;
        }

        .video-date {
          margin-top: 2px;
          margin-bottom: 0;
          font-size: 12px;
          color: #777777;
          line-height: 15px;
          font-weight: 400;
        }
      }

      .favorite-ccoin-container {
        width: 100%;
        height: 40%;
        border-top: 1px solid #ececec;
        padding-right: 10px;

        .course-favorite:hover {
          background: rgba(246, 246, 246, 1);
        }

        .course-favorite {
          padding: 9.5px;
          border-radius: 100px;
          // 這邊設置line-height與圖示高度相同的原因是，這樣子容器與圖片才會同心，否則在padding產出陰影效果後，會發現不是正圓
          line-height: 15.63px;
          margin-left: 18.76px;

          .course-favorite-icon {
            opacity: 0.8;
            width: 18px;
            height: 15.63px;
          }
        }

        .add-exchange:hover {
          background: rgba(246, 246, 246, 1);
        }

        .add-exchange {
          text-decoration: none;
          font-size: 14px;
          padding: 7px 12px;
          // margin-left: 19.24px;
          color: #25a2a6;
          border-radius: 100px;
          font-weight: 500;
        }

        .price-sold-container {
          // border: 1px solid red;

          .product-price {
            text-align: right;

            .price {
              font-size: 18px;
              // border: 1px solid green;
              vertical-align: middle;
              font-weight: 500;
            }

            .unit {
              margin-left: 4px;
              font-size: 14px;
              // border: 1px solid gray;
              vertical-align: middle;
              color: #777777;
              font-weight: 400;
            }
          }

          .product-sold-quantity {
            margin-bottom: 6px;
            line-height: 15.8px;

            .sold-quantity {
              font-size: 12px;
              color: #777777;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

// 其他固定CSS
.course-card-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
}
</style>
