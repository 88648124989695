
import { defineComponent } from "vue";

export default defineComponent({
  name: "OnlineShop-What-Is-AI-Modal",
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    stopAllYouTubeVideos() {
      var iframes = document.querySelectorAll("iframe");
      Array.prototype.forEach.call(iframes, (iframe) => {
        iframe.contentWindow.postMessage(
          JSON.stringify({ event: "command", func: "stopVideo" }),
          "*"
        );
      });
    },
  },
});
