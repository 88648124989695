<template>
  <!-- 確認付款 Modal modal fade -->
  <div
    class="modal"
    id="weekly-class-filter-page-modal"
    tabindex="-1"
    aria-labelledby="weekly-class-filter-page-modal-label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header d-flex flex-column title-search-container">
          <!-- 主視覺圖片 -->
          <div class="title">進階搜尋</div>
          <img
            class="close-btn"
            data-bs-dismiss="modal"
            src="@/assets/icons/shop/icon_closeFilter_black_btn.svg"
            alt="close btn icon"
          />
          <!-- 課程篩選 -->
          <div class="search-container d-flex justify-content-between">
            <a
              href="javascript:void(0);"
              id=""
              class="research d-flex align-items-center"
              @click="cleanSearch"
            >
              <img
                src="@/assets/icons/shop/icon_research.svg"
                alt=""
                class="research-icon"
              />
              <span>清除重置</span>
            </a>
            <button class="search" @click="startSearch()">開始搜尋</button>
          </div>
        </div>
        <!-- Modal Body -->
        <div class="modal-body">
          <!-- 上架時間 -->
          <div class="added-time-title-container">
            <span class="added-time-title">上架時間</span>
            <div
              class="added-time-container d-flex flex-wrap"
              style="position: relative"
            >
              <label
                ><input type="radio" name="added-time-mobile" value="7" />
                <div class="button">一周內</div></label
              >
              <label
                ><input type="radio" name="added-time-mobile" value="3" />
                <div class="button">3日內</div></label
              >
              <!-- <label><input type="radio" name="added-time-mobile" value="最新上架" /><div class="button">最新上架</div></label> -->
              <label
                ><input type="radio" name="added-time-mobile" value="14" />
                <div class="button">2周內</div></label
              >
              <!-- <label><input type="radio" name="added-time-mobile" value="即將下架" /><div class="button">即將下架</div></label> -->
              <label>
                <input
                  type="radio"
                  id="customTime"
                  name="added-time-mobile"
                  value="自訂日期"
                  @click="customTime"
                />
                <div class="button">自訂日期</div>
              </label>
              <DatePicker
                v-if="datePickerShow"
                v-model="date"
                :value="null"
                is-range
                class="datePicker"
              />
            </div>
          </div>

          <!-- 課程類型 -->
          <div class="language-tab-type-container">
            <span class="language-tab-title">課程類型</span>
            <div class="language-tab-container d-flex flex-wrap">
              <div
                v-for="(subjectInfo, index) in allowSubjects"
                :key="index"
                class="button"
                :class="{
                  'disable-button': !isEnableSubjectBtn(subjectInfo),
                  'active-button': isActiveSubjectBtn(subjectInfo),
                }"
                @click="() => clickSubjectBtn(subjectInfo)"
              >
                {{ subjectInfo.chinese }}
              </div>
            </div>
          </div>

          <!-- 課程等級 -->
          <div class="class-level-title-container">
            <span class="class-level-title">課程等級</span>
            <div class="class-level-container d-flex flex-wrap">
              <div
                v-show="levels.length > 0"
                v-for="(levelInfo, index) in levels"
                :key="index"
                class="button"
                :class="{ 'active-button': isActiveLevelBtn(levelInfo) }"
                @click="() => clickLevelBtn(levelInfo.value)"
              >
                {{ levelInfo.label }}
              </div>
            </div>
          </div>

          <!-- 授課講師 -->
          <div class="lecturer-title-container">
            <span class="lecturer-title">授課講師</span>
            <div class="lecturer-container d-flex flex-wrap">
              <div
                v-for="(lecturerInfo, index) in allLecturerList"
                :key="index"
                :class="{
                  'lecturer-button': isEnableLecturerBtn(lecturerInfo),
                  'lecturer-button disable-button':
                    !isEnableLecturerBtn(lecturerInfo),
                  'lecturer-button active-button':
                    isActiveLecturerBtn(lecturerInfo),
                }"
                @click="clickLecturerBtn(lecturerInfo)"
              >
                <img
                  class="lecturer-picture"
                  v-bind:src="lecturerInfo.avatar"
                />
                {{ lecturerInfo.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, Prop, PropType } from "vue";
import mixins from "@/mixins/index";
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";

export default defineComponent({
  name: "OnlineShop-weekly-class-filter-page-Modal",
  mixins: [mixins],
  components: {
    DatePicker,
  },
  props: ["allSubjects", "allLecturerList", "categoryLevelTypes"],
  data() {
    return {
      date: new Date(),
      datePickerShow: false,
      lecturerList: this.allLecturerList, // 取得老師名單
      addedTime: [],
      selectSubjects: [], // 資料格式訂為 array，但目前只使用第一個值
      selectLevels: [], // 資料格式訂為 array，但目前只使用第一個值
      selectLecturers: [], // 資料格式訂為 array，但目前只使用第一個值
      executed: false,
      categorySubjects: [],
    };
  },

  methods: {
    // 是否 subject 的按鈕是可以點選
    // 如果當前有選擇 lecturer，只有 lecturer 有教授該 subject 的項目可選
    // 沒有選擇 lecturer 則都可以選擇
    isEnableSubjectBtn(subjectInfo) {
      if (this.selectLecturers[0]) {
        const selectedLecturerInfo = this.allLecturerList.find((item) => {
          return item.id === this.selectLecturers[0];
        });

        if (selectedLecturerInfo) {
          return selectedLecturerInfo.subject.includes(subjectInfo.subject);
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 判斷是否為當前所選的 subject
    isActiveSubjectBtn(subjectInfo) {
      return this.selectSubjects.includes(subjectInfo.subject);
    },
    clickSubjectBtn(newSubjectInfo) {
      // 僅有允許的按鈕可以更改 selectSubjects 的值
      if (this.isEnableSubjectBtn(newSubjectInfo)) {
        // 找出在 selectedSubject 的 Subject 資訊
        const selectSubjectInfo = this.allowSubjects.find((item) => {
          return item.subject === this.selectSubjects[0];
        });

        // 更改 subject 時，判斷是否要清除 level
        // 比對之前選擇的 subject 與要更新的 subject，是否為相同的 category
        // 不一樣清除則 level
        if (
          !selectSubjectInfo ||
          selectSubjectInfo.category !== newSubjectInfo.category
        ) {
          this.selectLevels = [];
        }

        // 在前面的判斷結束後才將新的 subject 放到 selectSubjects
        this.selectSubjects = [newSubjectInfo.subject];
      }
    },
    // 判斷是否為當前所選的 level
    isActiveLevelBtn(levelInfo) {
      return levelInfo.value === this.selectLevels[0];
    },
    clickLevelBtn(level) {
      this.selectLevels = [level];
    },
    // 是否 lecturer 的按鈕是可以點選
    // 如果當前有選擇 subject，只 lecturer 有教授該 subject 的項目可選
    // 沒有選 subject 則都都可以選
    isEnableLecturerBtn(lecturerInfo) {
      if (this.selectSubjects.length > 0) {
        return lecturerInfo.subject.includes(this.selectSubjects[0]);
      } else {
        return true;
      }
    },
    // 判斷是否為當前所選的 lecturer
    isActiveLecturerBtn(lecturerInfo) {
      const findLecturer = this.selectLecturers.find((lecturerId) => {
        return lecturerId === lecturerInfo.id;
      });

      return findLecturer ? true : false;
    },
    clickLecturerBtn(lecturerInfo) {
      // 僅有允許的按鈕可以更改 selectLecturers 的值
      if (this.isEnableLecturerBtn(lecturerInfo)) {
        this.selectLecturers = [lecturerInfo.id];
      }
    },
    dateCalculate(days) {
      let nowDate = new Date();
      let targetDate = new Date();
      targetDate = targetDate.setDate(targetDate.getDate() + days);
      targetDate = new Date(targetDate);

      let startDateDate = nowDate.getDate().toString();
      let endDateDate = targetDate.getDate().toString();
      let startDateMonth = (nowDate.getMonth() + 1).toString();
      let endDateMonth = (targetDate.getMonth() + 1).toString();

      if (startDateMonth.length == 1) {
        startDateMonth = "0" + startDateMonth;
      }
      if (endDateMonth.length == 1) {
        endDateMonth = "0" + endDateMonth;
      }
      if (startDateDate.length == 1) {
        startDateDate = "0" + startDateDate;
      }
      if (endDateDate.length == 1) {
        endDateDate = "0" + endDateDate;
      }
      let startDate = `${nowDate.getFullYear()}-${startDateMonth}-${startDateDate}`;
      let endDate = `${targetDate.getFullYear()}-${endDateMonth}-${endDateDate}`;

      return [startDate, endDate];
    },
    cleanSearch() {
      let addedTimeInput = document.querySelectorAll(
        'input[name="added-time-mobile"]'
      );
      for (let i = 0; i < addedTimeInput.length; i++) {
        addedTimeInput[i].checked = false;
      }
      this.date = null;
      this.datePickerShow = false;
      this.addedTime = [];

      this.selectSubjects = [];
      this.selectLevels = [];
      this.selectLecturers = [];
    },
    startSearch() {
      this.$emit("weeklyClassMobileFilter", {
        addedTime: this.addedTime,
        language: this.selectSubjects, // 目前 api 沒有改 property name
        level: this.selectLevels,
        lecturer: this.selectLecturers,
      });
    },
    customTime() {
      let target = document.getElementById("customTime");
      this.datePickerShow = !this.datePickerShow;
      target.checked = this.datePickerShow;
    },
  },
  watch: {
    allSubjects: function () {
      this.categorySubjects = this.allSubjects;
    },
    date: function () {
      if (this.date != null) {
        // 起始日期
        let startYear = this.date.start.getFullYear().toString();
        let startMonth = (this.date.start.getMonth() + 1).toString();
        if (startMonth.length == 1) {
          startMonth = "0" + startMonth;
        }
        let startDate = this.date.start.getDate().toString();
        if (startDate.length == 1) {
          startDate = "0" + startDate;
        }

        // 結束日期
        let endYear = this.date.end.getFullYear().toString();
        let endMonth = (this.date.end.getMonth() + 1).toString();
        if (endMonth.length == 1) {
          endMonth = "0" + endMonth;
        }
        let endDate = this.date.end.getDate().toString();
        if (endDate.length == 1) {
          endDate = "0" + endDate;
        }

        let start = `${startYear}-${startMonth}-${startDate}`;
        let end = `${endYear}-${endMonth}-${endDate}`;
        this.addedTime = [start, end];

        let target = document.getElementById("customTime");
        this.datePickerShow = false;
      } else {
        this.addedTime = [];
      }
    },
  },
  mounted() {
    // 監控上架時間選項 added-time-mobile
    let addedTimeInput = document.querySelectorAll(
      'input[name="added-time-mobile"]'
    );
    for (let i = 0; i < addedTimeInput.length - 1; i++) {
      addedTimeInput[i].addEventListener("click", (event) => {
        if (addedTimeInput[i].checked) {
          this.addedTime = [];
          let date = this.dateCalculate(Number(addedTimeInput[i].value));
          this.addedTime = date;
          this.datePickerShow = false;
        }
      });
    }

    let datePicker = document.getElementById("customTime");
    datePicker.addEventListener("click", () => {
      if (datePicker.checked == false) {
        this.addedTime = [];
      }
    });

    // 監控課程等級 weekly-class-level-mobile
    let levelInput = document.querySelectorAll(
      'input[name="weekly-class-level-mobile"]'
    );
    for (let i = 0; i < levelInput.length; i++) {
      levelInput[i].addEventListener("change", (event) => {
        if (levelInput[i].checked) {
          this.selectLevels = [];
          this.selectLevels.push(levelInput[i].value);
        }
      });
    }
  },
  computed: {
    // 將 category -> subject 雙層的資料轉成單層的 subject 並放入 category 的值
    allowSubjects() {
      const result = this.categorySubjects.reduce((prev, categoryInfo) => {
        const { subjects } = categoryInfo;
        return [
          ...prev,
          ...subjects.map((subjectInfo) => {
            return { ...subjectInfo, category: categoryInfo.category };
          }),
        ];
      }, []);
      return result;
    },
    levels() {
      // 沒有選擇 subject，不顯示 level
      if (this.selectSubjects.length === 0) {
        return [];
      }
      const selectSubjectInfo = this.allowSubjects.find((subjectInfo) => {
        return subjectInfo.subject === this.selectSubjects[0];
      });
      // 找不到選擇的 subject 資訊，不顯示 level
      if (!selectSubjectInfo) {
        return [];
      }
      return this.categoryLevelTypes[
        this.categorySubjectMap(
          selectSubjectInfo.category,
          selectSubjectInfo.subject
        )
      ];
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss"; // 全域 CSS

// MIXINS
@mixin set-font($color, $size, $weight) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

@mixin set-size($width, $height) {
  width: $width;
  height: $height;
}

// 大於 320px 時，套用以下 css
@media screen and (min-width: 320px) {
  .modal-dialog {
    max-width: 560px;

    .modal-content {
      border: none;

      // 標題
      .modal-header {
        border: none;
        padding: 20.5px 16px 15px 16px;
        background-color: #f2f5f5;
        // opacity: 0.5;

        .title {
          width: 100%;
          font-size: 20px;
          color: #5e5d5d;
        }

        .search-container {
          margin-top: 37.5px;
          font-size: 14px;
          width: 100%;

          .research {
            text-decoration: none;

            .research-icon {
              width: 13.33px;
              height: 13.33px;
            }

            span {
              margin-left: 10px;
              color: #5e5d5d;
            }
          }

          .search {
            width: 90px;
            height: 32px;
            color: white;
            border-radius: 100px;
            background-color: #25a2a6;
            border: none;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .modal-body {
        height: 476px;
        overflow-y: auto;
        // margin-top: 20px;
        padding: 20px 16px 0px;
        // opacity: 0.5;

        // 活動標籤
        .added-time-title-container {
          .added-time-title {
            font-size: 14px;
            color: #5e5d5d;
          }

          .added-time-container {
            margin-top: 16px;

            a {
              text-decoration: none;
              color: #909090;
              font-size: 14px;
              border: 1px solid #909090;
              border-radius: 10px;
              padding: 6px 16px;
              margin-right: 10px;
              margin-bottom: 14px;
            }

            .added-time0 {
            }

            .added-time1 {
            }

            .added-time2 {
            }
          }
        }

        // 語言類型
        .language-tab-type-container {
          margin-top: 16px;

          .language-tab-title {
            font-size: 14px;
            color: #5e5d5d;
          }

          .language-tab-container {
            margin-top: 16px;

            a {
              margin-bottom: 14px;
              text-decoration: none;
              color: #909090;
              font-size: 14px;
              border: 1px solid #909090;
              border-radius: 10px;
              padding: 6px 16px;
              margin-right: 10px;
            }

            .language-tab0 {
            }

            .language-tab1 {
            }

            .language-tab2 {
            }
          }

          .language-tab-special-note {
            color: red;
          }
        }

        // 課程等級
        .class-level-title-container {
          margin-top: 16px;

          .class-level-title {
            font-size: 14px;
            color: #5e5d5d;
          }

          .class-level-container {
            margin-top: 16px;

            a {
              margin-bottom: 14px;
              text-decoration: none;
              color: #909090;
              font-size: 14px;
              border: 1px solid #909090;
              border-radius: 10px;
              padding: 6px 16px;
              margin-right: 10px;
            }

            .class-level0 {
            }

            .class-level1 {
            }

            .class-level2 {
            }
          }

          .class-level-special-note {
            color: red;
          }
        }

        // 授課講師
        .lecturer-title-container {
          .lecturer-title {
            font-size: 14px;
            color: #5e5d5d;
          }

          .lecturer-container {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

// datePicker位置調整
@media screen and (min-width: 403px) {
  .datePicker {
    position: absolute;
    z-index: 10;
    width: 300px;
    margin-top: 50px;
    right: 0;
  }
}

@media screen and (max-width: 402px) {
  .datePicker {
    position: absolute;
    z-index: 10;
    width: 300px;
    margin-top: 100px;
    left: 0;
  }
}

// 其他固定CSS
.close-btn {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 26px;
  right: 21px;
  cursor: pointer;
}

// 多重選項的css
input[type="checkbox"] {
  display: none;
}

input[type="radio"] {
  display: none;
}

input:checked + .button {
  background: #25a2a6;
  color: #fff;
}

.button {
  font-size: 14px;
  border: 1px solid #cccccc;
  display: inline-block;
  margin: 0 10px 14px 0;
  padding: 6px 16px;
  color: #909090;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 500;
  height: 30px;
  display: flex;
  align-items: center;
}

.button.active-button {
  background: #25a2a6;
  color: #fff;
}

.button.disable-button {
  border: 1px solid #f6f6f6;
  color: white;
  background-color: #cccccc;
  cursor: default;
}

// 講師的按鈕樣式
.lecturer-button {
  font-size: 12px;
  border: 1px solid #cccccc;
  display: inline-block;
  margin: 0 10px 14px 0;
  padding: 2.77px 12px 3.12px 2.42px;
  color: #909090;
  cursor: pointer;
  border-radius: 100px;
  font-weight: 500;
  height: 30px;
  display: flex;
  align-items: center;
}

.lecturer-button.disable-button {
  border: 1px solid #f6f6f6;
  color: white;
  background-color: #cccccc;
  cursor: default;
}

.lecturer-button.active-button {
  background: #25a2a6;
  color: #fff;
}

.lecturer-picture {
  border-radius: 100px;
  margin-right: 7.12px;
  width: 26.45px;
  height: 26.1px;
}

.button:hover {
}
</style>
