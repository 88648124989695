import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryCard = _resolveComponent("CategoryCard")!
  const _component_AltCategoryCard = _resolveComponent("AltCategoryCard")!
  const _component_Shop = _resolveComponent("Shop")!
  const _component_ExchangeList = _resolveComponent("ExchangeList")!
  const _component_CCoin = _resolveComponent("CCoin")!
  const _component_PurchaseCCoin = _resolveComponent("PurchaseCCoin")!
  const _component_History = _resolveComponent("History")!
  const _component_Template14 = _resolveComponent("Template14")!

  return (_openBlock(), _createBlock(_component_Template14, null, {
    "left-column": _withCtx(() => [
      _createVNode(_component_CategoryCard, {
        active: _ctx.activeCategory,
        onChangeCategory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeCategory($event)))
      }, null, 8, ["active"])
    ]),
    "right-column": _withCtx(() => [
      _createVNode(_component_AltCategoryCard, {
        active: _ctx.activeCategory,
        onChangeCategory: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeCategory($event)))
      }, null, 8, ["active"]),
      (_ctx.activeCategory === 'shop')
        ? (_openBlock(), _createBlock(_component_Shop, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.$route.query.category === 'exchangeList')
        ? (_openBlock(), _createBlock(_component_ExchangeList, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.activeCategory === 'ccoin' && _ctx.step === 0)
        ? (_openBlock(), _createBlock(_component_CCoin, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.activeCategory === 'ccoin' && _ctx.step !== 0)
        ? (_openBlock(), _createBlock(_component_PurchaseCCoin, {
            key: 3,
            step: _ctx.step,
            onChangeStep: _cache[2] || (_cache[2] = ($event: any) => (this.step = $event))
          }, null, 8, ["step"]))
        : _createCommentVNode("", true),
      (_ctx.activeCategory === 'history')
        ? (_openBlock(), _createBlock(_component_History, { key: 4 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}