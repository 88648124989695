
import { defineComponent, PropType } from "vue";
import mixins from "@/mixins/index";
import Bus from "@/utils/Bus";

import axios from "axios";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface info {
  name: string;
  email: string;
  phone: string;
  address: string;
  companyId: string;
  companyName: string;
  handleInvoiceType: string;
  handleInvoiceValue: string;
}

interface formData {
  itemName: string;
  coinId: number;
  amount: number;
  handlingCharge: boolean;
  paymentType: string;
  invoiceType: string;
  name: string;
  email: string;
  carrierType: string;
  code: string;
  taxID: number;
  phone: string;
  company: string;
  address: string;
}

interface dataStructure {
  ccoin: number;
  price: number;
  custom: boolean;
  features: {
    free: boolean;
    ai: number | null;
    weekly: number | null;
  };
  promote: string[];
  type: number;
  id: number;
  received: boolean | null;
}
export default defineComponent({
  name: "OnlineShop-Confirm-Payment-Modal-Sm",
  mixins: [mixins],
  props: {
    payment: {
      type: String,
      required: true,
    },
    invoice: {
      type: String,
      required: true,
    },
    infoType: {
      type: String,
      required: true,
    },
    modalInfo: {
      type: Object as PropType<info>,
      required: true,
    },
    itemData: {
      type: Object as () => dataStructure,
      required: true,
    },
  },
  data() {
    return {
      isPosting: false,
      showError: false,
      agreeCheckBox: false,
      paymentMap: {
        BARCODE: "超商條碼",
        Credit: "信用卡",
        ATM: "ATM虛擬帳號",
      } as { [key: string]: string },
      invoiceMap: {
        default: "儲存會員載具，中獎寄件通知",
        CDC: "自然人憑證條碼",
        carrier: "手機載具條碼",
        donate: "捐贈發票",
      } as { [key: string]: string },
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    agreeCheckBox() {
      this.showError = !this.agreeCheckBox;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    confirm() {
      this.showError = false;

      // 確認勾選條款
      const agreeToTerms = document.getElementById(
        "agree-to-terms-checkbox-sm"
      )! as HTMLInputElement;

      // console.log(agreeToTerms);
      // console.log(agreeToTerms.checked);

      if (agreeToTerms.checked) {
        let formData = {} as formData;

        // 購買品項名稱：C幣儲值(固定方案) || C幣儲值(自訂方案)
        formData.itemName =
          this.itemData.price < 10000
            ? "C幣儲值(固定方案)"
            : "C幣儲值(自訂方案)";

        // C幣ID 駿逸新增 2023/02/01
        formData.coinId = this.itemData.id;

        // 台幣金額(加上手續費後)
        formData.amount = this.itemData.price;

        // 是否需要手續費
        formData.handlingCharge =
          this.payment === "BARCODE" && this.itemData.price < 3000
            ? true
            : false;

        // 付款方式：Credit || ATM || BARCODE
        formData.paymentType = this.payment;

        // 發票類型：B2B || B2C
        formData.invoiceType = this.invoice;

        // 姓名
        formData.name = this.modalInfo.name;

        // 信箱
        formData.email = this.modalInfo.email;

        // 發票處理方式：default || CDC || carrier || donate || default
        if (this.invoice === "B2C")
          formData.carrierType = this.modalInfo.handleInvoiceType;

        // 載具條碼：CDC || carrier || donate
        if (
          this.invoice === "B2C" &&
          this.modalInfo.handleInvoiceType !== "default"
        )
          formData.code = this.modalInfo.handleInvoiceValue;

        // 統一編號
        if (this.invoice === "B2B")
          formData.taxID = parseInt(this.modalInfo.companyId);

        // 手機
        if (this.invoice === "B2B") formData.phone = this.modalInfo.phone;

        // 公司抬頭
        if (this.invoice === "B2B")
          formData.company = this.modalInfo.companyName;

        // 地址
        if (this.invoice === "B2B") formData.address = this.modalInfo.address;

        // console.log(formData);

        const config = {
          headers: {
            Authorization: loginToken,
          },
        };

        this.isPosting = true;
        axios
          .post(`${serverUrl}coin`, formData, config)
          .then((res) => {
            if (res.status == 200) {
              // console.log(res.data.data.mtNO);
              // console.log(res.data.data.html);

              this.isPosting = false;
              this.$emit("closeModal");

              // 將商品編號存在 LocalStorage
              const productId = res.data.data.mtNO;
              localStorage.setItem("mtNO", productId);

              // 導向綠界頁面
              const htmlString = res.data.data.html;

              let ret = htmlString.replace(
                '<script type="text/javascript">document.getElementById("ecpay-form").submit();</scr',
                ""
              );
              ret = ret.replace("ipt></body></html>", "");
              ret = ret.replace(
                '<!DOCTYPE html><html><head><meta charset="utf-8"></head><body>',
                ""
              );
              const testPage = document.getElementById(
                "test-page"
              ) as HTMLElement;
              testPage.innerHTML = ret;
              const ecpayForm = document.getElementById(
                "ecpay-form"
              )! as HTMLFormElement;
              ecpayForm.submit();

              this.isPosting = false;
              this.$emit("closeModal");
            } else {
              this.$emit("closeModal");
              this.$emit("openErrorModal");
              this.isPosting = false;
            }
          })
          .catch((err) => {
            this.$emit("closeModal");
            this.$emit("openErrorModal");
            this.isPosting = false;
          });
      } else {
        Bus.emit("openAlertModal", {
          type: "warning",
          title: ["Warning!"],
          content: "請勾選最下方的同意發票處理流程!",
          btnText: "關閉",
        });

        this.showError = true;
        this.isPosting = false;
      }
    },
  },
  emits: ["closeModal", "openErrorModal"],
});
