
import { defineComponent } from "vue";
import { Modal } from "bootstrap";

import mixins from "@/mixins/index";
import ConfirmPaymentModal from "@/components/OnlineShop/ConfirmPaymentModal.vue";
import ConfirmPaymentModalSm from "@/components/OnlineShop/ConfirmPaymentModalSm.vue";
import PaymentErrorModal from "@/components/OnlineShop/PaymentErrorModal.vue";
import TermsOfServiceModal from "@/components/OnlineShop/TermsOfServiceModal.vue";
import FloatingBlock from "@/components/OnlineShop/PurchaseStep2FloatingBlock.vue";

interface dataStructure {
  ccoin: number;
  price: number;
  custom: boolean;
  features: {
    free: boolean;
    ai: number | null;
    weekly: number | null;
  };
  promote: string[];
  type: number;
  id: number;
  received: boolean | null;
}

export default defineComponent({
  name: "OnlineShop-Step-2",
  components: {
    ConfirmPaymentModal,
    ConfirmPaymentModalSm,
    PaymentErrorModal,
    TermsOfServiceModal,
    FloatingBlock,
  },
  mixins: [mixins],
  data() {
    return {
      windowWidth: window.innerWidth,
      confirmModal: null as any,
      confirmModalSm: null as any,
      errorModal: null as any,
      termsModal: null as any,
      error: false,
      showError2C: [] as Array<string>,
      showError2B: [] as Array<string>,
      showErrorHandleInvoice: "",
      confirmModalInfo2C: {
        name: "",
        email: "",
        handleInvoiceType: "",
        handleInvoiceValue: "",
      },
      confirmModalInfo2B: {
        name: "",
        email: "",
        phone: "",
        address: "",
        companyId: "",
        companyName: "",
      },
      payments: [
        {
          name: "信用卡",
          value: "Credit",
        },
        {
          name: "ATM虛擬帳號",
          value: "ATM",
        },
        {
          name: "超商條碼",
          value: "BARCODE",
        },
      ],
      images: {
        Credit: ["mastercard", "jbc", "visa"],
        BARCODE: ["seven", "family", "ok", "hilife"],
      } as { [key: string]: Array<string> },
      notes: {
        Credit: [
          "付款流程將透過綠界科技ECPAY完成刷卡，資料傳輸均採用SSL憑證加密，保護您的資料安全。",
          "目前信用卡付款不提供分期付款的服務，敬請見諒。",
        ],
        ATM: [
          "付款流程將透過綠界科技ECPAY完成，您將得到一組虛擬帳號，請持代碼至實體ATM或網路銀行繳費。",
          "請於<strong>72小時內</strong>完成付款，逾期未付款系統將自動取消訂單，如仍欲進行儲值請重新下單。",
        ],
        BARCODE: [
          "請持紙本繳費單至四大超商(7-11、全家、OK、萊爾富)繳費。",
          "如您選擇的方案低於NT$3,000元，則平台將酌收NT$15元超商手續費，後續退款手續費將不進行退款。",
          "請於<strong>72小時內</strong>完成付款，逾期未付款系統將自動取消訂單，如仍欲進行儲值請重新下單。",
        ],
      } as { [key: string]: Array<string> },

      invoiceSelected: "B2C",
      invoices: [
        {
          name: "電子發票",
          value: "B2C",
        },
        {
          name: "統編發票",
          value: "B2B",
        },
      ],
      handleInvoiceSelected: "default",
      handleInvoices: [
        {
          name: "儲存會員載具，中獎寄件通知",
          value: "default",
        },
        {
          name: "自然人憑證條碼",
          value: "CDC",
        },
        {
          name: "手機載具條碼",
          value: "carrier",
        },
        {
          name: "捐贈發票",
          value: "donate",
        },
      ],
      handleInvoicesMap: {
        default: "儲存會員載具，中獎寄件通知",
        CDC: "自然人憑證條碼",
        carrier: "手機載具條碼",
        donate: "捐贈發票",
      } as { [key: string]: string },
      dropdown: false,
      donationSelected: "donate",
      donations: [
        {
          name: "財團法人中華民國兒童癌症基金會",
          value: "88888",
        },
        {
          name: "社團法人台灣ＦＯＣＵＳ家庭協會",
          value: "1314515",
        },
        {
          name: "財團法人無國界醫生基金會",
          value: "58813",
        },
        {
          name: "輸入捐贈碼",
          value: "donate",
        },
      ],
      promoteBg: [
        ["#26A69A"], // type 0
        ["#FF6767"], // type 1
        ["#FF8310", "#26A69A"], // type 2
        ["#FF8310", "#26A69A"], // type 3
        ["#FF8310", "#26A69A"], // type 99 這個永遠保持在最後面，如果有新的就往前插入
      ],
    };
  },
  computed: {
    colorType() {
      if (this.itemData.type !== 99) {
        return this.itemData.type;
      } else {
        return this.promoteBg.length - 1;
      }
    },
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    // 儲值方案台幣數值
    selected: {
      type: Number,
      required: true,
    },
    paymentSelected: {
      type: String,
      required: true,
    },
    itemData: {
      type: Object as () => dataStructure,
      required: true,
    },
  },
  methods: {
    errorMap(id: string) {
      const errorMap = {
        name: "聯絡姓名",
        email: "電子信箱",
        phone: "行動電話",
        address: "聯絡地址",
        "company-id": "統一編號",
        "company-name": "公司抬頭",
        CDC: "自然人憑證條碼",
        carrier: "手機載具條碼",
        donate: "捐贈碼",
      } as { [key: string]: string };
      return errorMap[id];
    },
    showErrorBorder(id: string) {
      const type = id.slice(0, 2);
      if ((type === "2C" ? this.showError2C : this.showError2B).includes(id)) {
        return "1px solid #FC2C2C";
      } else {
        return "1px solid #CCCCCC";
      }
    },
    hideErrorBorder(id: string, type: string) {
      switch (type) {
        case "2B": {
          if (this.showError2B.includes(id)) {
            this.showError2B.splice(this.showError2B.indexOf(id), 1);
          }
          break;
        }
        case "2C": {
          if (this.showError2C.includes(id)) {
            this.showError2C.splice(this.showError2C.indexOf(id), 1);
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    openModal(size: string) {
      this.showError2C = [];
      this.showError2B = [];
      this.showErrorHandleInvoice = "";

      if (this.invoiceSelected === "B2C") {
        // 無統編
        // 確認基本資料
        const checkItems = ["name", "email"];
        let errorItems = [] as Array<string>;
        checkItems.forEach((ele) => {
          const target = document.getElementById(
            `2C-input-${ele}`
          )! as HTMLInputElement;
          if (target !== null && !target.value) {
            errorItems.push(`2C-input-${ele}`);
          }
        });

        // 確認發票處理方式
        if (
          // 自然人憑證 or 手機條碼
          this.handleInvoiceSelected === "CDC" ||
          this.handleInvoiceSelected === "carrier"
        ) {
          const tempHandleInvoiceSelected = document.getElementById(
            `2C-input-${this.handleInvoiceSelected}`
          )! as HTMLInputElement;
          if (!tempHandleInvoiceSelected.value) {
            this.showErrorHandleInvoice = `${this.handleInvoiceSelected}`;
          } else {
            this.confirmModalInfo2C.handleInvoiceType = `${this.handleInvoiceSelected}`;
            this.confirmModalInfo2C.handleInvoiceValue =
              tempHandleInvoiceSelected.value;
          }
        } else if (this.handleInvoiceSelected === "donate") {
          // 捐贈發票
          if (this.donationSelected === "donate") {
            // 手動輸入捐贈碼
            const donationCode = document.getElementById(
              "2C-input-donation-code"
            )! as HTMLInputElement;
            if (!donationCode.value) {
              this.showErrorHandleInvoice = "donate";
            } else {
              this.confirmModalInfo2C.handleInvoiceType = "donate";
              this.confirmModalInfo2C.handleInvoiceValue = donationCode.value;
            }
          } else {
            // 勾選捐贈選項
            const checkboxs = document.getElementsByClassName(
              "donation-checkboxs"
            ) as HTMLCollectionOf<HTMLInputElement>;
            let checked = null;
            for (let index = 0; index < checkboxs.length; index++) {
              const checkbox = checkboxs[index];
              if (checkbox.checked) {
                checked = checkbox.name;
                this.donationSelected = checkbox.name;
              }
            }

            if (!checked) {
              this.showErrorHandleInvoice = "donate";
            } else {
              this.confirmModalInfo2C.handleInvoiceType = "donate";
              this.confirmModalInfo2C.handleInvoiceValue = checked;
            }
          }
        } else if (this.handleInvoiceSelected === "default") {
          this.confirmModalInfo2C.handleInvoiceType = "default";
          this.confirmModalInfo2C.handleInvoiceValue = "";
        }

        if (errorItems.length === 0 && this.showErrorHandleInvoice === "") {
          const name2C = document.getElementById(
            "2C-input-name"
          )! as HTMLInputElement;
          const email2C = document.getElementById(
            "2C-input-email"
          )! as HTMLInputElement;
          this.confirmModalInfo2C.name = name2C.value;
          this.confirmModalInfo2C.email = email2C.value;

          if (size === "lg") {
            this.confirmModal.show();
          } else {
            this.confirmModalSm.show();
          }
        } else {
          this.showError2C = errorItems;
        }

        // 手機版
        if (this.windowWidth < 768) {
          if (
            this.showError2C.length > 0 ||
            this.showErrorHandleInvoice.length > 0
          ) {
            document.getElementById("custom-toast-item")!.style.visibility =
              "visible";
            document.getElementById("custom-toast-item")!.style.opacity = "1";

            if (this.showError2C.length > 0) {
              document.getElementById(
                "custom-toast-item-text"
              )!.textContent = `${this.errorMap(
                this.showError2C[0].slice(9)
              )}為必填項目`;
            } else {
              document.getElementById(
                "custom-toast-item-text"
              )!.textContent = `${this.errorMap(
                this.showErrorHandleInvoice
              )}為必填項目`;
            }
          }
        }
      } else {
        // 有統編
        const checkItems = [
          "name",
          "email",
          "company-id",
          "phone",
          "company-name",
          "address",
        ];
        let errorItems = [] as Array<string>;
        checkItems.forEach((ele) => {
          const target = document.getElementById(
            `2B-input-${ele}`
          )! as HTMLInputElement;
          if (!target.value) {
            errorItems.push(`2B-input-${ele}`);
          }
        });

        if (errorItems.length === 0) {
          const name2B = document.getElementById(
            "2B-input-name"
          )! as HTMLInputElement;
          const email2B = document.getElementById(
            "2B-input-email"
          )! as HTMLInputElement;
          const phone2B = document.getElementById(
            "2B-input-phone"
          )! as HTMLInputElement;
          const address2B = document.getElementById(
            "2B-input-address"
          )! as HTMLInputElement;
          const companyId2B = document.getElementById(
            "2B-input-company-id"
          )! as HTMLInputElement;
          const companyName2B = document.getElementById(
            "2B-input-company-name"
          )! as HTMLInputElement;

          this.confirmModalInfo2B.name = name2B.value;
          this.confirmModalInfo2B.email = email2B.value;
          this.confirmModalInfo2B.phone = phone2B.value;
          this.confirmModalInfo2B.address = address2B.value;
          this.confirmModalInfo2B.companyId = companyId2B.value;
          this.confirmModalInfo2B.companyName = companyName2B.value;

          if (size === "lg") {
            this.confirmModal.show();
          } else {
            this.confirmModalSm.show();
          }
        } else {
          this.showError2B = errorItems;
        }

        // 手機版
        if (this.windowWidth < 768) {
          if (this.showError2B.length > 0) {
            document.getElementById("custom-toast-item")!.style.visibility =
              "visible";
            document.getElementById("custom-toast-item")!.style.opacity = "1";
            document.getElementById(
              "custom-toast-item-text"
            )!.textContent = `${this.errorMap(
              this.showError2B[0].slice(9)
            )}為必填項目`;
          }
        }
      }
    },
    closeModal(size: string) {
      if (size === "lg") {
        this.confirmModal.hide();
      } else {
        this.confirmModalSm.hide();
      }
      // this.changeStep(3);
    },
    openErrorModal() {
      this.errorModal.show();
    },
    hideToast() {
      document.getElementById("custom-toast-item")!.style.visibility = "hidden";
      document.getElementById("custom-toast-item")!.style.opacity = "0";
    },
    setIcon(name: string) {
      return require(`@/assets/icons/icon_payment_${name}.svg`);
    },
    toggleExpand() {
      const target = document.getElementById(
        "handle-invoice-collapse-control"
      )! as HTMLElement;
      const icon = document.getElementById(
        "handle-invoice-collapse-control-icon"
      )! as HTMLElement;
      if (target.getAttribute("aria-expanded") === "false") {
        // icon.classList.remove("bi-chevron-up");
        // icon.classList.add("bi-chevron-down");
        icon.style.transform = "rotate(0deg)";
        this.dropdown = false;
      } else {
        // icon.classList.remove("bi-chevron-down");
        // icon.classList.add("bi-chevron-up");
        icon.style.transform = "rotate(180deg)";
        this.dropdown = true;
      }
    },
    changeStep(step: number) {
      this.$router.push(`/online-shop?category=ccoin&step=${step}`);
    },
    setDonation(type: string) {
      this.donationSelected = type;
      const checkboxs = document.getElementsByClassName(
        "donation-checkboxs"
      ) as HTMLCollectionOf<HTMLInputElement>;
      for (let index = 0; index < checkboxs.length; index++) {
        const element = checkboxs[index];
        if (element.id !== `donation-checkbox-${type}`) {
          element.checked = false;
        }
      }
    },
    changePayment(type: string) {
      this.$emit("changePayment", type);
    },
    openUrl(link: string) {
      window.open(link);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  emits: ["changePayment", "setConfirmModalSm"],
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    this.confirmModal = new Modal(
      document.getElementById("confirm-payment-modal") as HTMLElement
    );

    this.confirmModalSm = new Modal(
      document.getElementById("confirm-payment-modal-sm") as HTMLElement
    );

    this.errorModal = new Modal(
      document.getElementById("payment-error-modal") as HTMLElement
    );

    this.termsModal = new Modal(
      document.getElementById("terms-of-service-modal") as HTMLElement
    );
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
});
