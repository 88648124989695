
import { defineComponent, PropType } from "vue";

interface plan {
  ccoin: number | string;
  price: number | string;
  custom: boolean;
  features: { free: boolean; ai: number | string; weekly: number };
  type: number;
}
export default defineComponent({
  name: "OnlineShopPlanItem",
  props: {
    data: {
      type: Object as PropType<plan>,
      required: true,
    },
    side: {
      type: String,
      required: true,
    },
  },
});
