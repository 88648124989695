import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49a8b21a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.data.route,
    class: _normalizeClass(["d-flex flex-row category-btn", { 'active-category': _ctx.active == _ctx.data.category }])
  }, {
    default: _withCtx(() => [
      (_ctx.active === _ctx.data.category)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.setImage(_ctx.data.imgActive),
            alt: _ctx.data.alt
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.setImage(_ctx.data.imgInactive),
            alt: _ctx.data.alt
          }, null, 8, _hoisted_2)),
      _createElementVNode("span", null, _toDisplayString(_ctx.data.name), 1)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}