
import { defineComponent, PropType } from "vue";
import CCoinOption from "@/components/OnlineShop/CCoinOption.vue";
import WhatIsAIModal from "@/components/OnlineShop/WhatIsAIModal.vue";
import CustomCCoinModal from "@/components/OnlineShop/CustomCCoinPlanModal.vue";
import CcoinPickUpInstructions from "@/components/modal/CcoinPickUpInstructions.vue";
import { Modal } from "bootstrap";
import axios from "axios";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface customCCoin {
  ccoin: number;
  price: number | string;
  custom: boolean;
  features: {
    free: boolean;
    ai: number | null;
    weekly: number;
  };
  promote: string[];
  type: number;
}
interface item {
  ccoin: number | string;
  price: number | string;
  custom: boolean;
  features: {
    free: boolean;
    ai: number | string;
    weekly: number | string;
  };
  promote: string[];
  type: number;
  received: boolean;
  id: number;
}
export default defineComponent({
  name: "OnlineShop-Step-1",
  components: {
    CCoinOption,
    WhatIsAIModal,
    CustomCCoinModal,
    CcoinPickUpInstructions,
  },
  data() {
    return {
      error: false,
      customModal: null as any,
      ccoinPickUpInstructions: null as any,
      plans: [
        {
          ccoin: 90,
          price: -1,
          custom: false,
          features: {
            free: true,
            ai: null,
            weekly: null,
          },
          promote: ["每月福利"],
          type: 0,
        },
        {
          ccoin: 180,
          price: 200,
          custom: false,
          features: {
            free: false,
            ai: null,
            weekly: null,
          },
          promote: ["輕鬆儲"],
          type: 1,
        },
        {
          ccoin: 450,
          price: 500,
          ai: 0,
          custom: false,
          features: {
            free: false,
            ai: null,
            weekly: null,
          },
          promote: ["輕鬆儲"],
          type: 1,
        },
        {
          ccoin: 900,
          price: 1000,
          custom: false,
          features: {
            free: false,
            ai: 7,
            weekly: null,
          },
          type: 1,
        },
        {
          ccoin: 2700,
          price: 3000,
          custom: false,
          features: {
            free: false,
            ai: 50,
            weekly: null,
          },
          promote: ["免手續費"],
          type: 2,
        },
        {
          ccoin: 5400,
          price: 6000,
          custom: false,
          features: {
            free: false,
            ai: 175,
            weekly: 2,
          },
          promote: ["免手續費"],
          type: 3,
        },
        {
          ccoin: 8100,
          price: 9000,
          custom: false,
          features: {
            free: false,
            ai: 360,
            weekly: 4,
          },
          promote: ["免手續費"],
          type: 3,
        },
        {
          ccoin: "自訂儲值",
          //   price: "1萬元以上金額",
          price: "萬元自訂額",
          //   ai: "依比例贈送AI題庫天數",
          custom: true,
          features: {
            free: false,
            ai: 365,
            weekly: 10,
          },
          promote: ["免手續費", "最超值"],
          type: 99,
          id: 1,
        },
      ],
      ccoinPickUpInstructionsStatus: false,
      spinnerStatus: true,
    };
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    selected: {
      type: Number || String,
      required: true,
    },
    customCCoin: {
      type: Object as PropType<customCCoin>,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.customModal.show();
    },
    closeModal() {
      this.customModal.hide();
    },
    openModalCcoinGet() {
      this.ccoinPickUpInstructions.show();
      (this.$refs.ccoinPickUpInstructions as any).gradientEffect();
    },
    closeModalCcoinGet() {
      this.ccoinPickUpInstructions.hide();
    },
    setCustom(data: any) {
      let custom = {} as any;

      this.plans.forEach((item) => {
        if (item.custom === true) {
          custom = { ...item };
        }
      });
      custom.price = data.price;
      custom.ccoin = data.ccoin;
      custom.features.ai = data.ai;
      custom.features.weekly = data.weekly;
      this.$emit("changeCustom", custom);
      this.changeSelected(custom);
    },
    changeStep(step: number) {
      this.$router.push(`/online-shop?category=ccoin&step=${step}`);
    },
    changeSelected(data: any) {
      this.$emit("changeSelected", data);
    },
    getCcoin() {
      this.plans.forEach(function (item: any) {
        if (item.features.free === true) {
          item.received = true;
        }
      });
    },
    getCcoinPlans() {
      this.spinnerStatus = true;
      axios
        .get(`${serverUrl}coin`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let arrayBuffer = [] as any;
            res.data.data.map(function (item: any, index: number) {
              let buffer = {
                ccoin: item.ccoin as number | string,
                price: item.price as number | string,
                custom: item.custom as boolean,
                features: item.feature,
                promote: item.label,
                type: 0 as number,
                received: item.received,
                id: item.id as number,
              };
              // type set
              if (buffer.custom === true) {
                buffer.type = 99;
                buffer.ccoin = "自訂儲值";
                buffer.price = "萬元自訂額";
                buffer.features.ai = "1年以上";
                buffer.features.weekly = 10;
              } else if (buffer.features.free === true) {
                buffer.type = 0;
                buffer.price = -1;
              } else if (buffer.price <= 1000) {
                buffer.type = 1;
              } else if (
                buffer.price >= 3000 &&
                buffer.features.weekly === null
              ) {
                buffer.type = 2;
              } else if (
                buffer.price >= 3000 &&
                buffer.features.weekly !== null
              ) {
                buffer.type = 3;
              }
              arrayBuffer.push(buffer);
            });
            this.plans = arrayBuffer;
            this.spinnerStatus = false;
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
        });
    },
  },
  computed: {
    mobileDevice() {
      if (window.innerWidth < 768) {
        return true;
      }
      return false;
    },
    freeCcoinReceived() {
      return (this.plans[0] as any).received;
    },
    receivedTitle() {
      return this.freeCcoinReceived ? "已領取" : "免費領取";
    },
  },
  created() {
    this.getCcoinPlans();
  },
  mounted() {
    // 初始化Modal
    this.customModal = new Modal(
      document.getElementById("custom-ccoin-plan-modal") as HTMLElement
    );
    this.ccoinPickUpInstructions = new Modal(
      document.getElementById("ccoin-pick-up-instructions") as HTMLElement
    );
  },
  emits: ["changeSelected", "changeCustom"],
  beforeUnmount() {
    this.closeModal();
    this.closeModalCcoinGet();
    this.ccoinPickUpInstructions = null;
  },
});
