
import { defineComponent } from "vue";

import CCoinBanner from "@/components/OnlineShop/CCoinBanner.vue";
import PurchaseStep1 from "@/components/OnlineShop/PurchaseStep1.vue";
import PurchaseStep2 from "@/components/OnlineShop/PurchaseStep2.vue";
import PurchaseStep3 from "@/components/OnlineShop/PurchaseStep3.vue";

export default defineComponent({
  name: "OnlineShop-CCoin",
  components: {
    CCoinBanner,
    PurchaseStep1,
    PurchaseStep2,
    PurchaseStep3,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: false,
      selected: 0 as number,
      paymentSelected: "Credit",
      payments: [
        {
          name: "信用卡",
          value: "Credit",
        },
        {
          name: "ATM虛擬帳號",
          value: "ATM",
        },
        {
          name: "超商條碼",
          value: "BARCODE",
        },
      ],
      images: {
        Credit: ["mastercard", "jbc", "visa"],
        ATM: ["unitedbank"],
        BARCODE: ["seven", "family", "ok", "hilife"],
      } as { [key: string]: Array<string> },
      notes: {
        Credit: [
          // "結帳時將連至第三方金流服務進行刷卡，資料傳輸均採用SSL憑證加密，保護您的資料安全。(選信用卡時顯示)",
          "結帳時將連至第三方金流服務進行刷卡，資料傳輸均採用SSL憑證加密，保護您的資料安全。",
        ],
        ATM: [
          "結帳時將連至第三方金流選擇銀行並取繳費資訊。",
          "持紙本繳費單至聯邦銀行繳費。",
        ],
        BARCODE: [
          "超商付款將加收NT$30手續費，後續退款手續費將不進行退款。",
          "持紙本繳費單至四大超商繳費。",
        ],
      } as { [key: string]: Array<string> },

      invoiceSelected: "B2C",
      invoices: [
        {
          name: "電子發票",
          value: "B2C",
        },
        {
          name: "統編發票",
          value: "B2B",
        },
      ],
      handleInvoiceSelected: "default",
      handleInvoices: [
        {
          name: "自然人憑證條碼",
          value: "CDC",
        },
        {
          name: "手機載具條碼",
          value: "carrier",
        },
        {
          name: "捐贈發票",
          value: "donate",
        },
      ],
      handleInvoicesMap: {
        default: "儲存會員載具，中獎寄郵件通知",
        CDC: "自然人憑證條碼",
        carrier: "手機載具條碼",
        donate: "捐贈發票",
      } as { [key: string]: string },
      customCCoin: {
        ccoin: 0 as number | string,
        price: "萬元自訂金額" as string,
        custom: true,
        features: {
          free: false,
          ai: 0,
          weekly: 0,
        },
        promote: [],
        type: 99,
      },
      dropdown: false,
      // plans: [
      //   {
      //     ccoin: 90,
      //     price: -1,
      //     custom: false,
      //     features: {
      //       free: true,
      //       ai: null as number | null,
      //       weekly: null,
      //     },
      //     promote: ["每月福利"],
      //     type: 0,
      //     received: false,
      //   },
      //   {
      //     ccoin: 180,
      //     price: 200,
      //     custom: false,
      //     features: {
      //       free: false,
      //       ai: null,
      //       weekly: null,
      //     },
      //     promote: ["輕鬆儲"],
      //     type: 1,
      //     received: null,
      //   },
      //   {
      //     ccoin: 450,
      //     price: 500,
      //     ai: 0,
      //     custom: false,
      //     features: {
      //       free: false,
      //       ai: null,
      //       weekly: null,
      //     },
      //     promote: ["輕鬆儲"],
      //     type: 1,
      //     received: null,
      //   },
      //   {
      //     ccoin: 900,
      //     price: 1000,
      //     custom: false,
      //     features: {
      //       free: false,
      //       ai: 7,
      //       weekly: null,
      //     },
      //     type: 1,
      //     received: null,
      //   },
      //   {
      //     ccoin: 2700,
      //     price: 3000,
      //     custom: false,
      //     features: {
      //       free: false,
      //       ai: 50,
      //       weekly: null,
      //     },
      //     promote: ["免手續費"],
      //     type: 2,
      //     received: null,
      //   },
      //   {
      //     ccoin: 5400,
      //     price: 6000,
      //     custom: false,
      //     features: {
      //       free: false,
      //       ai: 175,
      //       weekly: 2,
      //     },
      //     promote: ["免手續費"],
      //     type: 3,
      //     received: null,
      //   },
      //   {
      //     ccoin: 8100,
      //     price: 9000,
      //     custom: false,
      //     features: {
      //       free: false,
      //       ai: 360,
      //       weekly: 4,
      //     },
      //     promote: ["免手續費"],
      //     type: 3,
      //     received: null,
      //   },
      //   {
      //     ccoin: "自訂儲值",
      //     //   price: "1萬元以上金額",
      //     price: "萬元自定額",
      //     //   ai: "依比例贈送AI題庫天數",
      //     custom: true,
      //     features: {
      //       free: false,
      //       ai: "1年以上",
      //       weekly: 10,
      //     },
      //     promote: ["免手續費", "最超值"],
      //     type: 99,
      //     received: null,
      //   },
      // ],
      donationSelected: "donate",
      donations: [
        {
          name: "財團法人中華民國兒童癌症基金會",
          value: "88888",
        },
        {
          name: "社團法人台灣ＦＯＣＵＳ家庭協會",
          value: "1314515",
        },
        {
          name: "財團法人無國界醫生基金會",
          value: "58813",
        },
        {
          name: "輸入捐贈碼",
          value: "donate",
        },
      ],
      selectedData: {
        ccoin: 90,
        price: -1,
        custom: false,
        features: {
          free: true,
          ai: null,
          weekly: null,
        },
        promote: ["每月福利"],
        type: 0,
        id: 0,
      },
    };
  },
  watch: {
    selected() {
      if (this.selected === undefined) {
        this.selected = 0;
      }
    },
  },
  methods: {
    changeSelected(e: any) {
      this.selected = e.price;
      this.selectedData = e;
    },
    changeCustom(e: any) {
      this.selectedData = e;
    },
    // changeStep(step: number) {
    //   this.$router.push(`/online-shop?category=ccoin&step=${step}`);
    // },
  },
  mounted() {
    // URL Params: step
    if (
      this.$route.query.step &&
      [1, 2, 3].includes(parseInt(`${this.$route.query.step}`))
    ) {
      const step = parseInt(`${this.$route.query.step}`);
      if (step === 2 && this.selected === 0) {
        // 若於該頁重新整理，則導向「選擇方案」頁面
        this.$router.push("/online-shop?category=ccoin&step=1");
      }
    }
  },
});
