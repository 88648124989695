import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c03170b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryBtn = _resolveComponent("CategoryBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.query.category != 'exchangeList')
      ? (_openBlock(), _createBlock(_component_CategoryBtn, {
          key: 0,
          data: _ctx.btns[0],
          active: _ctx.active,
          onChangeCategory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setActive($event)))
        }, null, 8, ["data", "active"]))
      : _createCommentVNode("", true),
    (_ctx.$route.query.category == 'exchangeList')
      ? (_openBlock(), _createBlock(_component_CategoryBtn, {
          key: 1,
          data: _ctx.btns[3],
          active: _ctx.active,
          onChangeCategory: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActive($event)))
        }, null, 8, ["data", "active"]))
      : _createCommentVNode("", true),
    _createVNode(_component_CategoryBtn, {
      data: _ctx.btns[1],
      active: _ctx.active,
      onChangeCategory: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setActive($event)))
    }, null, 8, ["data", "active"]),
    _createVNode(_component_CategoryBtn, {
      data: _ctx.btns[2],
      active: _ctx.active,
      onChangeCategory: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setActive($event)))
    }, null, 8, ["data", "active"])
  ]))
}