
import { defineComponent } from "vue";
import mixins from "@/mixins/index";
import VueBarcode from "@chenfengyuan/vue-barcode";
import UnpaidInvoiceModal from "@/components/OnlineShop/UnpaidInvoiceModal.vue";
import BarcodeNoticeModal from "@/components/OnlineShop/BarcodeNoticeModal.vue";

import { Tooltip, Modal } from "bootstrap";

import axios from "axios";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
const mtNO = localStorage.getItem("mtNO");

interface transactionInfo {
  amount: number; // 7000;
  bankCode: string; // "007";
  coin: number; // 6300;
  createdAt: string; // "2022/12/22 17:56:40";
  expireDate: string; // "2022/12/25";
  invoiceId: number | null;
  paymentType: string; // "ATM" | "BARCODE" | "Credit";
  product: string; // "C幣儲值(固定方案)";
  vAccount: string; // "1057623595124831";
  barcode: Array<number>;
}

interface invoice {
  amount: 2000;
  company: null;
  customerNo: "TWC2206000050";
  invoiceDate: "2022-12-23";
  invoiceNumber: "XX66656891";
  invoiceType: "B2C";
  isAllowance: false;
  name: "apple";
  orderId: "200013200000000018";
  product: "C幣儲值(固定方案)";
  randomNumber: "1109";
  taxId: null;
}
export default defineComponent({
  name: "OnlineShop-Step-3",
  components: { VueBarcode, UnpaidInvoiceModal, BarcodeNoticeModal },
  mixins: [mixins],
  data() {
    return {
      showTooltip: false,
      isFetching: true,
      currentDate: this.timestampToDate(Date.now() / 1000, 1),
      invoiceModal: null as any,
      invoiceInfo: {
        customerName: "劉小美",
        customerMail: "angelaliu@gmail.com",
        invoiceTo: "B", // B 統編發票 ; C 電子發票
        buyer: "誠新數位科技股份有公司", // 非營業人則為 null
        companyId: 53247471, // 非營業人則為 null
      },
      paymentMap: {
        Credit: "信用卡",
        ATM: "ATM虛擬帳號",
        BARCODE: "超商條碼",
      } as { [key: string]: string },
      // 超商條碼
      transaction: {} as transactionInfo,
    };
  },
  methods: {
    openModal(invoiceId: number) {
      // 取得發票資訊
      axios
        .get(`${serverUrl}paydata/detail/${invoiceId}?type=coin&isInvoice=1`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          this.invoiceInfo = res.data.data;
        });

      this.invoiceModal.show();
    },
    getPaymentData() {
      axios
        .post(
          `${serverUrl}coin/result/order/${mtNO}`,
          {},
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          // 取得繳費資料
          this.transaction = res.data.data;

          this.isFetching = false;
        });
    },
  },
  mounted() {
    // 初始化Tooltip
    // var tooltipTriggerList = [].slice.call(
    //   document.querySelectorAll('[data-bs-toggle="tooltip"]')
    // );

    // tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   return new Tooltip(tooltipTriggerEl, {
    //     container: "body",
    //     trigger: "hover",
    //   });
    // });

    // 初始化Ｍodal;
    this.invoiceModal = new Modal(
      document.getElementById("unpaid-invoice-modal") as HTMLElement
    );

    // 取得繳費資訊
    const refreshId = setInterval(() => {
      this.getPaymentData();
      if (this.isFetching === false) {
        clearInterval(refreshId);
      }
    }, 3000);
  },
});
