
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import axios from "axios";
import Bus from "@/utils/Bus";

import ConfirmExchangeModal from "@/components/OnlineShop/ConfirmExchangeModal.vue";
import FavoriteListCourseCardFull from "@/components/OnlineShop/FavoriteListCourseCardFull.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}`;

interface item {
  coin: number;
  coinDiscount: number;
  discountText: string;
  enable: boolean;
  expirationDate: null | string;
  id: string;
  like: boolean;
  title: string;
  type: number;
  checked: boolean;
  setId: string | null;
}

interface favorite {
  coin: number;
  coinDiscount: number;
  discountText: string;
  enable: boolean;
  expirationDate: null | string;
  classDate: null | string;
  id: string;
  level: null | number;
  picture: null | string;
  sales: number;
  shoppingCart: boolean;
  title: string;
  type: number;
  cnName: string;
  foreignName: string;
  category: string;
}

export default defineComponent({
  name: "OnlineShop-Exchange-List",
  components: {
    ConfirmExchangeModal,
    FavoriteListCourseCardFull,
  },
  data() {
    return {
      view: "exchange",
      confirmModal: null as any,
      myCCoin: 800,
      showAlert: false,
      toastContent: "",
      isPendingCart: false,
      isPendingFavorites: false,
      items: [] as Array<item>,
      itemsChecked: [] as Array<item>,
      myFavorites: [] as Array<favorite>,
      purchasedItems: [] as Array<item>,
    };
  },
  methods: {
    // 取得兌換清單資料
    fetchCartItems() {
      this.isPendingCart = true;
      this.items = [];
      axios
        .get(`${serverUrl}learn_shop/cart`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data.data);
            res.data.data.forEach((ele: item) => {
              ele.checked = true;
              this.items.push(ele);
              this.itemsChecked.push(ele);
            });
            this.isPendingCart = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isPendingCart = false;
        });
    },
    // 取得我的收藏資料
    fetchMyFavorites() {
      this.isPendingFavorites = true;
      axios
        .get(`${serverUrl}learn_shop/like`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.myFavorites = res.data.data;
            this.isPendingFavorites = false;

            for (let i = 0; i < this.myFavorites.length; i++) {
              if (this.myFavorites[i].picture !== null) {
                // 顯示原先資料的圖片
              } else if (this.myFavorites[i].category === "skill") {
                this.myFavorites[i].picture =
                  "/static/img/onlineShop/productBannerSkill.png";
              } else if (this.myFavorites[i].type == 1) {
                this.myFavorites[i].picture =
                  "/static/img/onlineShop/productBanner0.png";
              } else if (this.myFavorites[i].type == 2) {
                this.myFavorites[i].picture =
                  "/static/img/onlineShop/productBanner1.png";
              } else if (this.myFavorites[i].type == 3) {
                this.myFavorites[i].picture =
                  "/static/img/onlineShop/productBanner2.png";
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.isPendingFavorites = false;
        });
    },
    // 取得用戶的C幣
    fetchMyCCoin() {
      axios
        .get(`${serverUrl}coin/balance`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.myCCoin = res.data.data.coin;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterChecked() {
      let temp = [] as Array<item>;
      if (this.items.length > 0) {
        this.items.forEach((ele: item) => {
          if (ele.checked) {
            temp.push(ele);
          }
        });
      }
      return temp;
    },
    openModal() {
      let items: item[] = this.filterChecked();
      let disabledItem = items.some((item) => !item.enable);
      if (items.length === 0) {
        return;
      }
      if (disabledItem) {
        Bus.emit("openAlertModal", {
          type: "warning",
          title: ["Warning!"],
          content: "請先將已下架的商品移除。",
          btnText: "關閉",
        });
        return;
      }
      if (this.myCCoin - this.getSum(this.items) >= 0) {
        this.confirmModal.show();
      }
    },
    closeModal() {
      this.confirmModal.hide();
      this.purchasedItems = this.filterChecked();
      this.view = "success";
    },
    selectAll(checked: boolean) {
      if (this.items.length > 0) {
        this.items.forEach((ele: item) => {
          ele.checked = checked;
        });
      }
    },
    getSum(items: Array<item>) {
      let sum = 0;
      if (items.length > 0) {
        items.forEach((element) => {
          if (element.checked && element.enable) {
            if (element.coinDiscount) {
              sum += element.coinDiscount;
            } else {
              sum += element.coin;
            }
          }
        });
      }

      return sum;
    },
    changeChecked(index: number) {
      this.items[index].checked = !this.items[index].checked;
    },
    removeItemFromFavList(info: { id: string; index: number; setId: string }) {
      let url = "";
      if (info.setId === null) {
        url = `${serverUrl}learn_shop/like/${info.id}`;
      } else {
        url = `${serverUrl}learn_shop/like/${info.setId}/${info.id}`;
      }
      axios
        .patch(
          url,
          { add: false },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // 更新前端「我的收藏」
            this.myFavorites.splice(info.index, 1);

            //更新前端「兌換清單」收藏狀態
            this.items.forEach((ele: item, index: number) => {
              if (ele.id === info.id) {
                this.items[index].like = !this.items[index].like;
              }
            });

            // 顯示提示方塊
            const target = document.getElementById(
              "exchange-alert"
            )! as HTMLElement;
            const targetText = document.getElementById(
              "exchange-alert-text"
            )! as HTMLElement;
            target.style.visibility = "visible";
            target.style.opacity = "1";
            targetText.textContent = "已取消收藏";

            setTimeout(() => {
              this.hideToast();
            }, 3000);
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
        });
    },
    toggleSavedItem(id: string, index: number, setId: string) {
      let url = "";
      if (setId === null) {
        url = `${serverUrl}learn_shop/like/${id}`;
      } else {
        url = `${serverUrl}learn_shop/like/${setId}/${id}`;
      }
      // POST API
      axios
        .patch(
          url,
          { add: !this.items[index].like },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // 重新取得「我的收藏」
            this.fetchMyFavorites();

            // 更新前端「兌換清單」
            this.items[index].like = !this.items[index].like;

            // 顯示提示方塊
            const target = document.getElementById(
              "exchange-alert"
            )! as HTMLElement;
            const targetText = document.getElementById(
              "exchange-alert-text"
            )! as HTMLElement;
            target.style.visibility = "visible";
            target.style.opacity = "1";
            targetText.textContent = this.items[index].like
              ? "已移到我的收藏"
              : "已取消收藏";

            setTimeout(() => {
              this.hideToast();
            }, 3000);
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
        });
    },
    removeItem(id: string, index: number, setId: string) {
      let url = "";
      if (setId === null) {
        url = `${serverUrl}learn_shop/cart/${id}`;
      } else {
        url = `${serverUrl}learn_shop/cart/${setId}/${id}`;
      }
      axios
        .patch(
          url,
          { add: false },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // 更新前端「兌換清單」
            this.items.splice(index, 1);

            // 顯示提示方塊
            const target = document.getElementById(
              "exchange-alert"
            )! as HTMLElement;
            const targetText = document.getElementById(
              "exchange-alert-text"
            )! as HTMLElement;
            target.style.visibility = "visible";
            target.style.opacity = "1";
            targetText.textContent = "已移出兌換清單";
            setTimeout(() => {
              this.hideToast();
            }, 3000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    hideToast() {
      const target = document.getElementById("exchange-alert")! as HTMLElement;
      target.style.visibility = "hidden";
      target.style.opacity = "0";
    },
    // 加入兌換清單: 新增一筆商品成功後就會重新打一次取得兌換清單資料的API，讓內容可以進行更新
    addToCart(info: { id: string; index: number; setId: string }) {
      let url = "";
      if (info.setId === null) {
        url = `${serverUrl}learn_shop/cart/${info.id}`;
      } else {
        url = `${serverUrl}learn_shop/cart/${info.setId}/${info.id}`;
      }
      axios
        .patch(
          url,
          { add: true },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.fetchCartItems();

            // 顯示提示方塊
            const target = document.getElementById(
              "exchange-alert"
            )! as HTMLElement;
            const targetText = document.getElementById(
              "exchange-alert-text"
            )! as HTMLElement;
            target.style.visibility = "visible";
            target.style.opacity = "1";
            targetText.textContent = "已加入兌換清單";
            setTimeout(() => {
              this.hideToast();
            }, 3000);
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
        });
    },
  },
  mounted() {
    // 初始化 Modal
    this.confirmModal = new Modal(
      document.getElementById("confirm-exchange-modal")! as HTMLElement
    );

    this.fetchCartItems();
    this.fetchMyFavorites();
    this.fetchMyCCoin();
  },
});
