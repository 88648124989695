
import { defineComponent } from "vue";
import mixins from "@/mixins/index";
import TermsOfServiceModalSm from "@/components/OnlineShop/TermsOfServiceModalSm.vue";
import { Modal } from "bootstrap";

interface dataStructure {
  ccoin: number;
  price: number;
  custom: boolean;
  features: {
    free: boolean;
    ai: number | null;
    weekly: number | null;
  };
  promote: string[];
  type: number;
}

export default defineComponent({
  name: "OnlineShop-Step-2-Floating-Block",
  components: { TermsOfServiceModalSm },
  mixins: [mixins],
  data() {
    return {
      termsModal: null as any,
      dropdown: false,
      promoteBg: [
        ["#26A69A"], // type 0
        ["#FF6767"], // type 1
        ["#FF8310", "#26A69A"], // type 2
        ["#FF8310", "#26A69A"], // type 3
        ["#FF8310", "#26A69A"], // type 99 這個永遠保持在最後面，如果有新的就往前插入
      ],
    };
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    selected: {
      type: Number,
      required: true,
    },
    paymentSelected: {
      type: String,
      required: true,
    },
    data: {
      type: Object as () => dataStructure,
      required: true,
    },
  },
  computed: {
    colorType() {
      if (this.data.type !== 99) {
        return this.data.type;
      } else {
        return this.promoteBg.length - 1;
      }
    },
  },
  methods: {
    openModal() {
      this.$emit("openModal");
    },
    setIcon(name: string) {
      return require(`@/assets/icons/icon_payment_${name}.svg`);
    },
    changeStep(step: number) {
      this.$router.push(`/online-shop?category=ccoin&step=${step}`);
    },
    toggleExpand2() {
      const target = document.getElementById(
        "purchase-info-collapse-control"
      )! as HTMLElement;
      // console.log(target);
      const icon = document.getElementById(
        "purchase-info-collapse-control-icon"
      )! as HTMLElement;
      // console.log(icon);
      if (target.getAttribute("aria-expanded") === "false") {
        // icon.classList.remove("bi-chevron-up");
        // icon.classList.add("bi-chevron-down");
        icon.style.transform = "rotate(0deg)";
      } else {
        // icon.classList.remove("bi-chevron-down");
        // icon.classList.add("bi-chevron-up");
        icon.style.transform = "rotate(180deg)";
      }
    },
  },
  emits: ["openModal"],
  mounted() {
    this.termsModal = new Modal(
      document.getElementById("terms-of-service-modal-sm") as HTMLElement
    );
  },
});
