
import { defineComponent } from "vue";
import CategoryBtn from "@/components/buttons/CategoryBtn.vue";

export default defineComponent({
  name: "OnlineShopCategoryCard",
  components: {
    CategoryBtn,
  },
  data() {
    return {
      btns: [
        {
          category: "shop",
          imgActive: "shop_turkey",
          imgInactive: "shop_gray",
          alt: "shop icon",
          name: "學習商店",
          route: "/online-shop?category=shop",
        },
        {
          category: "ccoin",
          imgActive: "ccoin_turkey",
          imgInactive: "ccoin_gray",
          alt: "ccoin icon",
          name: "C幣儲值",
          route: "/online-shop?category=ccoin",
        },
        {
          category: "history",
          imgActive: "order_turkey",
          imgInactive: "order_gray",
          alt: "record icon",
          name: "兌換紀錄",
          route: "/online-shop?category=history",
        },

        // 這邊是btns[3]的資料，可以看到只有category變成是'exchangeList'，其餘跟btns[0]一模一樣
        // 原因如最上面說明，需要保有這個按鈕一樣是切換到/online-shop?category=shop的功能，又要
        // 在兌換清單頁面同時讓該按鈕亮起，因此需要一個category是exchangeList的資料
        {
          category: "exchangeList",
          imgActive: "shop_turkey",
          imgInactive: "shop_gray",
          alt: "shop icon",
          name: "學習商店",
          route: "/online-shop?category=shop",
        },
      ],
    };
  },
  props: {
    active: {
      type: String,
      required: true,
    },
  },
  methods: {
    setActive(category: string) {
      this.$emit("changeCategory", category);
    },
  },
  emits: ["changeCategory"],
});
