<template>
  <a href="#" class="exchange-item">
    <div class="d-flex align-items-center">
      <img
        src="@/assets/icons/shop/icon_exchange_one_on_one.svg"
        alt=""
        v-if="itemInfo.type == 1"
      />
      <img
        src="@/assets/icons/shop/icon_exchange_video.svg"
        alt=""
        v-if="itemInfo.type == 2"
      />
      <img
        src="@/assets/icons/shop/icon_exchange_correspondence_education.svg"
        alt=""
        v-if="itemInfo.type == 3"
      />
      <span>{{ itemInfo.productName }}</span>
    </div>
  </a>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExchangeListItem",
  props: ["info"],
  data() {
    return {
      itemInfo: {
        productName: "",
        index: 0,
        type: 0, // type是用來在內部判斷小圖示要顯示成哪一個
        id: "",
      },
    };
  },
  methods: {
    openModal() {
      this.$emit("openModal");
    },
  },
  mounted() {
    this.itemInfo = this.info;
    // console.log(this.itemInfo)
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss"; // 全域 CSS

// 大於 1920px 時，套用以下 css
@media screen and (min-width: 1920px) {
  .exchange-item {
    margin: 8px 16px;
    border-radius: 10px;

    span {
      max-width: 163px;
      @include text-rows-limit(1);
      margin-left: 8px;
      font-size: 14px;
      color: #5e5d5d;
    }
  }
}

// 大於 768px 時，套用以下 css
@media screen and (min-width: 768px) and (max-width: 1919px) {
  .exchange-item {
    margin: 6px 12px;
    border-radius: 10px;

    span {
      max-width: 163px;
      @include text-rows-limit(1);
      margin-left: 8px;
      font-size: 14px;
      color: #5e5d5d;
    }
  }
}

// 其他固定CSS
</style>
