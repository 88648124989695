import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72b3e5f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = {
  class: "content-block",
  style: {"min-height":"60vh"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CCoinBanner = _resolveComponent("CCoinBanner")!
  const _component_PurchaseStep1 = _resolveComponent("PurchaseStep1")!
  const _component_PurchaseStep2 = _resolveComponent("PurchaseStep2")!
  const _component_PurchaseStep3 = _resolveComponent("PurchaseStep3")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CCoinBanner, { step: _ctx.step }, null, 8, ["step"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.step === 1)
        ? (_openBlock(), _createBlock(_component_PurchaseStep1, {
            key: 0,
            step: _ctx.step,
            selected: _ctx.selected,
            customCCoin: _ctx.customCCoin,
            onChangeSelected: _ctx.changeSelected,
            onChangeCustom: _ctx.changeCustom
          }, null, 8, ["step", "selected", "customCCoin", "onChangeSelected", "onChangeCustom"]))
        : _createCommentVNode("", true),
      (_ctx.step === 2)
        ? (_openBlock(), _createBlock(_component_PurchaseStep2, {
            key: 1,
            step: _ctx.step,
            itemData: _ctx.selectedData,
            selected: _ctx.selected,
            paymentSelected: _ctx.paymentSelected,
            onChangePayment: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paymentSelected = $event))
          }, null, 8, ["step", "itemData", "selected", "paymentSelected"]))
        : _createCommentVNode("", true),
      (_ctx.step === 3)
        ? (_openBlock(), _createBlock(_component_PurchaseStep3, {
            key: 2,
            step: _ctx.step
          }, null, 8, ["step"]))
        : _createCommentVNode("", true)
    ])
  ]))
}