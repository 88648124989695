
import { defineComponent, PropType } from "vue";

interface plan {
  ccoin: number;
  price: number | string;
  custom: boolean;
  features: {
    free: boolean;
    ai: number;
    weekly: number;
  };
  promote: string[];
  type: number;
  received: boolean;
  id: number;
}

interface customCCoin {
  ccoin: string;
  price: string;
  ai: string;
  custom: boolean;
}

export default defineComponent({
  name: "OnlineShopCCoinOption",
  props: {
    data: {
      type: Object as PropType<plan>,
      required: true,
    },
    selected: {
      type: Number || String,
      required: true,
    },
    customCCoin: {
      type: Object as PropType<plan>,
      required: true,
    },
  },
  data() {
    return {
      ccoinOption: {
        ccoin: 90,
        price: 0 as number | string,
        custom: false,
        features: {
          free: true,
          ai: 0,
          weekly: 0,
        },
        promote: ["每月福利"],
        type: 0,
      },
      promoteBg: [
        ["#26A69A"], // type 0
        ["#FF6767"], // type 1
        ["#FF8310", "#26A69A"], // type 2
        ["#FF8310", "#26A69A"], // type 3
        ["#FF8310", "#26A69A"], // type 99 這個永遠保持在最後面，如果有新的就往前插入
      ],
    };
  },
  methods: {
    // select(price: string | number) {
    //   // price 是來自 data
    //   if (this.selected === 0 || this.selected !== price) {
    //     this.$emit("changeSelected", price);
    //   } else {
    //     this.$emit("changeSelected", 0);
    //   }
    // },
    select(data: any) {
      // price 是來自 data
      if (this.selected === 0 || this.selected !== data.price) {
        this.$emit("changeSelected", data);
      } else {
        this.$emit("changeSelected", 0);
      }
    },
    openModal() {
      this.$emit("openModal");
    },
  },
  computed: {
    colorType() {
      if (this.data.type !== 99) {
        return this.data.type;
      } else {
        return this.promoteBg.length - 1;
      }
    },
  },
  emits: ["changeSelected", "openModal"],
});
