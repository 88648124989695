<template>
  <!-- 課程卡片內容 -->
  <div class="course-card">
    <!-- 透過使用position的方式將顯示在左上角的課程特點定位在圖片上 -->
    <!-- 使用picture標籤是為了rwd時會切換圖片，也許需要拿到資料後透過綁定src屬性切換實際圖片來源 -->
    <div
      class="course-card-banner d-flex align-items-center justify-content-center"
      :style="{ backgroundImage: `url(${info.picture})` }"
      @click.prevent="goToCoursePage"
    ></div>

    <!-- 課程特點小標籤，使用絕對定位 -->
    <span class="course-feature" v-show="courseInfo.courseFeature != undefined">
      {{ courseInfo.courseFeature }}</span
    >
    <span class="course-level" v-show="courseInfo.courseLevel != undefined">
      {{ courseInfo.courseLevel }}</span
    >
    <span
      class="course-discount"
      v-show="
        (courseInfo.courseDiscount != undefined) &
        (courseInfo.courseBought != true)
      "
    >
      {{ courseInfo.courseDiscount }}</span
    >
    <span class="course-bought" v-show="courseInfo.courseBought == true"
      >已購買</span
    >
    <span
      class="will-be-discontinued"
      v-show="courseInfo.willBeDiscontinued != undefined"
      >再過{{ courseInfo.willBeDiscontinued }}天即將下架</span
    >

    <!-- 這邊的高度是商品圖片在class="course-card-banner"內設定減剩下的，必須要設定否則文字在做垂直推移時會超出容器 -->
    <div
      class="d-flex flex-column justify-content-between"
      style="height: 56.05%"
    >
      <!-- 課程卡片標題、內容特點、時間顯示 -->
      <div class="product-description-container">
        <!-- href="javascript:void(0);"可以禁用a標籤跳轉的功能，這邊只是要拿來觸發彈出視窗 -->
        <a
          href="javascript:void(0);"
          style="text-decoration: none"
          @click.prevent="goToCoursePage"
        >
          <p class="product-name">
            {{ courseInfo.productName }}
          </p>
          <p class="product-description">
            {{ courseInfo.productDescription }}
          </p>
          <p class="video-date">
            {{ courseInfo.videoDate }}
          </p>
        </a>
      </div>

      <!-- 課程價格、出售狀況顯示 -->
      <div class="product-price-container">
        <!-- 目前原價並不會顯示，在將來有折扣設定時才會有，但需要考慮卡片高度的問題 -->
        <span
          class="course-original-price"
          v-show="courseInfo.courseOriginalPrice != undefined"
          >{{ courseInfo.courseOriginalPrice }}</span
        >
        <div class="product-price d-flex align-items-center">
          <span class="price">{{ courseInfo.price }}</span>
          <span class="unit">C幣</span>
        </div>

        <p class="product-sold-quantity">
          <span class="sold-quantity"
            >已售出 {{ courseInfo.soldQuantity }}</span
          >
        </p>
      </div>
    </div>

    <!-- 收藏及兌換清單符號顯示 -->
    <div class="favorite-shopcart-container">
      <!-- 收藏符號 -->
      <a
        href="javascript:void(0);"
        class="course-favorite"
        @click="favoriteStatusChange()"
      >
        <!-- <img class="course-favorite-icon" src="@/assets/icons/shop/icon_favorit_black.svg" alt="" style="color:red;"> -->
        <i
          class="bi bi-suit-heart-fill"
          :style="[
            courseInfo.favoriteStatus === true
              ? { color: '#FC2C2C' }
              : { color: '#777777' },
          ]"
          style="transition: color 0.2s"
        ></i>
      </a>
      <!-- 兌換清單符號 -->
      <a
        href="javascript:void(0);"
        class="course-shopcart"
        @click="clickAddToExchangeList"
      >
        <img
          class="course-shopcart-icon"
          src="@/assets/icons/shop/icon_shopcart_black.svg"
          alt=""
        />
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "CourseCard",
  components: [],
  emits: [
    "openModal",
    "favoriteStatusChange",
    "addToExchangeList",
    "favoriteIconClick",
  ],
  props: [
    // info是從外部接收到的課程資訊
    // index是在指定課程分類裡面的第幾項，當需要執行加入收藏或兌換清單時，需要用這個跟item搭配才可以選到指定課程
    // item是從外部接收到的課程分類名稱
    "info",
    "index",
    "item",
  ],
  data() {
    return {
      courseInfo: {
        courseFeature: "1對1視訊課程",
        courseLevel: "2~3",
        courseDiscount: "25",
        courseBought: false,
        willBeDiscontinued: "7",
        productName:
          " 英、日1對1 - 60分鐘 英、日1對1 - 60分鐘 英、日1對1 - 60分鐘",
        productDescription: "全程1對1教學，高效學習方案。",
        videoDate: "影片日期：2022/6/22",
        courseOriginalPrice: "99999",
        price: "900",
        soldQuantity: "1322",
        favoriteStatus: false,
        id: "",
      },
    };
  },
  methods: {
    // openModal() {
    //   this.$emit("openModal", { id: this.courseInfo.id });
    // },
    goToCoursePage() {
      // 直接在原畫面跳轉到指定網址
      // this.$router.push(`product-info?pid=${this.courseInfo.id}`);
      // let url = `product-info?pid=${this.courseInfo.id}`;
      // window.location.href = url;

      // 開啟新視窗，非直接在原畫面跳轉
      let routeUrl = this.$router.resolve({
        path: `product-info`,
        query: { pid: this.courseInfo.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    favoriteStatusChange() {
      // 按下收藏的按鈕時透過emit將狀態傳出父階，用以toast顯示
      if (this.courseInfo.favoriteStatus == true) {
        this.courseInfo.favoriteStatus = false;
        this.$emit("favoriteStatusChange", this.courseInfo.favoriteStatus);
        this.$emit("favoriteIconClick", {
          item: this.item,
          index: this.index,
          status: this.courseInfo.favoriteStatus,
          id: this.courseInfo.id,
        });
        return;
      }

      if (this.courseInfo.favoriteStatus == false) {
        this.courseInfo.favoriteStatus = true;
        this.$emit("favoriteStatusChange", this.courseInfo.favoriteStatus);
        this.$emit("favoriteIconClick", {
          item: this.item,
          index: this.index,
          status: this.courseInfo.favoriteStatus,
          id: this.courseInfo.id,
        });
        return;
      }

      // 如果原資料沒有收藏狀態，必須先將收藏狀態加入data ，否則會一直無法觸發顏色切換
      if (this.courseInfo.favoriteStatus == undefined) {
        this.courseInfo.favoriteStatus = true;
        this.$emit("favoriteStatusChange", this.courseInfo.favoriteStatus);
        this.$emit("favoriteIconClick", {
          item: this.item,
          index: this.index,
          status: this.courseInfo.favoriteStatus,
          id: this.courseInfo.id,
        });
        return;
      }
    },
    clickAddToExchangeList() {
      // 按下加入兌換的小圖示後，會將item及index兩個參數傳遞出去，從shop內執行將指定課程加入兌換清單的動作
      if (!this.courseInfo.shoppingCartStatus) {
        this.$emit("addToExchangeList", {
          productName: this.courseInfo.productName,
          index: this.index,
          id: this.courseInfo.id,
          item: this.item,
          type: this.courseInfo.type,
          addExchangeList: "addExchangeList",
        });
        this.courseInfo.shoppingCartStatus = true;
      } else {
        this.$emit("addToExchangeList");
      }
    },
  },
  mounted() {
    // 將父階傳進來的寫入卡片資訊，這樣就可以改動卡片的內容
    this.courseInfo = this.info;
    // console.log(this.courseInfo)
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss"; // 全域 CSS

// 大於 1920px 時，套用以下 css
@media screen and (min-width: 1920px) {
  .course-card:hover {
    box-shadow: 2px 2px 10px 1px #cccccc;
    // 透過transition增加變色時的動畫效果
    transition: all 0.5s ease-in;

    .price {
      color: #25a2a6;
      transition: all 0.3s ease-in;
    }
  }

  .course-card {
    position: relative;
    // 原先的css也要加入transition ，這樣在取消hover時也會有離開的效果
    transition: all 0.5s ease-out;
    // 這邊設定卡片寬度，由於1920版本每一row都要有4張卡片，因此width應該要是 100% / 4 = 25% ，但需要留距離給卡片之間的間隙
    // 因此設定成24%
    width: 24%;
    min-height: 273px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    overflow: hidden;

    // 課程卡片主要視覺圖片

    // 卡片左上角的特點說明標籤
    .course-feature {
      // width: 90px;
      height: 19px;
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #2d2d2d;
      opacity: 0.8;
      border-radius: 100px;
      // 使用flex及row是為了文字的水平跟垂直置中
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    // 卡片左上角的課程等級說明標籤
    .course-level {
      height: 19px;
      position: absolute;
      top: 31px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #f91d1d;
      opacity: 0.8;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    // 卡片右上角的折扣百分比
    .course-discount {
      // width: 90px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      background-color: red;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    // 卡片右上角的已購買標籤
    .course-bought {
      // width: 80px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      padding: 4px 16px;
      background-color: #ff6767;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    // 卡片本體右邊的即將下架提示標籤
    .will-be-discontinued {
      position: absolute;
      right: 0;
      bottom: 50px;
      color: white;
      padding: 6.5px 11.5px;
      background-color: #fc2c2c;
      font-size: 12px;
      // line-height: 125%;
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 500;
    }

    // 收藏與購買的符號
    .favorite-shopcart-container {
      position: absolute;
      bottom: 16px;
      right: 13px;
      // border: 1px solid red;

      .course-favorite:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-favorite {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 100px;
        // 這邊設置line-height與圖示高度相同的原因是，這樣子容器與圖片才會同心，否則在padding產出陰影效果後，會發現不是正圓
        // line-height: 15.63px;

        .course-favorite-icon {
          // opacity: 0.8;
          // // width: 18px;
          // // height: 15.63px;
        }
      }

      .course-shopcart:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-shopcart {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 50%;
        // border: 1px solid green;
        line-height: 17px;
        margin-left: 2px;

        .course-shopcart-icon {
          opacity: 0.8;
          width: 17px;
          height: 17px;
        }
      }
    }

    .product-description-container {
      // border: 1px solid green;
      margin-top: 10px;
      padding-right: 12px;
      padding-left: 12px;

      .product-name {
        font-size: 16px;
        color: #777777;
        line-height: 20px;
      }

      .product-description {
        margin-top: -10px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }

      .video-date {
        margin-top: 6px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }
    }

    .product-price-container {
      // border: 1px solid red;
      padding-right: 12px;
      padding-left: 12px;

      // 課程原價顯示
      .course-original-price {
        color: #777777;
        font-size: 12px;
        text-decoration-line: line-through;
      }

      .product-price {
        margin-bottom: 6px;
        height: 23px;
        .price {
          font-size: 18px;
          // border: 1px solid green;
          vertical-align: middle;
          transition: all 0.5s ease-out;
        }

        .unit {
          margin-left: 4px;
          font-size: 12px;
          // border: 1px solid gray;
          vertical-align: middle;
          color: #777777;
        }
      }

      .product-sold-quantity {
        margin-bottom: 12px;
        line-height: 15.8px;

        .sold-quantity {
          font-size: 12px;
          color: #777777;
        }
      }
    }
  }

  // 彈出視窗

  // 這裡可以修改彈出視窗顯示的位置
  .fade {
    top: 25%;
  }

  .modal {
    // 這裡可以修改彈出視窗的大小
    .modal-xl {
      width: 1000px;
      border: 1px solid red;
    }
  }
}

// 遊覽區域寬度範圍在1440px~1679px，則下方CSS描述會立即被套用：
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .course-card:hover {
    box-shadow: 2px 2px 10px 1px #cccccc;
    // 透過transition增加變色時的動畫效果
    transition: all 0.5s ease-in;

    .price {
      color: #25a2a6;
      transition: all 0.3s ease-in;
    }
  }

  .course-card {
    position: relative;
    // 原先的css也要加入transition ，這樣在取消hover時也會有離開的效果
    transition: all 0.5s ease-out;
    // 這邊設定卡片寬度，由於1440版本每一row都要有4張卡片，因此width應該要是 100% / 3 = 33.33% ，但需要留距離給卡片之間的間隙
    // 因此設定成32.5%
    width: 32.5%;
    min-height: 273px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    overflow: hidden;

    .course-feature {
      // width: 90px;
      height: 19px;
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #2d2d2d;
      opacity: 0.8;
      border-radius: 100px;
      // 使用flex及row是為了文字的水平跟垂直置中
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-level {
      height: 19px;
      position: absolute;
      top: 31px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #f91d1d;
      opacity: 0.8;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-discount {
      // width: 90px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      background-color: red;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .course-bought {
      // width: 80px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      padding: 4px 16px;
      background-color: #ff6767;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .will-be-discontinued {
      // width: 115px;
      height: 28px;
      position: absolute;
      right: 0;
      bottom: 60px;
      color: white;
      padding: 6.5px 11.5px;
      background-color: #fc2c2c;
      font-size: 12px;
      // line-height: 125%;
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 500;
    }

    // 收藏與購買的符號
    .favorite-shopcart-container {
      position: absolute;
      bottom: 16px;
      right: 13px;
      // border: 1px solid red;

      .course-favorite:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-favorite {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 100px;
        // 這邊設置line-height與圖示高度相同的原因是，這樣子容器與圖片才會同心，否則在padding產出陰影效果後，會發現不是正圓
        line-height: 15.63px;

        .course-favorite-icon {
          opacity: 0.8;
          width: 18px;
          height: 15.63px;
        }
      }

      .course-shopcart:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-shopcart {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 50%;
        // border: 1px solid green;
        line-height: 17px;
        margin-left: 2px;

        .course-shopcart-icon {
          opacity: 0.8;
          width: 17px;
          height: 17px;
        }
      }
    }

    .product-description-container {
      // border: 1px solid green;
      margin-top: 10px;
      padding-right: 12px;
      padding-left: 12px;

      .product-name {
        font-size: 16px;
        color: #777777;
        line-height: 20px;
      }

      .product-description {
        margin-top: -10px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }

      .video-date {
        margin-top: 6px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }
    }

    .product-price-container {
      // border: 1px solid red;
      padding-right: 12px;
      padding-left: 12px;

      .course-original-price {
        color: #777777;
        font-size: 12px;
        text-decoration-line: line-through;
      }

      .product-price {
        margin-bottom: 6px;
        height: 23px;
        .price {
          font-size: 18px;
          // border: 1px solid green;
          vertical-align: middle;
          transition: all 0.5s ease-out;
        }

        .unit {
          margin-left: 4px;
          font-size: 12px;
          // border: 1px solid gray;
          vertical-align: middle;
          color: #777777;
        }
      }

      .product-sold-quantity {
        margin-bottom: 12px;
        line-height: 15.8px;

        .sold-quantity {
          font-size: 12px;
          color: #777777;
        }
      }
    }
  }
}

// 遊覽區域寬度範圍在1024px~1440px，則下方CSS描述會立即被套用：
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .course-card:hover {
    box-shadow: 2px 2px 10px 1px #cccccc;
    // 透過transition增加變色時的動畫效果
    transition: all 0.5s ease-in;

    .price {
      color: #25a2a6;
      transition: all 0.3s ease-in;
    }
  }

  .course-card {
    position: relative;
    // 原先的css也要加入transition ，這樣在取消hover時也會有離開的效果
    transition: all 0.5s ease-out;
    // 這邊設定卡片寬度，由於1024版本每一row都要有4張卡片，因此width應該要是 100% / 3 = 33.33% ，但需要留距離給卡片之間的間隙
    // 因此設定成32.5%
    width: 32.5%;
    min-height: 273px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    overflow: hidden;

    .course-feature {
      // width: 90px;
      height: 19px;
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #2d2d2d;
      opacity: 0.8;
      border-radius: 100px;
      // 使用flex及row是為了文字的水平跟垂直置中
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-level {
      height: 19px;
      position: absolute;
      top: 31px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #f91d1d;
      opacity: 0.8;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-discount {
      // width: 90px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      background-color: red;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .course-bought {
      // width: 80px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      padding: 4px 16px;
      background-color: #ff6767;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .will-be-discontinued {
      // width: 115px;
      height: 28px;
      position: absolute;
      right: 0;
      bottom: 60px;
      color: white;
      padding: 6.5px 11.5px;
      background-color: #fc2c2c;
      font-size: 12px;
      // line-height: 125%;
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 500;
    }

    // 收藏與購買的符號
    .favorite-shopcart-container {
      position: absolute;
      bottom: 16px;
      right: 13px;
      // border: 1px solid red;

      .course-favorite:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-favorite {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 100px;
        // 這邊設置line-height與圖示高度相同的原因是，這樣子容器與圖片才會同心，否則在padding產出陰影效果後，會發現不是正圓
        line-height: 15.63px;

        .course-favorite-icon {
          opacity: 0.8;
          width: 18px;
          height: 15.63px;
        }
      }

      .course-shopcart:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-shopcart {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 50%;
        // border: 1px solid green;
        line-height: 17px;
        margin-left: 2px;

        .course-shopcart-icon {
          opacity: 0.8;
          width: 17px;
          height: 17px;
        }
      }
    }

    .product-description-container {
      // border: 1px solid green;
      margin-top: 10px;
      padding-right: 12px;
      padding-left: 12px;

      .product-name {
        font-size: 16px;
        color: #777777;
        line-height: 20px;
      }

      .product-description {
        margin-top: -10px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }

      .video-date {
        margin-top: 6px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }
    }

    .product-price-container {
      // border: 1px solid red;
      padding-right: 12px;
      padding-left: 12px;

      .course-original-price {
        color: #777777;
        font-size: 12px;
        text-decoration-line: line-through;
      }

      .product-price {
        margin-bottom: 6px;
        height: 23px;
        .price {
          font-size: 18px;
          // border: 1px solid green;
          vertical-align: middle;
          transition: all 0.5s ease-out;
        }

        .unit {
          margin-left: 4px;
          font-size: 12px;
          // border: 1px solid gray;
          vertical-align: middle;
          color: #777777;
        }
      }

      .product-sold-quantity {
        margin-bottom: 12px;
        line-height: 15.8px;

        .sold-quantity {
          font-size: 12px;
          color: #777777;
        }
      }
    }
  }
}

// 遊覽區域寬度範圍在768px~1023px，則下方CSS描述會立即被套用：
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .course-card:hover {
    box-shadow: 2px 2px 10px 1px #cccccc;
    // 透過transition增加變色時的動畫效果
    transition: all 0.5s ease-in;

    .price {
      color: #25a2a6;
      transition: all 0.3s ease-in;
    }
  }

  .course-card {
    position: relative;
    // 原先的css也要加入transition ，這樣在取消hover時也會有離開的效果
    transition: all 0.5s ease-out;
    // 這邊設定卡片寬度，由於768版本每一row都要有4張卡片，因此width應該要是 100% / 3 = 33.33% ，但需要留距離給卡片之間的間隙
    // 因此設定成32.5%
    width: 32.5%;
    min-height: 273px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    overflow: hidden;

    .course-feature {
      // width: 90px;
      height: 19px;
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #2d2d2d;
      opacity: 0.8;
      border-radius: 100px;
      // 使用flex及row是為了文字的水平跟垂直置中
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-level {
      height: 19px;
      position: absolute;
      top: 31px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #f91d1d;
      opacity: 0.8;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-discount {
      // width: 90px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      background-color: red;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .course-bought {
      // width: 80px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      padding: 4px 16px;
      background-color: #ff6767;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .will-be-discontinued {
      // width: 115px;
      height: 28px;
      position: absolute;
      right: 0;
      bottom: 60px;
      color: white;
      padding: 6.5px 11.5px;
      background-color: #fc2c2c;
      font-size: 12px;
      // line-height: 125%;
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 500;
    }

    // 收藏與購買的符號
    .favorite-shopcart-container {
      position: absolute;
      bottom: 16px;
      right: 13px;
      // border: 1px solid red;

      .course-favorite:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-favorite {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 100px;
        // 這邊設置line-height與圖示高度相同的原因是，這樣子容器與圖片才會同心，否則在padding產出陰影效果後，會發現不是正圓
        line-height: 15.63px;

        .course-favorite-icon {
          opacity: 0.8;
          width: 18px;
          height: 15.63px;
        }
      }

      .course-shopcart:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-shopcart {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 50%;
        // border: 1px solid green;
        line-height: 17px;
        margin-left: 2px;

        .course-shopcart-icon {
          opacity: 0.8;
          width: 17px;
          height: 17px;
        }
      }
    }

    .product-description-container {
      // border: 1px solid green;
      margin-top: 10px;
      padding-right: 12px;
      padding-left: 12px;

      .product-name {
        font-size: 16px;
        color: #777777;
        line-height: 20px;
      }

      .product-description {
        margin-top: -10px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }

      .video-date {
        margin-top: 6px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }
    }

    .product-price-container {
      // border: 1px solid red;
      padding-right: 12px;
      padding-left: 12px;

      .course-original-price {
        color: #777777;
        font-size: 12px;
        text-decoration-line: line-through;
      }

      .product-price {
        margin-bottom: 6px;
        height: 23px;
        .price {
          font-size: 18px;
          // border: 1px solid green;
          vertical-align: middle;
          transition: all 0.5s ease-out;
        }

        .unit {
          margin-left: 4px;
          font-size: 12px;
          // border: 1px solid gray;
          vertical-align: middle;
          color: #777777;
        }
      }

      .product-sold-quantity {
        margin-bottom: 12px;
        line-height: 15.8px;

        .sold-quantity {
          font-size: 12px;
          color: #777777;
        }
      }
    }
  }
}

// 遊覽區域寬度範圍在415px~767px，則下方CSS描述會立即被套用：
@media only screen and (min-width: 415px) and (max-width: 767px) {
  .course-card:hover {
    box-shadow: 2px 2px 10px 1px #cccccc;
    // 透過transition增加變色時的動畫效果
    transition: all 0.5s ease-in;

    .price {
      color: #25a2a6;
      transition: all 0.3s ease-in;
    }
  }

  .course-card {
    position: relative;
    // 原先的css也要加入transition ，這樣在取消hover時也會有離開的效果
    transition: all 0.5s ease-out;
    width: 100%;
    min-height: 300px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    overflow: hidden;

    .course-feature {
      // width: 90px;
      height: 19px;
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #2d2d2d;
      opacity: 0.8;
      border-radius: 100px;
      // 使用flex及row是為了文字的水平跟垂直置中
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-level {
      height: 19px;
      position: absolute;
      top: 31px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #f91d1d;
      opacity: 0.8;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-discount {
      // width: 90px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      background-color: red;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .course-bought {
      // width: 80px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      padding: 4px 16px;
      background-color: #ff6767;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .will-be-discontinued {
      height: 28px;
      position: absolute;
      right: 0;
      bottom: 60px;
      color: white;
      padding: 6.5px 11.5px;
      background-color: #fc2c2c;
      font-size: 16px;
      // line-height: 125%;
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 500;
    }

    // 收藏與購買的符號
    .favorite-shopcart-container {
      position: absolute;
      bottom: 16px;
      right: 13px;
      // border: 1px solid red;

      .course-favorite:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-favorite {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 100px;
        // 這邊設置line-height與圖示高度相同的原因是，這樣子容器與圖片才會同心，否則在padding產出陰影效果後，會發現不是正圓
        line-height: 15.63px;

        .course-favorite-icon {
          opacity: 0.8;
          width: 18px;
          height: 15.63px;
        }
      }

      .course-shopcart:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-shopcart {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 50%;
        // border: 1px solid green;
        line-height: 17px;
        margin-left: 2px;

        .course-shopcart-icon {
          opacity: 0.8;
          width: 17px;
          height: 17px;
        }
      }
    }

    .product-description-container {
      // border: 1px solid green;
      margin-top: 10px;
      padding-right: 12px;
      padding-left: 12px;

      .product-name {
        font-size: 16px;
        color: #777777;
        line-height: 20px;
      }

      .product-description {
        margin-top: -10px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }

      .video-date {
        margin-top: 6px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }
    }

    .product-price-container {
      // border: 1px solid red;
      padding-right: 12px;
      padding-left: 12px;

      .course-original-price {
        color: #777777;
        font-size: 12px;
        text-decoration-line: line-through;
      }

      .product-price {
        margin-bottom: 6px;
        height: 25px;
        .price {
          font-size: 18px;
          // border: 1px solid green;
          vertical-align: middle;
          transition: all 0.5s ease-out;
        }

        .unit {
          margin-left: 4px;
          font-size: 12px;
          // border: 1px solid gray;
          vertical-align: middle;
          color: #777777;
        }
      }

      .product-sold-quantity {
        margin-bottom: 12px;
        line-height: 15.8px;

        .sold-quantity {
          font-size: 12px;
          color: #777777;
        }
      }
    }
  }
}

// 遊覽區域寬度範圍小於415px，則下方CSS描述會立即被套用：
@media only screen and (max-width: 414px) {
  .course-card:hover {
    box-shadow: 2px 2px 10px 1px #cccccc;
    // 透過transition增加變色時的動畫效果
    transition: all 0.5s ease-in;

    .price {
      color: #25a2a6;
      transition: all 0.3s ease-in;
    }
  }

  .course-card {
    position: relative;
    // 原先的css也要加入transition ，這樣在取消hover時也會有離開的效果
    transition: all 0.5s ease-out;
    width: 100%;
    min-height: 300px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    overflow: hidden;

    .course-feature {
      // width: 90px;
      height: 19px;
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #2d2d2d;
      opacity: 0.8;
      border-radius: 100px;
      // 使用flex及row是為了文字的水平跟垂直置中
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-level {
      height: 19px;
      position: absolute;
      top: 31px;
      left: 8px;
      font-size: 12px;
      color: #e1e3e6;
      background-color: #f91d1d;
      opacity: 0.8;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
    }

    .course-discount {
      // width: 90px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      background-color: red;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .course-bought {
      // width: 80px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      padding: 4px 16px;
      background-color: #ff6767;
      border-radius: 0px 10px 0px 10px;
      font-size: 16px;
      line-height: 125%;
    }

    .will-be-discontinued {
      height: 28px;
      position: absolute;
      right: 0;
      bottom: 60px;
      color: white;
      padding: 6.5px 11.5px;
      background-color: #fc2c2c;
      font-size: 16px;
      // line-height: 125%;
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 500;
    }

    // 收藏與購買的符號
    .favorite-shopcart-container {
      position: absolute;
      bottom: 16px;
      right: 13px;
      // border: 1px solid red;

      .course-favorite:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-favorite {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 100px;
        // 這邊設置line-height與圖示高度相同的原因是，這樣子容器與圖片才會同心，否則在padding產出陰影效果後，會發現不是正圓
        line-height: 15.63px;

        .course-favorite-icon {
          opacity: 0.8;
          width: 18px;
          height: 15.63px;
        }
      }

      .course-shopcart:hover {
        background-color: #909090;
        // 需要再父元素加上這行達到與opacity相同的效果，但又不影響子元素圖片的透明度
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in;
      }

      .course-shopcart {
        transition: all 0.2s ease-out;
        padding: 9.5px 11.87px;
        border-radius: 50%;
        // border: 1px solid green;
        line-height: 17px;
        margin-left: 2px;

        .course-shopcart-icon {
          opacity: 0.8;
          width: 17px;
          height: 17px;
        }
      }
    }

    .product-description-container {
      // border: 1px solid green;
      margin-top: 10px;
      padding-right: 12px;
      padding-left: 12px;

      .product-name {
        font-size: 16px;
        color: #777777;
        line-height: 20px;
      }

      .product-description {
        margin-top: -10px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }

      .video-date {
        margin-top: 6px;
        margin-bottom: 0;
        font-size: 12px;
        color: #909090;
        line-height: 15px;
      }
    }

    .product-price-container {
      // border: 1px solid red;
      padding-right: 12px;
      padding-left: 12px;

      .course-original-price {
        color: #777777;
        font-size: 12px;
        text-decoration-line: line-through;
      }

      .product-price {
        margin-bottom: 6px;
        height: 25px;
        .price {
          font-size: 18px;
          // border: 1px solid green;
          vertical-align: middle;
          transition: all 0.5s ease-out;
        }

        .unit {
          margin-left: 4px;
          font-size: 12px;
          // border: 1px solid gray;
          vertical-align: middle;
          color: #777777;
        }
      }

      .product-sold-quantity {
        margin-bottom: 12px;
        line-height: 15.8px;

        .sold-quantity {
          font-size: 12px;
          color: #777777;
        }
      }
    }
  }
}

// 其他固定CSS
.course-card-banner {
  background-image: url("~@/assets/img/shop/product/415/productBanner0.png");
  // 設定width 100% img才會填滿容器寬度
  width: 100%;
  // 高度則是透過設計師的原稿與容器進行計算，設定的原因是要固定圖片與剩下空間讓文字可以進行排列縮放
  height: 43.95%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
}

// 課程名稱兩行限制
.product-name {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /*行數*/
  -webkit-box-orient: vertical;
  white-space: normal;
}
.product-description {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /*行數*/
  -webkit-box-orient: vertical;
  white-space: normal;
}
</style>
