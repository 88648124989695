
import { defineComponent } from "vue";
import axios from "axios";
import { Modal } from "bootstrap";
import CcoinUsageHistoryModal from "@/components/modal/CcoinUsageHistory.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "OnlineShop-Banner",
  components: { CcoinUsageHistoryModal },
  data() {
    return {
      myCCoin: "--",
      ccoinUsageHistoryModal: null as any,
      ccoinUsageHistoryModalId: "Ccoin",
    };
  },
  methods: {
    openCcoinUsageHistoryModal() {
      this.ccoinUsageHistoryModal.show();
      (this.$refs.CcoinUsageHistoryModal as any).gradientEffect();
    },
    closeCcoinUsageHistoryModal() {
      this.ccoinUsageHistoryModal.hide();
    },
  },
  mounted() {
    // 取得C幣餘額
    axios
      .get(`${serverUrl}coin/balance`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data.data.coin);
          this.myCCoin = res.data.data.coin;
          this.$emit("myCCoinValue",this.myCCoin)
        }
      })
      .catch(() => {
        console.log("error");
      });
    this.ccoinUsageHistoryModal = new Modal(
      document.getElementById(this.ccoinUsageHistoryModalId) as HTMLElement
    );
  },
  beforeUnmount() {
    this.closeCcoinUsageHistoryModal();
  },
  emits:["myCCoinValue"],
});
